import React, { useEffect, useState } from 'react';
import BlogSearch from '../../components/Blog/BlogSearch';
import BlogBanner from '../../components/Blog/BlogBanner';
import BlogCard from '../../components/Blog/BlogCard';
import Subscribe from '../../components/Subscribe';
import Navbar from '../../components/Navbar/Navbar';
import classes from './blog.module.css';
import { Container } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';
import Seo from '../../components/SEO';
const BlogURL = `https://www.moneyfactory.ai/st`;
// const BlogURL = 'http://3.111.117.212:1337';
const BlogBaseURL = 'https://www.moneyfactory.ai';
// const BlogBaseURL = 'http://3.111.117.212:1337';
function BlogPage() {
  const [blogs, setBlogs] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);


  useEffect(() => {
    fetchBlogs()
  }, [page, search]);

  const loadMore = () => {
    setPage(page + 1);
  }

  const filterBlogs = (query) => {
    setSearch(query.trim());
  }

  const fetchBlogs = () => {
    let blogAPI = `${BlogURL}/api/blogs?populate=*`;
    if(search && search.length > 0) {
      blogAPI = blogAPI + `&/[$contains]=${search}`
    }
    axios.get(blogAPI).then(res => {
      if(res.data && res.data.data && res.data.data.length > 0) {
        let filterResult = res.data.data.sort((blogA, blogB) => 
          (blogA, blogB) => Number(blogA.attributes.publishedAt) - Number(blogB.attributes.publishedAt),
        ).reverse();
        setBlogs(filterResult);
      }else{
        setBlogs([]);
      }
    }).catch(err => {
      console.log(err);
      setBlogs([]);
    })
  }

  return (
    <div className={classes.blogContainer}>
      <Seo title="" canonical={window.location.pathname} description=""/>
      <Navbar />
      <Container>
        <BlogSearch filterBlogs={filterBlogs} />
        <BlogBanner BlogURL={BlogURL} BlogBaseURL={BlogBaseURL} blogs={blogs && blogs.length > 0 ? blogs[0] : {} } />
        <div className={classes.blogCardStyle}>
        <BlogCard BlogURL={BlogURL} BlogBaseURL={BlogBaseURL} blogs={blogs} perPage={perPage} page={page} loadMore={loadMore} />
        </div>
      </Container>
      {/* <Subscribe /> */}
      <Footer joinWithUs={false} childComponent={<Subscribe />} />
    </div>
  );
}

export default BlogPage;
