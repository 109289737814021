import React, { useEffect, useState } from 'react';
import ABCard from '../../components/MarketPlace/ABCard/ABCarddetails';
import Cashback from '../../components/MarketPlace/Cashback/Cashback';
import PVCard from '../../components/MarketPlace/PVCard/PVCard';
import classes from './Dashboard.module.css';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar';
import ActiveQ from '../../components/MarketPlace/ActiveQ/ActiveQdetails';
import NewDeployedBar from '../../components/MarketPlace/NewDeployedBar/NewDeployedBar';
import OrderInfo from '../../components/MarketPlace/Order/OrderInfo';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js';


import dots from '../../assets/fi-rr-menu-dots-vertical.svg';
import ab_balance_ic from '../../assets/ab_balance_ic.svg';
import refer from '../../assets/refer.png';
import current from '../../assets/currentStatistics.jpg'
import Actionoints from '../../components/MarketPlace/DeployedBar/Activepoints';
import './Dashboard.css';

// for now static data

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Dashboard() {
    let cancel = false;
    const [broker, setbroker] = useState(false);
    const [name, setName] = useState('Test');
    const [myQuants, setMyQuants] = useState([]);
    const [card, setcard] = useState(() => new Set());
    const [fivepaise, setfivepaise] = useState(false);
    const [angelone, setangelone] = useState(false);
    const [overall, setoverall] = useState(0.0);
    const [totalpnl, settotalpnl] = useState(0);
    const [total_balance, settotal_balance] = useState(0);
    const [avMargin, setavMargin] = useState(0);
    const [grMargin, setgrMargin] = useState(0);

    const calcTotalPnl = (quants) => {
        let total = 0;
        quants.stocksignals.map((ele) => {
          total += ele?.pldata?.pl;
          //   calc(ele.subscribed_price, ele.ltp);
        });
    
        return isNaN(total) ? 0 : Number(total).toFixed(2);
      };
    
      useEffect(() => {
        if (cancel) return;
        setName(JSON.parse(localStorage.getItem('MF_user')).name);
        const userId = JSON.parse(localStorage.getItem('MF_user')).id;
        fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/activequants/${userId}`
        )
          .then((res) => {
            if (!res.ok) {
              // get error message from body or default to response status
              const error = 'Server error';
              return Promise.reject(error);
            }
            return res.json();
          })
          .then((res) => {
            setMyQuants(res.activeQuants);
            let ovtmp = 0,
              r = 0;
            res.activeQuants.forEach((q) => {
              let capital = (ovtmp += q.quant.quant.price * q.quant.quantity);
              r += Number(calcTotalPnl(q));
            });
            setoverall(ovtmp);
            // let r = calcTotalPnl(res.activeQuants);
            settotalpnl(r.toFixed(2));
          })
          .catch((err) => {
            console.log(err);
          });
    
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/${userId}`)
          .then((res) => {
            if (!res.ok) {
              return Promise.reject('error');
            }
            return res.json();
          })
          .then((res) => {
            res.profile.broker_accounts.forEach((element) => {
              setcard((prev) => new Set(prev).add(element.brokerId));
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        return () => {
          cancel = true;
        };
      }, []);
    
    return (
        <>
            <Sidebar>
                <div className='dashbord_main_wrapper'>
                    <h4 className='dashbord_name_title'>Hello, {name}</h4>
                    <div className='dashbord_main_inner'>
                        <div className='row'>
                            <div className='col-xl-8'>
                                <div className='available_balance_info'>
                                    <div className='row'>
                                            <div className='overview_info col-md-8'>
                                                <h3 className='dashbord_title'>Overview</h3>
                                                <div className='available_balance_wrap'>
                                                    <ul>
                                                        <li>
                                                            <div className='available_balance_box'>
                                                                <div className='available_balance_box_image'>
                                                                    <img src={ab_balance_ic} alt="icon" />
                                                                </div>
                                                                <div className='available_balance_details'>
                                                                    <h5>Available Balance</h5>
                                                                    <p>₹ {total_balance}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='available_balance_box'>
                                                                <div className='available_balance_box_image'>
                                                                    <img src={ab_balance_ic} alt="icon" />
                                                                </div>
                                                                <div className='available_balance_details'>
                                                                    <h5>Portfolio Value</h5>
                                                                    <p>₹ 0</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='available_balance_box'>
                                                                <div className='available_balance_box_image'>
                                                                    <img src={ab_balance_ic} alt="icon" />
                                                                </div>
                                                                <div className='available_balance_details'>
                                                                    <h5>Used Balance</h5>
                                                                    <p>₹ 0</p>
                                                                </div>
                                                                <div className='used_balance_chart'>
                                                                    {/* <Doughnut data={data} width="80px" height="80px" ref={chart}/> */}
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='available_balance_box'>
                                                                <div className='available_balance_box_image'>
                                                                    <img src={ab_balance_ic} alt="icon" />
                                                                </div>
                                                                <div className='available_balance_details'>
                                                                    <h5>Total Capital Deployed</h5>
                                                                    <p>₹ {overall}</p>
                                                                </div>

                                                                <div className='order_type_wrap'>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                            <img src={dots} alt="dots" />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item href="#/action-1">Assisted</Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-2">Stimulated</Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3">Automatic</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='available_balance_wrap pnl_wrapper'>
                                                    <div className='available_balance_box w-100'>
                                                        <div className='available_balance_box_image'>
                                                            <img src={ab_balance_ic} alt="icon" />
                                                        </div>
                                                        <div className='available_balance_details'>
                                                            <h5>Total PNL</h5>
                                                            <p>₹ {totalpnl}</p>
                                                        </div>
                                                        <div className='available_balance_details'>
                                                            <h5>Today Profit</h5>
                                                            <p>₹ {totalpnl}</p>
                                                        </div>
                                                        <div className='bar_chart_wrap'>
                                                            {/* <Bar data={bardata} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='current_statistics col-md-4'>
                                            <h3 className='dashbord_title'>Current Statistics</h3>
                                            <div className='current_statistics_graph'>
                                            <img src={current} alt='current stats' style={{width: '100%'}}/></div>
                                            </div>
                                    </div>
                                </div>

                                <div className='active_quants_wrappper'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <ActiveQ myQuants={myQuants} />
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='all_order_wrap'>
                                                <OrderInfo />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='quants_overview_wrapper'>
                                    <div className='common_quants_title_wrap'>
                                        <h3>Quant Overview</h3>
                                    </div>
                                    <div className='market_overview_wrapper'>
                                        <div className='market_overview_with_quants'>
                                            <div className='market_titls'>
                                                <h4>Market Overview</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur</p>
                                            </div>
                                            <div className='quants_list d-flex'>
                                                <ul>
                                                    {
                                                        myQuants.map((quants, ind) => {
                                                            return (
                                                                <li>
                                                                    <div className='quants_icon_wrap'>
                                                                        <img src={quants ? quants.quant.quant.imgUrl : ''} alt="quants icon" />
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div className='order_type_wrap'>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic2">
                                                            <img src={dots} alt="dots" />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">Assisted</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-2">Stimulated</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3">Automatic</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='col-xl-4'>
                                {/* <div className='messages_wrapper'>
                                    <div className='common_quants_title_wrap'>
                                        <h3>Messages</h3>
                                    </div>
                                    <div className='message_info_wrap'></div>
                                </div> */}
                                <div className='what_next_wrapper'>
                                    <div className='common_quants_title_wrap'>
                                        <h3>What Next?</h3>
                                        <Link to="/" className="all_links_text">View All</Link>
                                    </div>
                                    <div className='fet_offer_wrap'>
                                        <div className='fet_offer_info'>
                                            <div className='fet_offer_details'>
                                                <p>Get <span>₹500</span> Cashback on your <span>FIRST Referral</span></p>
                                                <button type='button' className='refer_btn cm_next_btn'>Refer Now</button>
                                            </div>
                                            <img src={refer} alt="refer" />
                                        </div>
                                        <div className='help_wrapper'>
                                            <h3>Need help with Investing?</h3>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            <button type='button' className='book_btn cm_next_btn'>Book A Call</button>
                                        </div>
                                        <div className='invest_wrapper'>
                                            <p>Explore the best deals for you <span>Invest Right Way.</span></p>
                                            <button type='button' className='invest_now cm_next_btn'>Invest Now</button>
                                        </div>
                                    </div>
                                </div>
                                <Actionoints />
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default Dashboard;





