import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import classes from './Invest.module.css';
import MBgrad from '../MBgrad/MBgrad';
import playstore from '../../assets/playstore.svg';
import IMRE from '../IMRE/IMRE';
import Siat from '../SIAT/Siat';
import Eibp from '../EIBP/Eibp';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import Ellips from '../../assets/Ellipse62.png';
import fly from '../../assets/home/fly.svg';
import Button from '../Ui/Button';
import { motion } from 'framer-motion';
import ScrollAnimation from 'react-animate-on-scroll';
function Invest() {
  return (
    <Container fluid>
      <div className={classes.rightFluid}>
        <div className={classes.invest_container}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className={classes.investText}>
                  <ScrollAnimation duration={2} animateIn="fadeIn">
                    <h1>
                      INVEST LIKE THE <span>TOP 1%</span>
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="bounceInLeft">
                    <p>Without spending years learning how the market works.</p>
                  </ScrollAnimation>
                  <ScrollAnimation duration={3} animateIn="fadeIn">
                    <a href="https://play.google.com/store/apps/details?id=com.moneyfactory" target="_blank">
                      <Button
                        wrapperClass={classes.homeBtnWrapper}
                        btnClass={classes.homeBtn}
                      >
                        GET AN APP &ensp; <img src={playstore} alt='logo' height='26' width='26'/>
                      </Button>
                    </a>
                  </ScrollAnimation>
                </div>
                {/* <div className={classes.imgWrapper}>
                  <img src={Ellips} alt="elips" className={classes.btnImg} />
                  <button className={`button ${classes.homeBtn}`}>
                    Apply for early access
                  </button>
                </div> */}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className={`${classes.mainImg}`}>
                  <ScrollAnimation animateIn="flipInX">
                    <motion.nav
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <img src={fly} alt="INVEST LIKE THE TOP 1% with Moneyfactory" width="100%" />
                    </motion.nav>
                  </ScrollAnimation>
                </div>
              </Col>
            </Row>
        </div>
      </div>
      <MBgrad />
      <Siat />
    </Container>
  );
}

export default Invest;
