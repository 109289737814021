import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Zeus from '../../../assets/Zeus.svg';
import Carousel from '../Carousel/Carousel.jsx';
import Sidebar from '../Sidebar/Sidebar';
import { useLocation, useParams } from 'react-router-dom';
import classes from './Description.module.css';
import Position from '../../../assets/fi-sr-chat-arrow-grow.svg';
import Index from '../../../assets/fi-sr-diploma.svg';
import Histogram from '../../../assets/histogram.svg';
import { Select, styled, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import swal from 'sweetalert';
import { Chart, Tooltip, ArcElement, Legend } from 'chart.js';

Chart.register(
    Tooltip, ArcElement, Legend
)

const CssSelect = styled(Select)({
    color: 'white',
    background: "#1D1D1D",
    "& label.Mui-focused": {
        display: "none"
    },
    "& legend": {
        display: "none"
    },
    '& svg': {
        fill: "#00D37F"
    },
    '&:hover fieldset': {
        borderColor: '#00D37F !important'
    },
    '& input': {
        color: 'white'
    },
    '& fieldset': {
        border: '2px solid',
        borderColor: "#333333",

    },
    '&.Mui-focused fieldset': {
        // color:'black',
        borderColor: '#00D37F !important'
    }
});

function Description() {
    const [deploy, setDeploy] = useState("Assisted");
    const [chartdata, setChartdata] = useState("");
    const [subscribe, setSubscribe] = useState(false);
    const [broker, setBroker] = useState(10);
    const [brokerName, setBrokerName] = useState('');
    const [multiplier, setMultiplier] = useState(1);
    const [quantData, setQuantData] = useState({});
    const [data, setData] = useState([]);
    const [brokerList, setBrokerList] = useState({
        Stimulated: [{
            status: "active",
            _id: 6,
            name: "MF Virtual Cash",
            description: "MoneyFactory Virtual Cash for simulated deployment",
            imgURL: "moneyFactory"
        }]
    });
    const selectedList = ['Assisted', 'Automatic', "Stimulated"];

    const handleDeploy = (e) => {
        setDeploy(e.target.value);
    }

    const handleBroker = (e) => {
        const value=e.target.value;
        if (typeof value=='number') {
            setBroker(value);
            setBrokerName('');
        }
        else {
            setBroker(10);
            setBrokerName(value);
        }
    }

    const handleMultiplier = (e) => {
        setMultiplier(e.target.value);
    }

    const location = useLocation();
    const { quantId } = useParams();
    let categoryId, categoryTitle;
    if (location.state) {
        categoryId = location.state.categoryId;
        categoryTitle = location.state.categoryTitle;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/discover/quants/${quantId}`)
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                setQuantData(res.quant);
                setChartdata({
                    labels: [...res.quant.stocks.map((ele) => { return ele.name })],
                    datasets: [{
                        data: [...res.quant.stocks.map((ele) => { return 1 })],
                        backgroundColor: [
                            '#CEFD1E',
                            '#00D37F',
                            '#1C4861',
                            '#35919A',
                            '#F294B4',
                            '#5C45CC',
                            '#D0B6FC',
                            '#FCB017',
                            '#204247',
                            '#69CAD7',
                        ],
                        borderColor: [
                            '#CEFD1E',
                            '#00D37F',
                            '#1C4861',
                            '#35919A',
                            '#F294B4',
                            '#5C45CC',
                            '#D0B6FC',
                            '#FCB017',
                            '#204247',
                            '#69CAD7',
                        ],
                        borderWidth: 1,
                        cutout: "75%",
                        offset: 10,
                        hoverOffset: 25,
                        hoverBorderWidth: 15,
                        hoverBorderJoinStyle: 5
                    }]
                }
                )
            })
            .then(() => {
                fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/quants`)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        setData(res);

                    })
                const quantbackgroundId = document.getElementById('quantbackground');
            })
            .catch((err) => {
                console.log(err);
            })
    }, [quantId])

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem("MF_user")).id;
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/brokers`)
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                setBrokerList((prev) => ({ ...prev, Automatic: res }));
            })
            .catch(err => {
                console.log(err);
            })
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/smallcase/savedbroker/${userId}`)
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                const brokers = [{ _id: 'guest', name: 'Link new broker' }]
                for (var key in res.brokers) {
                    brokers.push({ _id: key, name: key })
                }
                setBrokerList((prev) => ({ ...prev, Assisted: brokers }));
                setBroker(10);
                setBrokerName(brokers[0]._id);
            })
            .catch(err => {
                console.log(err);
            })
        if (data.length) {
            const carouselArray = document.querySelectorAll(".carouselContainer");
            document.querySelectorAll('.leftIcon')[carouselArray.length - 1].style.pointerEvents = "none";
            document.querySelectorAll('.leftIcon')[carouselArray.length - 1].style.fill = "grey";
        }
    }, [data])

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem("MF_user")).id;
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/getsubscribequants/${userId}`)
            .then((res) => {
                if (!res.ok) {
                    // get error message from body or default to response status
                    const error = 'Server error';
                    return Promise.reject(error);
                }
                return res.json();
            })
            .then((res) => {
                res.subQts.forEach((ele) => {
                    if (quantId == ele.quant._id) {
                        setSubscribe(true);
                    }
                })
            }).catch((err) => {
                console.log(err);
            })

    }, [])

    const handleSubscribe = () => {
        if (!subscribe) {
            const options = {
                quantId: quantId,
                userId: JSON.parse(localStorage.getItem("MF_user")).id
            }

            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            }

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/subscribequants`, requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        // get error message from body or default to response status
                        const error = 'not subscribed';
                        return Promise.reject(error);
                    }
                    return res.json();
                })
                .then((res) => {
                    swal({
                        title: 'Successfully subscribed',
                        text: 'Thank you for subscribing',
                        icon: 'success',
                        timer: 3000
                    })
                    setSubscribe(true);
                })
        }
    }

    const findDifficultyColor = (ele) => {
        if (ele === "Low") {
            return "#4D9078";
        } else if (ele === "Medium") {
            return "#FCB017";
        } else {
            return "#C5283D";
        }
    }

    const handleDeployment = () => {
        if (subscribe) {
            const options = {
                quantId: quantId,
                userId: JSON.parse(localStorage.getItem("MF_user")).id,
                mode: deploy,
                quantity: multiplier,
                broker: broker,
                brokerName:brokerName
            }

            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            }
            var error = false;
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/deployquants`, requestOptions)
                .then((res) => {
                    if (!res.ok || res.status == 404) {
                        error = true;
                    }
                    return res.json();
                })
                .then((res) => {
                    if(error)
                        {
                            swal({
                                title: 'Error',
                                text: res.msg,
                                icon: 'error',
                                timer: 2000
                            });
                            return Promise.reject(res.msg);
                        }
                    
                    swal({
                        title: 'Successfully deployed',
                        text: 'Thank you',
                        icon: 'success',
                        timer: 3000
                    });
                    window.location.href = '/myquants';

                    //Need to add to signals table for order creation based on alerts.

                    const sigoptions = {
                        quantId: quantId,
                        userId: JSON.parse(localStorage.getItem("MF_user")).id,
                        brokerId: 1,
                        subscribedPrice: quantData.price * multiplier
                    }

                    const requestSigOptions = {
                        method: "POST",
                        mode: "cors",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(sigoptions)
                    }

                    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/broker/create/signals`, requestSigOptions)
                        .then((res) => {
                            if (!res.ok) {
                                // get error message from body or default to response status
                                const error = 'Signals not deployed';
                                console.log(error);
                            } else {
                                console.log('Signals created Successfully');
                            }
                        })
                })
        } else {
            swal({
                title: 'Not deployed',
                text: ' Need to subscribe to quant before deploying',
                icon: 'warning',
                timer: 3000
            })
        }
    }

    return (
        <Sidebar>
            <div className={classes.description}>
                <div className={`${classes.details}`} id="quantbackground" >
                    <div className={classes.description_wrap}>
                        <div className={classes.description_img_wrap}>
                            <img src={quantData ? quantData.imgUrl : ""} alt="" />
                        </div>
                        <div className={classes.difficulty} style={{ backgroundColor: findDifficultyColor(quantData ? quantData.risk : "") }}>
                            {quantData ? quantData.risk : ""}
                        </div>
                    </div>

                    <div className={classes.quantbackground_img}>
                        <img src={quantData?.imgUrl ? quantData?.imgUrl : ""} alt="" />
                    </div>

                    <div className={classes.quantInfo}>
                        <div>
                            {categoryTitle ? <p>{categoryTitle}</p> : ""}
                            <h2>{quantData.name}</h2>
                            <div className={classes.svg_container}>
                                <div className={classes.svg}>
                                    <img src={Position} alt="" />
                                    <h6>
                                        {quantData.subCategories?.length ? quantData.subCategories.map((ele, ind) => (ind == quantData.subCategories.length - 1) ? ele.subCategoryId.name : ele.subCategoryId.name + " , ") : "Positional features"}
                                    </h6>
                                </div>
                                <div className={classes.svg}>
                                    <img src={Index} alt="" />
                                    <h6>
                                        {quantData.functionality?.length ? quantData.functionality.map((ele, ind) => (ind == quantData.functionality.length - 1) ? ele.functionalityId.name : ele.functionalityId.name + " , ") : "index position"}
                                    </h6>
                                </div>
                            </div>
                            <h3>₹ {quantData.price}</h3>
                            {subscribe ? <button className={classes.subscribed}>Subscribed</button> : <button className={classes.subscribe} onClick={handleSubscribe}>Subscribe Now</button>}
                        </div>
                        <div className={classes.quant_chart}>
                            {chartdata ?
                                <Doughnut data={chartdata}
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive: true,

                                        plugins: {
                                            legend: {
                                                labels: {
                                                    boxWidth: 0
                                                },
                                                display: false,
                                            }
                                        }
                                    }} />
                                : ""}
                        </div>

                    </div>
                </div>
                <div>
                    <h5>Card  Details & Matrix</h5>
                    <div className={classes.histogram}>
                        <div>
                            <img src={Histogram} alt="" />
                        </div>
                        <div>
                            <div className={classes.flex}>
                                <div>
                                    <h6>Selected Mode</h6>
                                    <CssSelect
                                        label={" "}
                                        id="custom-css-outlined-input"
                                        IconComponent={KeyboardArrowDownIcon}
                                        InputLabelProps={{
                                            style: { color: '#fff' },
                                            shrink: false
                                        }}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiPaper-root": {
                                                    background: "#1D1D1D",
                                                    color: 'white',
                                                    border: "1px solid #333333",
                                                    "& li": {
                                                        padding: "0.7rem 0.5rem",
                                                    },
                                                    "& li:hover": {
                                                        background: "#00D37F1A"
                                                    },
                                                    "& .Mui-selected": {
                                                        background: "none",
                                                    }
                                                }
                                            }
                                        }}
                                        multiline={true}
                                        className={classes.first_tf}
                                        onChange={handleDeploy}
                                        name="deploy"
                                        margin='normal'
                                        value={deploy}
                                        variant="outlined"
                                    >
                                        {selectedList.map((i) => { return <MenuItem value={i}>{i}</MenuItem> })}
                                    </CssSelect>
                                </div>
                                <div>
                                    <h6>Multiplier</h6>
                                    <CssSelect
                                        label={" "}
                                        id="custom-css-outlined-input"
                                        IconComponent={KeyboardArrowDownIcon}
                                        InputLabelProps={{
                                            style: { color: '#fff' },
                                            shrink: false
                                        }}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiPaper-root": {
                                                    background: "#1D1D1D",
                                                    color: 'white',
                                                    border: "1px solid #333333",
                                                    "& li": {
                                                        padding: "0.7rem 0.5rem",
                                                    },
                                                    "& li:hover": {
                                                        background: "#00D37F1A"
                                                    },
                                                    "& .Mui-selected": {
                                                        background: "none",
                                                    }
                                                }
                                            }
                                        }}
                                        multiline={true}
                                        className={classes.first_tf}
                                        onChange={handleMultiplier}
                                        name="multiplier"
                                        margin='normal'
                                        value={multiplier}
                                        variant="outlined"
                                    >
                                        <MenuItem value={1}>1x</MenuItem>
                                        <MenuItem value={3}>3x</MenuItem>
                                        <MenuItem value={5}>5x</MenuItem>
                                        <MenuItem value={10}>10x</MenuItem>
                                    </CssSelect>
                                </div>
                            </div>
                            <h6>Broker</h6>
                            <div className={classes.deploy}>
                                <CssSelect
                                    label={" "}
                                    id="custom-css-outlined-input"
                                    IconComponent={KeyboardArrowDownIcon}
                                    InputLabelProps={{
                                        style: { color: '#fff' },
                                        shrink: false
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiPaper-root": {
                                                background: "#1D1D1D",
                                                color: 'white',
                                                border: "1px solid #333333",
                                                "& li": {
                                                    padding: "0.7rem 0.5rem",
                                                },
                                                "& li:hover": {
                                                    background: "#00D37F1A"
                                                },
                                                "& .Mui-selected": {
                                                    background: "none",
                                                }
                                            }
                                        }
                                    }}
                                    multiline={true}
                                    className={classes.first_tf}
                                    onChange={handleBroker}
                                    name="broker"
                                    margin='normal'
                                    value={broker==10?brokerName:broker}
                                    variant="outlined"
                                >
                                    {brokerList && deploy && brokerList[deploy]?.map(obj => <MenuItem value={obj._id}>{obj.name}</MenuItem>)}
                                </CssSelect>
                                <button onClick={handleDeployment}>Deploy</button>
                            </div>
                            <div className={classes.flexdetails}>
                                <div>
                                    <h6>Start date</h6>
                                    <p>{quantData.startdate}</p>
                                </div>
                                <div>
                                    <h6>Created by</h6>
                                    <p>{quantData.createdBy}</p>
                                </div>
                                <div>
                                    <h6>Trading Frequency</h6>
                                    <p>{quantData.trading_frequency}</p>
                                </div>
                                <div>
                                    <h6>Risk Rating</h6>
                                    <p>{quantData.risk}</p>
                                </div>
                            </div>
                            <div>
                                <h6>Category</h6>
                                {data.length ? <p className={classes.category}>{quantData.categories.map((ele) => (
                                    `${ele.categoryId.name},`
                                ))}</p> : ""}
                            </div>
                            <div>
                                <h6>Description</h6>
                                <p>{quantData.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {categoryId ? <Carousel title={categoryTitle} data={data} id={categoryId} ind={0} /> : data.length ? <Carousel title={quantData?.categories[0]?.categoryId?.name} data={data} id={quantData?.categories[0]?.categoryId?._id} ind={0} /> : ""}
                    {/*  */}
                </div>
            </div>
        </Sidebar>
    )
}

export default Description