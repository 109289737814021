import React, { useEffect, useState } from 'react';
import classes from './MyQuants.module.css';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar.jsx';
import Slider from '../../assets/sliders.svg';
import MyQuantTable from '../../components/MarketPlace/MyQuantTable/MyQuantTable';
import MyQuantFilter from '../../components/MarketPlace/MyQuantFilter/MyQuantFilter';
import icici from '../../assets/_icici.svg';
import _5paisa from '../../assets/_5paisa.svg';
import angleBroking from '../../assets/_angleBroking.svg';
import moneyFactory from '../../assets/logo.ico';
import fyres from '../../assets/_fyres.svg';
import hdfc from '../../assets/hdfc.png';

function MyQuants() {
    const [myQuants, setMyQuants] = useState([]);
    const [filters, setFilters] = useState([]);
    const [brokerList, setBrokerList] = useState([]);
    const brokerLogos = {
        icici: icici,
        _5paisa:_5paisa,
        angleBroking:angleBroking,
        moneyFactory:moneyFactory,
        fyres: fyres,
        hdfc:hdfc
    }
    const show_filter = () => {
        const filt = document.querySelector('.filter');
        document.body.style.height = "100vh";
        document.body.style.overflowX = "hidden";
        filt.style.transform = "translateX(0)";
    }

    const filter = (value) => {
        if (filters.length === 0) { return true; }
        if (filters.category !== 'all' && filters.category !== value.quant.quant.categories[0].categoryId) {
            return false;
        }
        if (filters.mode !== 'all' && filters.mode !== value.quant.mode) {
            return false;
        }
        if (filters.status !== 'all' && filters.status !== value.quant.quant.status) {
            return false;
        }
        if (filters.broker !== 'all' && filters.broker !== value.quant.broker) {
            return false;
        }
        // if(filters.segment !== 'all' && filters.segment !== value){
        //     return false;
        // }
        return true;
    }
    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem("MF_user")).id;
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/activequants/${userId}`)
            .then((res) => {
                if (!res.ok) {
                    // get error message from body or default to response status
                    const error = 'Server error';
                    return Promise.reject(error);
                }
                return res.json();
            })
            .then((res) => {
                setMyQuants(res);
            }).catch((err) => {
                console.log(err);
            })

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/brokers`)
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                setBrokerList(res);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <Sidebar>
            <MyQuantFilter setFil={setFilters} brokerList={brokerList} />
            <div className={classes.Myquant}>
                <div>
                    <div className={classes.heading}>
                        <h4>My Quants</h4>
                        <button onClick={() => { show_filter() }}><img src={Slider} alt="Filter" />Filter</button>
                    </div>
                    {myQuants?.activeQuants?.filter(filter).map((ele) => {
                        let broker = brokerList.filter(i => {
                            if (ele.quant.broker === i._id) {
                                return true;
                            }
                            return false;
                        })[0];
                        return <MyQuantTable quantDetails={ele} brokerName={broker?.name} brokerLogo={brokerLogos[broker?.imgURL]} />
                        })}
                </div>
            </div>
        </Sidebar>
    )
}

export default MyQuants