import classes from './Welcome.module.css';
import React, { useEffect, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import welcomeDashboard from '../../assets/welcomeDashboard.svg';
import Carousel from '../../components/MarketPlace/Carousel/Carousel';
import { Select, styled, MenuItem } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MyQuantTable from '../../components/MarketPlace/MyQuantTable/MyQuantTable';
import dashboadFly from '../../assets/dashboadFly.svg';

const CssSelect = styled(Select)({
  color: 'white !important',
  width: '100%',
  background: '#1D1D1D',
  '& label.Mui-focused': {
    display: 'none'
  },
  '& legend': {
    display: 'none'
  },
  '& svg': {
    fill: '#00D37F'
  },
  '&:hover fieldset': {
    borderColor: '#00D37F !important'
  },
  '& input': {
    color: 'white'
  },
  '& fieldset': {
    border: '2px solid',
    borderColor: '#333333'
  },
  '&.Mui-focused fieldset': {
    // color:'black',
    borderColor: '#00D37F !important'
  }
});

export const DashboardPageOne = (props) => {
  const [checkedValue, setCheckedValue] = useState('');
  const [errorDisplay, setError] = useState('');
  const onPortfolioValue = (e) => {
    if (e.target.checked) {
      setCheckedValue(e.target.value);
      setError('');
    } else {
      setCheckedValue('');
    }
  };

  const submitValue = () => {
    if (checkedValue) {
      const options = {
        portfolio: checkedValue
      };

      const requestOptions = {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(options)
      };

      fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/saveportfolio/${props.userId}`,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            // get error message from body or default to response status
            const error = 'please select value';
            return Promise.reject(error);
          }
          return res.json();
        })
        .then((res) => {
          // swal({
          //   title: 'Successfully',
          //   text: 'Thank you',
          //   icon: 'success',
          //   timer: 3000
          // });

          props.nextPage();
        });
      } else {
      setError('Please select value');
    }
  };

  return (
    <div>
      <div className={classes.noDataDashboardImg}>
        <img src={welcomeDashboard} alt="select amount" />
      </div>
      <div className={classes.noDataTitle}>
        Are you ready to build exponential wealth?
      </div>
      <div className={classes.noDataContent}>
        Select your portfolio size and let us show you how you can. (Don’t
        worry, this is all simulated)
      </div>
      <div className={classes.selectValue}>
        <Row className={' align-items-center justify-content-center'}>
          {props?.portFolioValue?.map((x, k) => {
            return (
              <div
                className={`col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3`}
                key={k + 1}
              >
                <div className={classes.portfolioValue}>
                  <Row>
                    <Col md={3} xs={3} sm={3} lg={3}>
                      <input
                        onClick={(e) => onPortfolioValue(e)}
                        type="checkbox"
                        id={k}
                        name={x.name}
                        value={x._id}
                      />
                    </Col>
                    <Col md={9} xs={9} sm={9} lg={9}>
                      <p className={classes.pStaticValue}>Portfolio Value</p>
                      <p className={classes.pValue}>{x.value}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </Row>
        {errorDisplay ? (
          <span style={{ color: 'red', fontSize: '12px' }}>{errorDisplay}</span>
        ) : (
          ''
        )}
        <div>
          <button
            className={classes.nextValue}
            onClick={() => {
              submitValue();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export const DashboardPageTwo = (props) => {
  const [deploy, setDeploy] = useState('Assisted');
  const [subscribe, setSubscribe] = useState(false);
  const [multiplier, setMultiplier] = useState(2);
  const [quantData, setQuantData] = useState({});
  const [quantId, setQuantId] = useState(0);

  const handleDeployment = () => {
    if (subscribe) {
      const options = {
        quantId: quantId,
        userId: JSON.parse(localStorage.getItem('MF_user')).id,
        mode: deploy,
        quantity: multiplier
      };

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(options)
      };

      fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/deployquants`,
        requestOptions
      )
        .then((res) => {
          if (!res.ok || res.status == 404) {
            // get error message from body or default to response status
            const error = 'not deployed';
            swal({
              title: 'Error: Quants already deployed',
              text: 'Thank you',
              icon: 'error',
              timer: 2000
            });
            props.nextPage();
            return Promise.reject(error);
          }
          return res.json();
        })
        .then((res) => {
          swal({
            title: 'Successfully deployed',
            text: 'Thank you',
            icon: 'success',
            timer: 3000
          });
          props.nextPage();
          //Need to add to signals table for order creation based on alerts.

          const sigoptions = {
            quantId: quantId,
            userId: JSON.parse(localStorage.getItem('MF_user')).id,
            brokerId: 1,
            subscribedPrice: quantData.price * multiplier
          };

          const requestSigOptions = {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(sigoptions)
          };

          fetch(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/broker/create/signals`,
            requestSigOptions
          ).then((res) => {
            if (!res.ok) {
              // get error message from body or default to response status
              const error = 'Signals not deployed';
              console.log(error);
            } else {
              console.log('Signals created Successfully');
            }
          });
        });
    } else {
      swal({
        title: 'Not deployed',
        text: ' Need to subscribe to quant before deploying',
        icon: 'warning',
        timer: 3000
      });
    }
  };

  return (
    <div>
      <div className={classes.noDataTitle}>
        The finance gods will help you soar
      </div>
      <div className={classes.noDataContent}>
        Choose a powerful quant and then trust the AI-powered algorithm to bring
        you an optimised portfolio for maximising profits & minimising errors.
        <br /> Select one to dive deeper!
      </div>
      <div className={classes.selectValue}>
        {props.discoverData.map((ele, ind) => (
          <Carousel
            // isOpen={'false'}
            // handleDiscoverSelect={handleDiscoverSelect}
            // subscribe={subscribe}
            // subscribeCardId={subscribeCardId}
            // handleSubscribe={handleSubscribe}
            title={ele.name}
            id={ele._id}
            data={props.data}
            ind={ind}
            key={ele._id}
          />
        ))}
        {/* onClick={handleDeployment} */}

        {/* <Carousel title={'NIFTY 50'} data={props.discoverData} id={1} ind={0} /> */}
        <span>
          <button className={classes.backValue} onClick={props.backPage}>
            Back
          </button>
          <button className={classes.nextValue} onClick={handleDeployment}>
            Deploy Next
          </button>
        </span>
      </div>
    </div>
  );
};

export const DashboardPageThree = (props) => {
  return (
    <div>
      <div className={classes.noDataTitle}>Build wealth while you sleep</div>
      <div className={classes.noDataContent}>
        Deploy the quant once and then let the algorithm guide you Track your
        performance easily; invest & sell when the time is right
      </div>
      {props.myQuants?.map((ele) => (
        <MyQuantTable quantDetails={ele} />
        // 'hi'
      ))}
      <span>
        <button className={classes.backValue} onClick={props.backPage}>
          Back
        </button>
        <button className={classes.nextValue} onClick={props.nextPage}>
          Deploy Quants
        </button>
      </span>
    </div>
  );
};

export const DashboardPageFour = (props) => {
  return (
    <div>
      <div className={classes.DashboardFlyImg}>
        <img src={dashboadFly} alt="select amount" />
      </div>
      <div className={classes.noDataTitle}>
        Congratulations! Welcome to the top 1%
      </div>
      <div className={classes.noDataContent}>
        Now you can start to invest like the top 1%
        <br />
        Learn faster, invest better, and let MoneyFactory make the journey
        easier
      </div>
      <span>
        <button className={classes.backValue} onClick={props.backPage}>
          Back
        </button>
        <button className={classes.nextValue}>Get Started</button>
      </span>
    </div>
  );
};
