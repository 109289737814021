import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper';
import UpArrow from '../../../assets/upArrow.svg';
import DownArrow from '../../../assets/downArrow.svg';
import my_order_men from '../../../assets/my_order_men.png'
import Moment from 'react-moment';
import '../Myaccount/my_account.css'

const OrderBook = () => {
    useEffect(() => {
        var useid = JSON.parse(localStorage.getItem('MF_user')).id;
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/orders/${useid}`)
            .then((response) => response.json())
            .then((json) => {
                setOrderTransactions(json)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const [ordertransactions, setOrderTransactions] = useState([]);
    const [page, setPage] = useState(1);

    return (
        <div className='right_aide_section'>
            <TableContainer component={Paper} className="cm_table">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Quants</TableCell>
                            <TableCell>Instrument</TableCell>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>
                                <div className='cm_title_arrow'>
                                    <p>Order Status</p>
                                    <div className="arrow">
                                        <div>
                                            <img src={UpArrow} alt="uparrow" />
                                        </div>
                                        <div>
                                            <img src={DownArrow} alt="uparrow" />
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                Quantity
                            </TableCell>
                            <TableCell>
                                <div className='cm_title_arrow'>
                                    <p>Price</p>
                                    <div className="arrow">
                                        <div>
                                            <img src={UpArrow} alt="uparrow" />
                                        </div>
                                        <div>
                                            <img src={DownArrow} alt="uparrow" />
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ordertransactions.slice((page-1) * 10, page * 10)?.map((orderdata) => {
                                var price = orderdata?.order_details?.price ? orderdata?.order_details?.price : 0
                                var qty = orderdata?.qty ? orderdata?.qty : 0
                                var value = price * qty;
                                return (
                                    <TableRow>
                                        <TableCell>
                                            <div className='person_image_and_text'>
                                                <div className='person_img'>
                                                    <img src={my_order_men} alt="my order" />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell><span>{orderdata.symbol}</span></TableCell>
                                        <TableCell>{orderdata._id}</TableCell>
                                        <TableCell>
                                            <Moment format="DD/MM/YYYY">{orderdata.createDate}</Moment>
                                        </TableCell>
                                        <TableCell>
                                            <Moment format="hh:mm:ss a">{orderdata.createDate}</Moment>
                                        </TableCell>
                                        <TableCell className={orderdata.order_details.transactiontype === "SELL" ? "exit_color" : "entry_color "}>
                                            {orderdata.order_details.transactiontype === "SELL" ? "Exit" : "Entry"}</TableCell>
                                        <TableCell>{orderdata.order_details.transactiontype === "SELL" ? `- ${orderdata.qty}` : orderdata.qty}</TableCell>
                                        <TableCell>{price === 'undefined' ? 0 : price}</TableCell>
                                        <TableCell align="right">{orderdata?.order_details.transactiontype === "SELL" ? `- ₹ ${value}` : `₹ ${value}`} </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='table_pagination' style={{display: 'flex',paddingTop:'10px', justifyContent: 'center'}}>
                <FirstPageIcon onClick={()=>{setPage(1)}} />
                <KeyboardArrowLeftIcon onClick={()=>{if(page !== 1){setPage(prev=>prev-1)}}}/>
                <h6>Page: {page}</h6>
                <KeyboardArrowRightIcon onClick={()=>{if(page !== Math.ceil(ordertransactions.length/10)){setPage(prev=>prev+1)}}}/>
                <LastPageIcon onClick={()=>{setPage(Math.ceil(ordertransactions.length/10))}}/>
            </div>
        </div>
    )
}

export default OrderBook


