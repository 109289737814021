import React from 'react';

// will style this page later

function NF() {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '5em'
      }}
    >
      <h3>404 | Page Not Found</h3>
      <h5>OOPS!</h5>
      <p>Something went wrong</p>
    </div>
  );
}

export default NF;
