import React, { useEffect, useState } from 'react';
import classes from './EditProfile.module.css';
import swal from 'sweetalert';
import 'reactjs-popup/dist/index.css';

import {
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChangeProfile from './ChangeProfile/ChangeProfile';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const HiddenLabelTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F',
        display: 'none'
    },
    '& legend': {
        display: 'none'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const DisabledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#5A5A5A !important'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#333333'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333 !important',
            color: '#5A5A5A'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#333333'
        }
    },
    '& label.Mui-root': {
        color: '#5A5A5A'
    },
    '& .MuiInputBase-root': {
        color: '#5A5A5A',
        backgroundColor: '#333333 !important'
    }
});

const CssSelect = styled(Select)({
    color: 'white',
    '& svg': {
        fill: '#00D37F'
    },
    '&:hover fieldset': {
        borderColor: '#00D37F !important'
    },
    '& input': {
        color: 'white'
    },
    '& fieldset': {
        borderColor: '#333333'
    },
    '&.Mui-focused fieldset': {
        borderColor: '#00D37F !important'
    }
});

const HiddenLabelSelect = styled(Select)({
    color: 'white',
    '& label.Mui-focused': {
        display: 'none'
    },
    '& legend': {
        display: 'none'
    },
    '& svg': {
        fill: '#00D37F'
    },
    '&:hover fieldset': {
        borderColor: '#00D37F !important'
    },
    '& input': {
        color: 'white'
    },
    '& fieldset': {
        border: '2px solid',
        borderColor: '#333333'
    },
    '&.Mui-focused fieldset': {
        borderColor: '#00D37F !important'
    }
});

const EditProfile = ({ user, setUser }) => {
    const [img, setImg] = useState();

    const initialValues = {
        Fname: user.name.split(" ")[0],
        Lname: user.name.split(" ")[1],
        mob: '',
        email: user.email,
        l1: '',
        l2: '',
        city: '',
        state: '',
        country: '',
        zipcode: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [mobNo, setMobNo] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const phoneregex =
            /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/;

        if (!values.Fname) {
            errors.Fname = 'Name is required!';
        }
        if (!values.email) {
            errors.email = 'Email is required!';
        } else if (!regex.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        }
        if (!values.mob) {
            errors.mob = 'Mobile number is required';
        } else if (!phoneregex.test(values.mob)) {
            errors.mob = 'Enter proper Phone number';
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            if (formValues.mob != mobNo) {
                fetch(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/phone/${user.id}`,
                    {
                        method: 'PATCH',
                        body: JSON.stringify({
                            phno: formValues.mob
                        }),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    }
                )
                    .then((response) => response.json())
            }
            fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/updateaddress/${user.id}`,
                {
                    method: 'PUT',
                    body: JSON.stringify({
                        line1: formValues.l1,
                        line2: formValues.l2,
                        city: formValues.city,
                        state: formValues.state,
                        country: formValues.country,
                        zipcode: formValues.zipcode
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    swal({
                        title: 'User Profile edited!',
                        text: 'your profile has been updated.',
                        icon: 'success',
                        timer: 3000
                    }).then(() => {
                    });

                })
                ;
        }
    }, [formErrors, formValues, isSubmit]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/${user.id}`)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else console.log(res);
            })
            .then((data) => {
                setMobNo(data.profile.user.phone_number);
                setFormValues({
                    Fname: data.profile.user.name.split(" ")[0],
                    Lname: data.profile.user.name.split(" ")[1],
                    mob: data.profile.user.phone_number,
                    email: data.profile.user.email,

                    l1: data.profile.address.line1,
                    l2: data.profile.address.line2,
                    city: data.profile.address.city,
                    state: data.profile.address.state,
                    country: data.profile.address.country,
                    zipcode: data.profile.address.zipcode
                });
            });
    }, []);

    return (
        <div className={classes.right_secn}>
            <div className={classes.top_secn}>
                <img className={classes.avatar} src={user.avatar} alt="" />
                <div className={classes.profile}>
                    <div className={classes.name}>{user.name}</div>
                    {/* <div className={classes.change} onClick={changeProfile}>
            Change Profile Photo
          </div> */}
                    <ChangeProfile img={img} setImg={setImg} />
                </div>
            </div>
            <div className={classes.editProfile}>
                <div className={classes.inputs}>
                    <div>
                        <CssTextField
                            label="First Name"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="Fname"
                            value={formValues.Fname}
                        />
                        {/* <p className={classes.errors}>{formErrors.Fname}</p> */}
                    </div>
                    <div>
                        <CssTextField
                            label="Last Name"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="Lname"
                            value={formValues.Lname}
                        />
                        {/* <p className={classes.errors}>{formErrors.name}</p> */}
                    </div>
                </div>
                <br />
                <div className={classes.inputs}>
                    <div>
                        <CssTextField
                            label="Email"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="email"
                            value={formValues.email}
                        />
                        {/* <p className={classes.errors}>{formErrors.email}</p> */}
                    </div>
                    <div>
                        <CssTextField
                            label="Mobile Number"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="mob"
                            value={formValues.mob}
                        />
                        <p className={classes.errors}>{formErrors.mob}</p>
                    </div>
                </div>
                <div className={classes.inputs}>
                    <div>
                        <CssTextField
                            label="Address Line 1"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="l1"
                            value={formValues.l1}
                        />
                        {/* <p className={classes.errors}>{formErrors.email}</p> */}
                    </div>
                    <div>
                        <CssTextField
                            label="Address Line 2"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="l2"
                            value={formValues.l2}
                        />
                        <p className={classes.errors}>{formErrors.l2}</p>
                    </div>
                </div>
                <div className={classes.inputs}>
                    <div>
                        <CssTextField
                            label="City"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="city"
                            value={formValues.city}
                        />
                        {/* <p className={classes.errors}>{formErrors.email}</p> */}
                    </div>
                    <div>
                        <CssTextField
                            label="State"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            disabled={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="state"
                            value={formValues.state}
                        />
                        <p className={classes.errors}>{formErrors.mob}</p>
                    </div>
                </div>
                <div className={classes.inputs}>
                    <div>
                        <CssTextField
                            label="Country"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            disabled={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="country"
                            value={formValues.country}
                        />
                        {/* <p className={classes.errors}>{formErrors.email}</p> */}
                    </div>
                    <div>
                        <CssTextField
                            label="Zip Code"
                            type="number"
                            id="custom-css-outlined-input"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={true}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="zipcode"
                            value={formValues.zipcode}
                        />
                        <p className={classes.errors}>{formErrors.zipcode}</p>
                    </div>
                </div>

                {/* <div className={classes.inputs}>
          <div>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: '#fff' }}
              >
                City
              </InputLabel>
              <CssSelect
                label="City"
                id="custom-css-outlined-input"
                IconComponent={KeyboardArrowDownIcon}
                InputLabelProps={{
                  style: { color: '#fff' }
                }}
                multiline={true}
                className={classes.first_tf}
                // onChange={handleChange}
                name="city"
                value={formValues.city}
              >
              </CssSelect>
            </FormControl>
          </div>
          <div>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: '#fff' }}
              >
                State
              </InputLabel>
              <CssSelect
                label="State"
                id="custom-css-outlined-input"
                IconComponent={KeyboardArrowDownIcon}
                InputLabelProps={{
                  style: { color: '#fff' }
                }}
                multiline={true}
                className={classes.first_tf}
                // onChange={handleChange}
                name="state"
                value={formValues.state}
              >
              </CssSelect>
            </FormControl>
          </div>
        </div>
        <br />
        <div className={classes.inputs}>
          <div>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: '#fff' }}
              >
                Country
              </InputLabel>
              <CssSelect
                label="Country"
                id="custom-css-outlined-input"
                IconComponent={KeyboardArrowDownIcon}
                InputLabelProps={{
                  style: { color: '#fff' }
                }}
                multiline={true}
                className={classes.first_tf}
                // onChange={handleChange}
                name="country"
                value={formValues.country}
              >
              </CssSelect>
            </FormControl>
          </div>
          <div></div> 
           </div> */}
            </div>
            <div className={classes.buttons}>
                <button className={classes.cancel}> Cancel </button>
                <button className={classes.save} onClick={handleSubmit}>
                    {' '}
                    Save{' '}
                </button>
            </div>
        </div>
    );
};

export default EditProfile;
