import React from 'react';
import { Container } from 'react-bootstrap';
import classes from './blogDetail.module.css';
import blogBanner from '../../assets/Rectangle15.png';
import blogDetail1 from '../../assets/blogDetails1.png';
import blogDetail2 from '../../assets/blogDetail2.png';
import PopularBlogs from '../PopularBlogs';
import moment from 'moment';

function BlogContent(props) {
  // column section
  const blog = props?.blog?.attributes ? props?.blog?.attributes : {};
  const blogId = props?.blog?.id ? props?.blog?.id : {};
  
  return (
    // <Box sx={{pt:5}}>
    <Container>
      <div key={blogId} className={classes.blogContent}>
        <button type="button" className={`btn ${classes.btnCard}`}>
          {
            blog?.categories?.data?.map((x, k) => {
              return (<p className={classes.subCardBtnText}>{x.attributes?.Name}</p>);
            })
          }
          
        </button>
        <div className={classes.date}>{moment(blog.publishedAt).format('Do MMMM, YYYY')}</div>
        <div className={classes.title}>
          {blog.Heading}
        </div>
        {/* <div className={classes.subHeading}>
          Money and Inflation : Analysing the rise in price
        </div> */}
         <img src={ blog?.CoverImage?.data?.attributes?.url ?  props.BlogBaseURL+"/st"+(blog?.CoverImage?.data?.attributes?.url) : blogBanner} alt="blog-details" className={classes.img} />
        {/* <img src={`${props.BlogBaseURL}/st/${blog.CoverImage.data.attributes.url}`} alt="blog-details" className={classes.img} /> */}
        
      {/*  <img src={props.BlogBaseURL+"/st"+(blog?.CoverImage?.data?.attributes?.url)} alt="blog-details" className={classes.img} /> */}

       {/*  <div className={classes.subTitle}>
          Money and Inflation : Analysing the rise in price
        </div> */}
        <div className={classes.description1} dangerouslySetInnerHTML={
          { __html: blog.Content }
        }>
          
        </div>
        <hr style={{marginTop: '50px'}}/>
        <div style={{marginTop: '50px'}}>
          <PopularBlogs />
        </div>
        {/* <div className={classes.List}>
          <p>1) Why are prices going up?</p>
          <p>2) Who was increasing the prices?</p>
          <p>3) Is there a way to manage this better?</p>
        </div>
        <div className={classes.description2}>
          Between 1990 and 2000, average inflation in India was 9.5% p.a. This
          meant that you had to spend Rs. 248 in 2000, to buy the exact item
          that you spent Rs. 100 on, in 1990. Having lived this reality, I
          developed a better sense of this phenomenon called inflation. Fuel
          prices increased by 3x during this decade- from Rs. 10 per liter to
          almost Rs. 30 per liter.
        </div>
        <div className={classes.subTitle1}>
          What is Inflation? How does it impact our lives?
        </div>
        <div className={classes.List1Title}>
          Let’s divide this discussion into two parts:
        </div>
        <div className={classes.List1}>
          I. Focus on understanding the concept of inflation II. Map the
          financial life of a typical family and the role that inflation plays
        </div>
        <ul>
          <li>Deep dive into the concept of Inflation</li>
        </ul>
        <div className={classes.description3}>
          Inflation is a rise in the prices of goods and services and we
          encounter it due to two factors: economic and lifestyle. Let’s
          understand both of them:
        </div>
        <div style={{ paddingTop: '123px' }}>
          <div className={classes.listTitleMain1}>1. Economic factors</div>
          <div className={classes.listcontentMain1}>
            <b>Cost-push inflation</b>: Increase in prices due to rising input
            costs. For example, a sugar company increases prices as a result of
            rising sugarcane prices. <br />
            <br />
            <b>Demand-pull inflation</b>: When the supply of money exceeds the
            demand, the purchasing power of money falls and too much money
            starts chasing too few goods. We are witnessing it currently because
            during the covid crisis, all the central banks increased the money
            supply and that has led to the higher prices.{' '}
          </div>
          <div className={classes.listImgText}>
            Simulating these parameters, the financial portfolio of the
            individual will tentatively appear as below:
            <img
              src={blogDetail1}
              alt="subImg"
              style={{ paddingTop: '20px', width:'100%' }}
            />
            <div className={classes.listImgBlowContent}>
              <span className={classes.listImgSubBelowContent}>
                Deep dive into the concept of Inflation
              </span>
              <br />
              <br />
              Inflation is a rise in the prices of goods and services and we
              encounter it due to two factors: economic and lifestyle. Let’s
              understand both of them:
            </div>
          </div>
        </div>
        <div style={{ paddingTop: '50px' }}>
          <div className={classes.listTitleMain1}>2. Economic factors</div>
          <div className={classes.listcontentMain1}>
            <b>Cost-push inflation</b>: Increase in prices due to rising input
            costs. For example, a sugar company increases prices as a result of
            rising sugarcane prices. <br />
            <br />
            <b>Demand-pull inflation</b>: When the supply of money exceeds the
            demand, the purchasing power of money falls and too much money
            starts chasing too few goods. We are witnessing it currently because
            during the covid crisis, all the central banks increased the money
            supply and that has led to the higher prices.{' '}
          </div>
          <img src={blogDetail2} alt="subImg" style={{ paddingTop: '20px', width:'100%' }} />
          <div className={classes.listImgBlowContent}>
            <span className={classes.listImgSubBelowContent}>
              Deep dive into the concept of Inflation
            </span>
            <br />
            <br />
            Inflation is a rise in the prices of goods and services and we
            encounter it due to two factors: economic and lifestyle. Let’s
            understand both of them:
          </div>
        </div>
        <div style={{ paddingTop: '93px' }}>
          <div className={classes.portfolioTitle}>
            How can you construct a portfolio that beats inflation?
          </div>
          <div className={classes.portfolioContent}>
            It is important to design a portfolio with reasonable exposure to
            the asset classes that perform better than inflation and can
            maintain and increase the purchasing power of the investments.
            However, it is important to note that during differing market
            conditions, the instruments within each of these classes might
            generate different returns.<br/><br/> In summary, inflation plays a
            significant role in management of finances and it is important to
            periodically measure its impact on the expenses and also to actively
            check whether your investments are able to keep pace with that. In
            case there is a gap, investors should modify their investment mix to
            meet/beat the inflation impact.{' '}
          </div>
          <div className={classes.portfolioLinks}>
            1.Starts earning at the age of 24 yrs. with a yearly salary of Rs. 6
            L, which grows by 9% every year<br/><br/>
            2.Retirement age – 60 yrs.; Life
            expectancy – 85 yrs<br/><br/>
            3.Personal milestones <br/>
            <ul>
                <li>a)Married at 28 yrs</li>
                <li>b)Two
            children are born- one at 30 yrs; the second at 32 yrs </li>
                <li>c)Home
            purchase at 40 years with an annual EMI (Equated Monthly
            Installment) of Rs. 15L for 20 years</li>
            </ul><br/>
            4.Economic inflation assumed at
            5% p.a. & lifestyle inflation at 3% p.a.<br/><br/>
            5.Savings from salary, post
            expenses, grow 9% annually
          </div>
        </div> */}
      </div>
      
    </Container>
  );
}

export default BlogContent;
