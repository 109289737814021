import React from 'react';
import classes from './PVCard.module.css';
import Loading from '../../../assets/Loading.svg';

function PVCard({ overall, totalpnl }) {
  let pnl = 20;
  let price;
  let x = Number(totalpnl).toFixed(2);
  if (totalpnl < 0) price = '- ₹' + `${Math.abs(x)}`;
  else price = '₹' + `${totalpnl}`;

  let nullprice = '₹ ' + overall;
  return (
    <>
      <div className={classes.pv_card}>
        <div className={classes.pv_text}>
          <p className={classes.h4}>Portfolio Value</p>
          <p className={classes.h5}>Equity + MF</p>
          <div className={classes.combined}>
            <div className={classes.h5down_section}>
              <p className={classes.h5}>Today’s P&L</p>
              <p
                className={`${classes.h6} ${
                  totalpnl < 0 ? classes.red : classes.green
                }`}
              >
                {price}
              </p>
            </div>
            <div className={classes.h6down_section}>
              <p className={classes.h5}>Deployed Capital</p>
              <p className={classes.h6}>{nullprice}</p>
            </div>
          </div>
        </div>
        <div className={classes.loading_widget}>
          <img src={Loading} alt="loading" />
        </div>
      </div>
    </>
  );
}

export default PVCard;
