import React from 'react';
import classes from './Invest.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import affilateBanner from '../../../../assets/affilate-banner.svg';
import Button from '../../../../components/Ui/Button';
import { motion } from 'framer-motion';
import ScrollAnimation from 'react-animate-on-scroll';
import higherprofit from '../../../../assets/higherprofit.png';
import commision from '../../../../assets/commision.png';
function Invest() {
  return (
    <Container fluid>
      <div className={classes.rightFluid}>
        <div className={classes.invest_container}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={classes.investText}>
                <div className={classes.subHeadingText}>
                  <p>Partner with us</p>
                </div>
                <ScrollAnimation duration={2} animateIn="fadeIn">
                  <h1>
                    Make <img src={higherprofit} alt="" /> higher profits{' '}
                    <span>
                      earn better commissions <img src={commision} alt="" />
                    </span>
                  </h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn="bounceInLeft">
                  <p>
                    Now as you grow your investments with MoneyFactory, you can
                    grow your passive income with our partner program.
                  </p>
                </ScrollAnimation>
                <ScrollAnimation duration={3} animateIn="fadeIn">
                  <a
                    href="/partner-with-us"
                    style={{
                      textDecoration: 'none'
                    }}
                  >
                    <Button
                      wrapperClass={classes.homeBtnWrapper}
                      btnClass={classes.homeBtn}
                    >
                      Partner with MoneyFactory
                    </Button>
                  </a>
                </ScrollAnimation>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={`${classes.mainImg}`}>
                <ScrollAnimation animateIn="flipInX">
                  <motion.nav
                    whileHover={{ scale: 1.0 }}
                    whileTap={{ scale: 0.8 }}
                  >
                    <img
                      src={affilateBanner}
                      alt="INVEST LIKE THE TOP 1% with Moneyfactory"
                      width="100%"
                    />
                  </motion.nav>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className={classes.leftGreenishCorner}></div>
    </Container>
  );
}

export default Invest;
