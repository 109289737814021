import React, { useState, useEffect } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Moment from 'react-moment';
import my_order_men from '../../../assets/my_order_men.png';
import { Link } from 'react-router-dom';

const OrderInfo = () => {
    useEffect(() => {
        var useid = JSON.parse(localStorage.getItem('MF_user')).id;
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/orders/${useid}`)
            .then((response) => response.json())
            .then((json) => {
                setOrderTransactions(json)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const [ordertransactions, setOrderTransactions] = useState();

    return (
        <div className='order_info_wrapper'>
            <div className='common_quants_title_wrap'>
                <h3>My Orders</h3>
                <Link to={{ pathname: `/profile`, selected: 2 }} className="all_links_text">See All</Link>
                <div className='order_type_wrap'>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <p>Today</p>
                            <span>
                                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7108 1.20986C13.6178 1.11613 13.5072 1.04174 13.3854 0.990969C13.2635 0.9402 13.1328 0.914062 13.0008 0.914062C12.8688 0.914062 12.7381 0.9402 12.6162 0.990969C12.4944 1.04174 12.3838 1.11613 12.2908 1.20986L7.71079 5.78985C7.61783 5.88358 7.50723 5.95797 7.38537 6.00874C7.26351 6.05951 7.13281 6.08565 7.00079 6.08565C6.86878 6.08565 6.73808 6.05951 6.61622 6.00874C6.49436 5.95797 6.38376 5.88358 6.29079 5.78985L1.71079 1.20986C1.61783 1.11613 1.50723 1.04174 1.38537 0.990969C1.26351 0.9402 1.1328 0.914062 1.00079 0.914062C0.868781 0.914062 0.738075 0.9402 0.616216 0.990969C0.494357 1.04174 0.383755 1.11613 0.290792 1.20986C0.104542 1.39722 0 1.65067 0 1.91486C0 2.17904 0.104542 2.4325 0.290792 2.61986L4.88079 7.20985C5.44329 7.77165 6.20579 8.08721 7.00079 8.08721C7.7958 8.08721 8.55829 7.77165 9.12079 7.20985L13.7108 2.61986C13.897 2.4325 14.0016 2.17904 14.0016 1.91486C14.0016 1.65067 13.897 1.39722 13.7108 1.20986V1.20986Z" fill="#CEFD1E" />
                                </svg>
                            </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Assisted</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Stimulated</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Automatic</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='order_tabpe_wrapper'>
                <TableContainer component={Paper} className="cm_table">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Quants</TableCell>
                                <TableCell>Instrument</TableCell>
                                <TableCell>Order Status</TableCell>
                                <TableCell>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                ordertransactions?.reverse().slice(0, 4).map((orderdata, idx) => {
                                    var price = orderdata?.order_details?.price ? orderdata?.order_details?.price : 0
                                    var qty = orderdata?.qty ? orderdata?.qty : 0
                                    var value = price * qty;
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>
                                                <div className='person_image_and_text'>
                                                    <div className='person_img'>
                                                        <img src={my_order_men} alt="my order" />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell><span>{orderdata.symbol}</span></TableCell>
                                            <TableCell className={orderdata.order_details.transactiontype === "SELL" ? "exit_color" : "entry_color "}>
                                                {orderdata.order_details.transactiontype === "SELL" ? "Exit" : "Entry"}</TableCell>
                                            <TableCell>₹ {value}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default OrderInfo