import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import classes from './privacyPolicy.module.css';

function PrivacyPolicy() {
  return (
    <div className={classes.privacyPolicyBg}>
      <Navbar />
      <Container>
        <div className={`${classes.privacyContent}`}>
          <h2>Privacy Policy</h2>
          <p>Effective date: 31-08-2022</p>
          <div>
            This <b>Privacy Policy</b> is created and published in accordance
            with Rule 3
            <br />
            <br />
            <div>
              <ul className={classes.mainList}>
                <li>
                  <h3>1. DEFINITIONS</h3>
                  <ul>
                    <li>
                      1.1. <b>“Account(s)”</b> refers to User profile(s)
                      (created after undergoing the Registration Process) by
                      which User(s) are able to create and share information,
                      personal messages, communications and other content.
                    </li>
                    <li>
                      1.2. <b>“Cookies”</b> are small files stored on your
                      device (computer or mobile device) while browsing through
                      the Platform or Application.
                    </li>
                    <li>
                      1.3. <b>“Fee(s)”</b> means the charges as prescribed in
                      the Platform and as payable by the User through the
                      payment gateways available on the Platform for availing
                      the Services available on the Platform.
                    </li>
                    <li>
                      1.4. <b>“End-User(s)”</b> refers to person or his/her
                      representative(s) or affiliate(s), who has/ have an
                      Account (after undergoing the Registration Process) with
                      the Platform want to make an Account through the Platform
                      to avail the Services provided therein.
                    </li>
                    <li>
                      1.5. <b>“Personal Information”</b> as defined under the
                      Information Technology (Reasonable Security Practices and
                      Procedures and Sensitive Personal Data or Information)
                      Rules, 2011 [the “SPI Rules”] means any information that
                      relates to a natural person, which, either directly or
                      indirectly, in combination with other information
                      available or likely to be available to a body corporate,
                      is capable of identifying such person.
                    </li>
                    <li>
                      1.6. <b>“Sensitive Personal Data or Information”</b> as
                      defined under Rule 3 of the Information Technology
                      (Reasonable Security Practices and Procedures and
                      Sensitive Personal Data or Information) Rules, 2011 [the
                      “SPI Rules”].{' '}
                    </li>
                    <li>
                      1.7.<b> “Registration Process”</b> means the process which
                      a User undergoes while registering himself/ herself/
                      themselves on the Platform thereby creating an Account in
                      accordance with the process mentioned in Clause [.] of the
                      Terms of Use available at [.]
                    </li>
                    <li>
                      1.8. <b>“Service(s)”</b> means and includes providing
                      Quantitative and Smart Investing Assistance through Stock
                      Brokers.{' '}
                    </li>
                    <li>
                      1.9. <b>“Usage Data”</b> is data collected automatically
                      either generated by the use of Service or from Service
                      infrastructure itself (for example, the duration of a page
                      visit).
                    </li>
                    <li>
                      1.10. <b>“You”, “Your”, “User” or “Users”</b> refers to
                      any individual, organisation, association or to his/her/
                      their representatives or affiliates, who utilises or
                      visits the Platform and includes “End-User(s)”.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <h3>
                    2. OBJECTIVE OF THIS PRIVACY POLICY 2.1 This Privacy Policy
                    is published in compliance with, inter alia:
                  </h3>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          (i) Section 43A of the Information Technology Act,
                          2000.
                        </li>
                        <li>
                          (ii) Rule 4 of the Information Technology (Reasonable
                          Security Practices and Procedures and Sensitive
                          Personal Information) Rules, 2011 [the{' '}
                          <b>“SPI Rules”</b>].
                        </li>
                        <li>
                          (iii) Rule 3(1) of the Information Technology
                          (Intermediaries Guidelines) Rules, 2011{' '}
                        </li>
                        <li>
                          (iv) Rule 3(1) (a) Information Technology
                          (Intermediary Guidelines and Digital Media Ethics
                          Code) Rules, 2021.
                        </li>
                      </ul>
                    </li>
                    <li>
                      2.2 This Privacy Policy mentions the type of information
                      collected from the Users, including Sensitive Personal
                      Data or Information, the purpose, means and modes of usage
                      of such information and how and to whom such information
                      may be disclosed.
                    </li>
                  </ul>
                </li>
                <li>
                  <br />
                  <h3>
                    3. PERSONAL INFORMATION AND/OR THE SENSITIVE PERSONAL DATA
                    OR INFORMATION COLLECTED BY THE COMPANY
                  </h3>
                  <br />
                  <ul>
                    For providing the Services that are available on the
                    Platform the Company requires information from the User(s)
                    for providing the User(s) with the suitable Services. The
                    information collected may include ‘Personal Information’
                    and/or ‘Sensitive Personal Data or Information’ as mentioned
                    under the SPI Rules. The User(s) understands that the
                    Personal Information and/or the Sensitive Personal Data or
                    Information is being voluntarily provided by you. You hereby
                    consent to the collection of such information by the
                    Company. Information collected by the Company from you may
                    inter alia include the following:
                    <ul>
                      <li>
                        <h4>A. PERSONAL DATA/ INFORMATION</h4>
                        <p>
                          Generally, the User(s) can visit and browse the
                          Platform without disclosing the personal information.
                          However, to fully use the Services provided by the
                          Company, via the Platform, the User(s) is/ are
                          required to undergo the Registration Process as
                          provided in the Terms of Use available at [.].
                          <br />
                          The Registration Process mentions the information that
                          is mandatorily required and the information that is
                          optional on behalf of the User(s). The User will have
                          the option to not to provide such information by
                          choosing not to use a particular Service or feature of
                          the Platform. The Personal Information that will be
                          required by the Platform (if so chosen) are as
                          follows:
                        </p>
                        <ul>
                          <li>
                            i. Contact Information: Information, inter alia,
                            including name, contact number, email address,
                            postal address, gender, date of birth, password of
                            the User’s Account etc.
                          </li>
                          <li>
                            ii. Information required for providing the Service:
                            The Platform may ask for information regarding phone
                            numbers, email and Demat Account that will be linked
                            with the Platform or any other detail (in accordance
                            with the prevailing laws) which may be required to
                            avail the Services on the Platform.
                          </li>
                          <li>
                            iii. Payment and billing Information: Financial
                            information such as bank accounts, credit and debit
                            card details or other payment instrument details,
                            payment method, billing name, billing address may be
                            collected. The payment information will be obtained
                            and processed by the Company’s online payment
                            partners such as (not limited to) Razorpay. The
                            payment information provided by the User(s) will be
                            shared with the payment gateway as chosen by the
                            User(s).
                          </li>
                          <li>
                            iv. Any other information: The Platform may collect
                            any other information which is freely available in
                            public domain or accessible or furnished under the
                            Right to Information Act, 2005 or any other law for
                            the time being in force, as the case may be, without
                            your consent. Such information shall not be regarded
                            as Personal Information or Sensitive Personal
                            Information.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4> B. USAGE DATA </h4>
                        <p>
                          The Company may also collect information that the
                          User’s browser sends whenever you visit the Platform
                          [the “Usage Data”].
                          <br />
                          This Usage Data may include information such as your
                          computer’s IP address, browser type, browser version,
                          the pages of our Platform that you visit, the time and
                          date of your visit, the time spent on those pages,
                          unique device identifiers and other diagnostic data.
                          <br />
                          When you access Service with a device, this Usage Data
                          may include information such as the type of device you
                          use, your device unique ID, the IP address of your
                          device, your device operating system, the type of
                          Internet browser you use, unique device identifiers
                          and other diagnostic data.
                          <br />
                        </p>
                      </li>
                      <li>
                        {' '}
                        <h4>C. LOCATION DATA</h4>
                        <p>
                          We may use and store information about your location
                          if you provide us permission for the same [“Location
                          Data”]. The Company use this data to provide features
                          of our Service, to improve & customize our Services,
                          and provide efficient Service to the User(s).
                          <br /> You have an option to enable or disable
                          location services, while using our Platform and the
                          Services provided by the Company, at any time by way
                          of your device settings.
                        </p>
                      </li>
                      <li>
                        <h4>D. TRACKING COOKIES DATA</h4>
                        <p>
                          We use data collection devices such as ‘Cookies’ and
                          similar tracking technologies to track the activity on
                          our Platform and we hold certain information. Cookies
                          are files with a small amount of data which may
                          include an anonymous unique identifier. <br />
                          Cookies are sent to your browser from the Platform and
                          stored on your device to help the Company to provide
                          information in the interest of the User(s). Cookies
                          are small files that store information on your
                          computer, mobile or any other device through which the
                          User(s) uses/ visits the Platform. These are used
                          inter-alia to remember your sign-in credentials, helps
                          the Company to understand the Services that are
                          essential to most of the User(s). You can decline the
                          use of Cookies in your browser. Since each browser is
                          different therefore the User(s) has to check with the
                          browser to learn how to manage Cookies. However, if
                          the User(s) opts to not accept Cookies, such User(s)
                          may not be able to avail some portions of the Services
                          offered on the Platform.
                        </p>
                      </li>
                    </ul>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>4. PURPOSE OF COLLECTION/ USAGE</h3>
                  <ul>
                    <li>
                      4.1. The Company may use the Personal Information and/or
                      the Sensitive Personal Data or Information provided by the
                      User(s), for the following purposes:
                    </li>
                    <li>
                      <ul>
                        <li>
                          a) for the purpose of providing you the Services;
                        </li>
                        <li>
                          b) for the purpose of improving the quality of the
                          Services;
                        </li>
                        <li>c) for marketing and promotional purposes;</li>
                        <li>
                          d) for the purpose of transactional communications;
                        </li>
                        <li>e) for the purpose of financial transactions;</li>
                        <li>
                          f) for the purpose of debugging customer support
                          related issues;
                        </li>
                        <li>
                          g) for the purpose of receiving feedbacks from the
                          User(s);
                        </li>
                        <li>
                          h) for the purpose of responding to summons, court
                          orders or other legal obligations;
                        </li>
                        <li>
                          i) for the purpose of research and generating
                          statistical analysis;
                        </li>
                        <li>
                          j) for the purpose as mentioned under the Information
                          Technology Act, 2000 and the rules thereunder.
                        </li>
                      </ul>
                    </li>
                    <li>
                      4.2. The Company or any person/organisation acting on
                      behalf of the Company shall not be responsible for the
                      authenticity and accuracy of the Personal Information
                      and/or the Sensitive Personal Data or Information provided
                      by the User(s).
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>5. RETENTION OF DATA</h3>
                  <ul>
                    <li>
                      5.1. The Company retains the Personal Information and/or
                      the Sensitive Personal Data or Information only for as
                      long as it is necessary for the purposes as mentioned
                      herein the Privacy Policy and for the purpose it was
                      collected.
                    </li>
                    <li>
                      5.2 The Company retains the Personal Information and/or
                      the Sensitive Personal Data or Information for the
                      duration for which the Service is provided to the User(s).
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>
                    6. OBLIGATIONS OF THE USER(S) FOR THE PERSONAL INFORMATION
                    AND/OR THE SENSITIVE PERSONAL DATA OR INFORMATION{' '}
                  </h3>
                  <ul>
                    <li>
                      6.1. You hereby consent to the collection and use of the
                      Personal Information and/or the Sensitive Personal Data or
                      Information, provided by you to the Company, for any of
                      the purposes stated herein.
                    </li>
                    <li>
                      6.2. The User agrees and understands that all the
                      information provided to the Company by the User(s),
                      including Personal Information and/or the Sensitive
                      Personal Data or Information, is voluntary.{' '}
                    </li>
                    <li>
                      6.3. For creating the Account, the User requires to
                      provide Personal Information and/or the Sensitive Personal
                      Data or Information such as the Username, User ID, email
                      address, country, ZIP/postal code, age, phone number,
                      password provided by the User and valid financial account
                      information.{' '}
                    </li>
                    <li>
                      6.4. The User(s) agrees and understands that the Company
                      is not liable for the authenticity of the Personal
                      Information and/or the Sensitive Personal Data or
                      Information provided/ supplied by the User(s).
                    </li>
                    <li>
                      6.5. The User(s) are responsible for the accuracy and
                      correctness of the information that you submit to the
                      Company.{' '}
                    </li>
                    <li>
                      6.6. If the User(s) intends to deregister his/her/their
                      Account or requests that their information no longer be
                      used, the User(s) can contact the Company by sending an
                      email addressed to support@moneyfactory.ai . Upon
                      receiving such request, the Company shall not keep such
                      information in its databases beyond the required time.{' '}
                    </li>
                    <li>
                      6.7. After the withdrawal of consent, or cancellation of
                      Account, the Company may discontinue or may not be able in
                      a position to provide the Services to such User(s).
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>
                    7. DISCLOSURE OF PERSONAL INFORMATION AND/OR THE SENSITIVE
                    PERSONAL DATA OR INFORMATION{' '}
                  </h3>
                  <ul>
                    <li>
                      7.1. The following is applicable to all the User(s):
                      <ul>
                        <li>
                          7.1.1. The User(s) confirms and agrees that the
                          Company does not sell or share any Personal
                          Information about the User(s) with other people/
                          organisation except to provide the Services under the
                          Terms of Use or this Privacy Policy.{' '}
                        </li>
                        <li>
                          7.1.2. The Company shall only disclose the Personal
                          Information and/or the Sensitive Personal Data or
                          Information only to the extent as will be necessary to
                          provide its Services as under:
                          <ul>
                            <li>
                              a) The Platform may require the User to pay a
                              certain amount of Fee via a credit card, debit
                              card for the Services rendered by the Company. The
                              User(s) may opt for the Company to collect and
                              store such User’s credit card number and/or other
                              financial information such as bank account
                              numbers, in a digitally encrypted format, for ease
                              of payment processes and future references.{' '}
                            </li>
                            <li>
                              b) When a User visits the Platform, the Company
                              will automatically receive the IP address of the
                              User’s computer, User’s operating system and type
                              of web browser that is being used. This will help
                              the Company to analyse the overall trends which
                              will help the Platform in improving its Services.
                            </li>
                            <li>
                              c) The Company, via the Platform, uses temporary
                              cookies to store certain data and for the purpose
                              of research and development, and for
                              administration purposes. These cookies do not
                              store any Personal Information and/or the
                              Sensitive personal data or information of the
                              User(s).{' '}
                            </li>
                            <li>
                              d) The Company has no control over the third party
                              websites that may be displayed as search results
                              or links from within the Services. These third
                              party websites may place their own cookies or
                              other files on the Users’ computer, collect data
                              or solicit personal information from the Users,
                              for which the Company shall not liable.
                            </li>
                            <li>
                              e) Other parties if so required by any law at the
                              time being in force and in compliance of the
                              Company’s legal obligation.{' '}
                            </li>
                            <li>
                              f) The Company shall disclose the Personal
                              Information and/or the Sensitive personal data or
                              information to Government agency if so mandated
                              under the law in force at the relevant time.{' '}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      7.2 The following information collected will not be shared
                      with any third party or disclosed to any person other than
                      as required by law; Sensitive Personal Data or Information
                      of any person being the password, financial information
                      such as Bank account or credit card or debit card or other
                      payment instrument details, sexual orientation or any
                      other sensitive personal information not essential for the
                      continued use of the Website.
                    </li>
                    <li>
                      7.3 We may share Personal Information with our other
                      corporate and /or associate entities and affiliates to
                      help detect and prevent identity theft, fraud and other
                      potentially illegal acts and cyber security incidents;
                      correlate related or multiple accounts to prevent abuse of
                      our services; and to facilitate joint or co-branded
                      services that you request where such services are provided
                      by more than one associate entity. Those entities and
                      affiliates may not market to you as a result of such
                      sharing unless you explicitly opt-in.
                    </li>
                    <li>
                      7.4 We may disclose Personal Information if required to do
                      so by law or in the good faith belief that such disclosure
                      is reasonably necessary to respond to summons, court
                      orders, or other legal process. We may disclose Personal
                      Information to law enforcement offices, third party rights
                      owners, or others in the good faith and belief that such
                      disclosure is reasonably necessary to: enforce our Terms
                      of Use or Privacy Policy; respond to claims that an
                      advertisement, posting or other content violates the
                      rights of a third party; or protect the rights, property
                      or personal safety of our users or the general public.
                    </li>
                    <li>
                      7.5 We give access of your Personal Information only to
                      those personnel’s/officials who are authorised in this
                      behalf and the third-parties who are hired by them to
                      perform administrative services. We do not guarantee that
                      your information will be kept confidential by these
                      parties and we will not liable in any manner for the loss
                      of confidentiality by them.{' '}
                    </li>
                    <li>
                      7.6 The Company has implemented best market practices and
                      security policies, rules and technical measures to protect
                      the Personal Data/ Information of the User(s) from
                      unauthorised access, improper use, disclosure of data,
                      unauthorised modification and damage or accidental loss.{' '}
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>
                    8. TRANSFER AND SECURITY OF PERSONAL INFORMATION AND/OR
                    SENSITIVE PERSONAL DATA OR INFORMATION{' '}
                  </h3>
                  <ul>
                    <li>
                      8.1 The Personal Information and/or the Sensitive Personal
                      Data or Information of the User(s) is maintained by the
                      Company in its secured/ encrypted database. The Company
                      may also keep the records of electronic communications
                      with the User(s) through the Platform for the limited
                      purpose as stated herein the Privacy Policy and for
                      customer support, research and development, for
                      administrative purpose.
                    </li>
                    <li>
                      8.2 The Personal Information and/or the Sensitive Personal
                      Data or Information of the User(s) may be transferred to
                      and maintained on a secured/ encrypted database outside
                      your State or country and the laws might differ
                      accordingly.{' '}
                    </li>
                    <li>
                      8.3 The User(s) agrees and understands that the Personal
                      Information and/or the Sensitive Personal Data or
                      Information provided by the User(s) are shared with the
                      employees of the Company who are authorised in this behalf
                      and on need-to-know basis for the purpose of rendering
                      Services to the User(s).{' '}
                    </li>
                    <li>
                      8.4 The User(s) understands that all the employees of the
                      Company, who have access to such Personal Information
                      and/or the Sensitive Personal Data or Information are
                      obligated to keep the same confidential. The Company is
                      committed towards maintaining the Personal Information
                      and/or the Sensitive Personal Data or Information of the
                      User(s) secret and confidential
                    </li>
                    <li>
                      8.5 The Company may transfer the Personal Information
                      and/or the Sensitive Personal Data or Information to third
                      party service provider for providing better Services on
                      behalf of the Company. The third party shall also maintain
                      and ensure protection and confidentiality of the Personal
                      Information and/or the Sensitive personal data or
                      information of such User(s).
                    </li>
                    <li>
                      8.6 If the Company or its subsidiaries, if any, are
                      involved in a merger, acquisition or asset sale the
                      Personal information and the Personal Information and/or
                      the Sensitive Personal Data or Information of the User(s)
                      may be transferred in accordance with law and also in
                      accordance the agreements executed for such merger(s)/
                      acquisition(s)/ asset sale(s).
                    </li>
                    <li>
                      8.7 The Platform takes all reasonable precautions to
                      protect the Users’ data (whether online or offline) and
                      User(s) Personal Information and/or the Sensitive Personal
                      Data or Information. The User(s) understands that no one,
                      except the End-User(s) will have access to End-Users’
                      passwords for its Account.{' '}
                    </li>
                    <li>
                      8.8 The Company shall not be liable to indemnify the
                      User(s) for any loss suffered by the latter due to
                      unauthorized use of your Account and the password thereof.{' '}
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>9. AMENDMENTS IN PRIVACY POLICY</h3><br/>
                  <p>
                    The Company may update the Privacy Policy from time to time.
                    The Platform will notify the Users of any changes by posting
                    the new Privacy Policy on the website available at[.] The
                    Company retains the right to change, modify or terminate the
                    terms of this Privacy Policy, or any portion of this Privacy
                    Policy, at any time by publishing such changes on the
                    Website. The amended Privacy Policy would be effective as on
                    the date of change, which the Company would post on the
                    Website and it shall be binding upon you from such date. If
                    you do not agree to any of the terms and conditions in the
                    amended Policy, you may discontinue from using the service
                    available on the Platform immediately.
                    <br />
                    <br />
                    The Users are advised to review this Privacy Policy
                    periodically for any changes. Use of information the Company
                    collects, is subject to the Privacy Policy in effect at the
                    time such information is used. Your continued use of the
                    Services following the posting of changes to this Privacy
                    Policy will constitute your consent and acceptance of those
                    changes.
                  </p>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>10. RESTRICTIONS ON USAGE</h3>
                  <ul>
                    <li>
                      10.1. You are not permitted to host, display, upload,
                      modify, publish, transmit, update, share or distribute any
                      information that:
                      <ul>
                        <li>
                          i. belongs to another person and over which you have
                          no right upon;{' '}
                        </li>
                        <li>
                          ii. is defamatory, obscene, pornographic, grossly
                          harmful, harassing, blasphemous, defamatory, obscene,
                          paedophilic, libellous, invading another's privacy,
                          including bodily privacy, insulting or harassing on
                          the basis of gender, hateful, libellous, racially or
                          ethnically objectionable, disparaging, relating to or
                          encouraging money laundering or gambling, or otherwise
                          unlawful in any way;{' '}
                        </li>
                        <li>
                          iii. harms minors in any way; infringes on the rights
                          of minor;
                        </li>
                        <li>
                          iv. infringes on any patent, trademark, copyright, or
                          other proprietary rights; violates any current law;{' '}
                        </li>
                        <li>
                          v. includes software viruses or any other computer
                          code, files, or programmes designed to disrupt,
                          damage, or limit the functioning of any computer
                          resource;{' '}
                        </li>
                        <li>
                          vi. is patently false and untrue, and is written or
                          published in any form, with the intent to mislead or
                          harass a person, entity or agency for financial gain
                          or to cause any injury to any person.
                        </li>
                      </ul>
                    </li>
                    <li>
                      10.2. The Company reserves the right to delete any content
                      and/or remove any non-compliant information from the
                      Platform that violates any of the foregoing criteria.
                    </li>
                    <li>
                      10.3. The Company also reserves its right to terminate the
                      access to any User(s) immediately in case of
                      non-compliance with the Terms of Use and Privacy Policy.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>11. CONSENT TO THIS POLICY</h3><br/>
                  <p>
                    The User voluntarily acknowledges that this Privacy Policy
                    is a part of the Terms of Use of the Platform. The User
                    agrees that visiting the Platform or utilising the Services
                    of the Platform the User signifies its consent to this
                    Privacy Policy. User’s visit to the Website and use of the
                    Services is subject to this Privacy Policy and the Terms of
                    Use.
                  </p>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>12. JURISDICTION</h3>
                  <ul>
                    <li>
                      12.1 This Policy is governed by Indian law, which
                      includes, but is not limited to, the following:
                      <ul>
                        <li>i. The Indian Contract Act, 1872;</li>
                        <li>
                          ii. The (Indian) Information Technology Act, 2000;
                        </li>
                        <li>
                          iii. The (Indian) Information Technology (Reasonable
                          Security Practices and Procedures and Sensitive
                          Personal Information) Rules, 2011;{' '}
                        </li>
                        <li>
                          iv. The (Indian) Information Technology
                          (Intermediaries Guidelines) Rules, 2011;
                        </li>
                        <li>
                          v. The Information Technology (Intermediary Guidelines
                          and Digital Media Ethics Code) Rules, 2021.
                        </li>
                      </ul>
                    </li>
                    <li>
                      12.2 This Platform was created in Bengaluru. The laws of
                      the State of Karnataka will apply to this Policy. You
                      consent to the jurisdiction and venue of the courts in
                      Bengaluru, India in connection with any action, suit,
                      process, or claim arising under or by reason of this
                      Privacy Policy by using this Platform.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  {' '}
                  <h3>13. GRIEVANCE CELL </h3><br/>
                  <p>
                    If any User has any question regarding this Privacy Policy,
                    access or usage of computer resource, and/or to register any
                    complaint such User can approach the Grievance Officer
                    appointed by the Company. The User(s) may reach out the
                    Grievance Officer vide an email addressed to
                    Arun.kumar@moneyfactory.ai , the official email-id of the
                    Grievance Officer. <br />
                    <br />
                    The Grievance Officer and the Company shall make reasonable
                    and prompt efforts to resolve the issue of the User(s). The
                    details of the Grievance Officer are mentioned herein below:
                    <br />
                    <br />
                    <p>
                      Name : <b>Arun Kumar.R</b>{' '}
                    </p>
                    <p>
                      Address:
                      <b>
                        {' '}
                        #87/1, 1st Floor, Coles Rd, Pulikeshi Nagar- 560005
                        Bengaluru, Karnataka{' '}
                      </b>{' '}
                    </p>
                    <p>
                      Phone: <b>805021167</b>{' '}
                    </p>
                    <p>
                      Email: <b>Arun.kumar@moneyfactory.ai</b>{' '}
                    </p>
                    <p>
                      Availability: <b>9:00 AM to 6:00 PM</b>{' '}
                    </p>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <Footer joinWithUs={false} childComponent={false} />
    </div>
  );
}
export default PrivacyPolicy;
