import React, { useEffect, useState } from 'react';
import ABCard from '../../components/MarketPlace/ABCard/ABCard';
import Cashback from '../../components/MarketPlace/Cashback/Cashback';
import PVCard from '../../components/MarketPlace/PVCard/PVCard';
import classes from './Dashboard.module.css';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar';
import ActiveQ from '../../components/MarketPlace/ActiveQ/ActiveQ';
import NewDeployedBar from '../../components/MarketPlace/NewDeployedBar/NewDeployedBar';

// for now static data

function Dashboard() {
  let cancel = false;
  const [broker, setbroker] = useState(false);
  const [name, setName] = useState('Test');
  const [myQuants, setMyQuants] = useState([]);
  const [card, setcard] = useState(() => new Set());
  const [fivepaise, setfivepaise] = useState(false);
  const [angelone, setangelone] = useState(false);
  const [overall, setoverall] = useState(0.0);
  const [totalpnl, settotalpnl] = useState(0);

  // const calc = (subs, ltp) => {
  //     return Math.round(
  //         ((Math.round(ltp * Math.round(subs / ltp)) * 100) / 100 - subs) * 100
  //     );
  // };

  // const calcTotalPnl = (quants) => {
  //     let total = 0;
  //     quants.stocksignals.map((ele) => {
  //         total += calc(ele.subscribed_price, ele.ltp);
  //     });

  //     return isNaN(total) ? 0 : total / 100;
  // };

  const calc = (qty, ltp, price) => {
    return Math.round(
      ((Math.round(ltp * Math.round(qty)) * 100) / 100 - price * qty) * 100
    );
  };
  const calcTotalPnl = (quants) => {
    let total = 0;
    quants.stocksignals.map((ele) => {
      total += ele?.pldata?.pl;
    });

    return isNaN(total) ? 0 : Number(total).toFixed(2);
  };

  // const calcTotalPnl = (quantDetails) => {
  //   let total = 0;
  //   quantDetails.stocksignals.map((ele) => {
  //     var price = ele?.signal === 1 ? ele?.transactions[0].entry_price : 0;

  //     if (ele.ltp) {
  //       total += calc(ele.qty, ele.ltp, price);
  //     }
  //   });
  //   return isNaN(total) ? 0 : total / 100;
  // };

  useEffect(() => {
    if (cancel) return;
    setName(JSON.parse(localStorage.getItem('MF_user')).name);
    const userId = JSON.parse(localStorage.getItem('MF_user')).id;
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/activequants/${userId}`
    )
      .then((res) => {
        if (!res.ok) {
          // get error message from body or default to response status
          const error = 'Server error';
          return Promise.reject(error);
        }
        return res.json();
      })
      .then((res) => {
        setMyQuants(res.activeQuants);
        let ovtmp = 0,
          r = 0;
        res.activeQuants.forEach((q) => {
          let capital = (ovtmp += q.quant.quant.price * q.quant.quantity);
          r += Number(calcTotalPnl(q));
        });
        setoverall(ovtmp);
        // let r = calcTotalPnl(res.activeQuants);
        settotalpnl(r.toFixed(2));
      })
      .catch((err) => {
        console.log(err);
      });

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/${userId}`)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject('error');
        }
        return res.json();
      })
      .then((res) => {
        res.profile.broker_accounts.forEach((element) => {
          // if (element.brokerId === 1) setfivepaise(true);
          // if (element.brokerId === 2) setangelone(true);
          // let newcard = card;
          // newcard.push(element.brokerId);
          // setcard(newcard);
          setcard((prev) => new Set(prev).add(element.brokerId));
        });

        // if (res.profile.broker_accounts.length != 0) {

        // setbroker(true);
        // fetch(
        //   `${process.env.REACT_APP_BACKEND_BASE_URL}/api/fivepaisabroker/account/margins/${userId}`
        // )
        //   .then((res) => {
        //     if (res.ok) {
        //       var ncard = [...card];
        //       ncard.push('5');
        //       setcard(ncard);
        //       return res.json();
        //     } else {
        //       return Promise.reject('error');
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });

        // fetch(
        //   `${process.env.REACT_APP_BACKEND_BASE_URL}/api/angelonebroker/account/margins/${userId}`
        // )
        //   .then((res) => {
        //     if (res.ok) {
        //       setcard((oldArray) => [...oldArray, 'angel']);
        //       return res.json();
        //     } else {
        //       return Promise.reject('error');
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        // }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      cancel = true;
    };
  }, []);


  return (
    <>
      <Sidebar>
        <div className={classes.dashboard_container}>
        <h4 className={classes.db_header}>Hello, {name}</h4>
          <div className={classes.inner_container}>
            <div className={classes.card_section}>
              {card.size === 0 ? (
                <ABCard i={0} />
              ) : (
                [...card].map((c) => {
                  return <ABCard i={c} key={c} />;
                })
              )}
              <PVCard totalpnl={totalpnl} overall={overall} />
              <Cashback />
            </div>
          </div>
          <div className={classes.right_section}>
            <ActiveQ myQuants={myQuants} />
            <NewDeployedBar myQuants={myQuants} />
          </div>
        </div>
      </Sidebar>
    </>
  );
}

export default Dashboard;
