import React from 'react';
import classes from './home.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, Container, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import icici from '../../assets/home/icici.svg';
import angelOne from '../../assets/home/angelOne.svg';
import fivePaisa from '../../assets/home/5paisa.svg';
import zeroDha from '../../assets/home/zeroDha.svg';
import upstox from '../../assets/home/upstox.svg';
import ScrollAnimation from 'react-animate-on-scroll';
const easySteps = [
  {
    img: angelOne
  },
  {
    img: zeroDha
  },

  {
    img: upstox
  },
  {
    img: icici
  },

  {
    img: fivePaisa
  }
];

function OurClients() {
  return (
    <div className={classes.client_container}>
      <Container>
        <ScrollAnimation duration={2} animateIn='fadeIn'>
        <h2>
          start making
          <span className={classes.subHeadingTestimonial}> a <span className={classes.bgTitleLine}>profit</span></span>
        </h2>
        </ScrollAnimation>
        <p className={classes.clientTitle}>
          We work only with the most trusted financial brands
        </p>
        <Row className={classes.clientLogo+' align-items-center justify-content-center'}>
          {easySteps.map((x, k) => {
            return (
              <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3`} key={k + 1}>
                {/* <Card className={classes.clientCards}>
                  <Card.Body>
                    <Card.Text> */}
                <div className={classes.clientCards}>
                  <img src={x.img} alt="invest" className={classes.clientCardsImg}/>
                </div>
                {/* </Card.Text>
                  </Card.Body>
                </Card> */}
              </div>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default OurClients;
