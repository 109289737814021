import React, { useState, useEffect } from 'react'
import classes from './Filter.module.css';
import Search from '../../../assets/search.svg';
import Close from '../../../assets/close.svg';

function Filter({ filterInterface, handleFilterchoice, filterchoice }) {

    const [selectedMapFilters, setSelectedMapFilters] = useState([{ SORTBY: '' }, { PRICE: '' }, { SUBCATEGORIES: [] }, { EXCHANGES: [] }, { FAVOURITE: '' }]);
    const [showAllTags, setShowAllTags] = useState(false);

    useEffect(() => {
        selectgiventags(selectedMapFilters);
    }, [showAllTags])

    useEffect(() => {
        setSelectedMapFilters(JSON.parse(JSON.stringify(filterchoice)));
        selectgiventags(filterchoice);
        selectGivenInputTags();
    }, [filterchoice])

    const close_filter = () => {
        const filt = document.querySelector('.filter');
        document.body.style.height = "initial";
        document.body.style.overflow = "auto";
        filt.style.transform = "translateX(320px)";
        setSelectedMapFilters(JSON.parse(JSON.stringify(filterchoice)));
        selectgiventags(filterchoice);
        selectGivenInputTags();
    }

    const reset = () => {
        setSelectedMapFilters([{ SORTBY: '' }, { PRICE: '' }, { SUBCATEGORIES: [] }, { EXCHANGES: [] }, { FAVOURITE: '' }])
        let TagButtons = document.querySelectorAll(".TagButton");
        TagButtons.forEach((tag) => {
            tag.classList.remove("selected");
        })
        let inputButtons = document.querySelectorAll(`input[type="radio"],input[type="checkbox"]`);
        inputButtons.forEach((input) => {
            input.checked = false;
        })
        handleFilterchoice([{ SORTBY: '' }, { PRICE: '' }, { SUBCATEGORIES: [] }, { EXCHANGES: [] }, { FAVOURITE: '' }]);
    }

    const apply = () => {
        handleFilterchoice(JSON.parse(JSON.stringify(selectedMapFilters)));
        close_filter();
    }

    const handletag = (e) => {
        e.target.classList.toggle('selected');
        let newMapFilters = [...selectedMapFilters];
        newMapFilters.forEach((ele) => {
            if (ele.hasOwnProperty("SUBCATEGORIES")) {
                let flag = 1;
                let newTags = ele["SUBCATEGORIES"].filter((tag) => {
                    if (tag != e.target.value) {
                        return true;
                    } else {
                        flag = 0;
                        return false;
                    }
                })
                if (flag) {
                    newTags.push(e.target.value);
                }
                ele["SUBCATEGORIES"] = newTags;
            }
        })
        setSelectedMapFilters([...newMapFilters]);
    }

    const handleradio = (e) => {
        let newMapFilters = [...selectedMapFilters];
        newMapFilters.forEach((ele) => {
            if (ele.hasOwnProperty(e.target.name)) {
                ele[e.target.name] = e.target.value;
            }
        })
        setSelectedMapFilters([...newMapFilters]);
    }

    const selectGivenInputTags = () => {
        const givenInputTags = document.querySelectorAll(`input[type="Checkbox"],input[type="radio"]`);
        givenInputTags.forEach((input) => {
            filterchoice.forEach((ele) => {
                if (ele.hasOwnProperty(input.name)) {
                    if (Array.isArray(ele[input.name])) {
                        let flag = 1;
                        ele[input.name].forEach((exchange) => {
                            if (input.value == exchange) {
                                input.checked = true;
                                flag = 0;
                            }
                        })
                        if (flag) {
                            input.checked = false;
                        }
                    } else {
                        if (ele[input.name] == input.value) {
                            input.checked = true;
                        } else {
                            input.checked = false;
                        }
                    }
                }
            })
        })
    }
    // Select the SUBCATEGORIES which are present in the selectedMapFilters
    const selectgiventags = (filter) => {
        let TagButtons = document.querySelectorAll(".TagButton");
        TagButtons.forEach((e) => {
            filter.forEach((ele) => {
                if (ele.hasOwnProperty("SUBCATEGORIES")) {
                    let flag = 1;
                    ele["SUBCATEGORIES"].forEach((tag) => {
                        if (e.value == tag) {
                            e.classList.add("selected");
                            flag = 0;
                        }
                    })
                    if (flag) {
                        e.classList.remove("selected");
                    }
                }
            })
        })
    }

    const handleShowAllTags = () => {
        setShowAllTags(!showAllTags);
    }

    const handleCheckBox = (e) => {
        let newMapFilters = [...selectedMapFilters];
        newMapFilters.forEach((ele) => {
            if (ele.hasOwnProperty(e.target.name)) {
                let flag = 1;
                let newTags = ele[e.target.name].filter((tag) => {
                    if (tag != e.target.value) {
                        return true;
                    } else {
                        flag = 0;
                        return false;
                    }
                })
                if (flag) {
                    newTags.push(e.target.value);
                }
                ele[e.target.name] = newTags;
            }
        })
        setSelectedMapFilters([...newMapFilters]);
    }


    // const handleRadioButton=(e)=>{

    // }

    return (
        <div className={`${classes.filter} filter`}>
            <div className={classes.heading}>
                <h3>Filter</h3>
                <img src={Close} onClick={() => { close_filter() }} alt="" />
            </div>
            <div>
                <h4>Search Strategies</h4>
                <div className={classes.search}>
                    <input type="text" name="search" id="" placeholder='Search' />
                    <img src={Search} alt="search" />
                </div>
                <div>
                    <h4>Sort By</h4>
                    {Object.keys(filterInterface).length !== 0 ? filterInterface.SORTBY.map((ele) => (
                        <div className={classes.input_button} key={ele.key}>
                            <input type="radio" name="SORTBY" id={ele.key} value={ele.key} onChange={handleradio} />
                            <label htmlFor={ele.key}>{ele.value}</label>
                        </div>
                    )) : ""
                    }
                    <h4>SUBCATEGORIES</h4>
                    <div className={classes.tags}>
                        {showAllTags ? Object.keys(filterInterface).length !== 0 ? filterInterface.SUBCATEGORIES.map((ele) => (
                            <button key={ele._id} onClick={(e) => { handletag(e) }} className="TagButton" value={ele._id}>{ele.name}</button>
                        )) : "" : Object.keys(filterInterface).length !== 0 ? filterInterface.SUBCATEGORIES.filter((ele, ind) => { return ind < 4 }).map((ele) => (
                            <button key={ele._id} onClick={(e) => { handletag(e) }} className="TagButton" value={ele._id}>{ele.name}</button>
                        )) : ""
                        }
                        <button onClick={(e) => { handleShowAllTags() }}>{showAllTags ? "Show Less" : "Show More"}</button>
                    </div>
                    <div>
                        <h4>Exchanges</h4>
                        {Object.keys(filterInterface).length !== 0 ? filterInterface.EXCHANGES.map((ele) => (
                            <div className={classes.input_button} key={ele.value}>
                                <input type="checkbox" name="EXCHANGES" onChange={handleCheckBox} id={ele.value} value={ele.value} />
                                <label htmlFor={ele.value}>{ele.value}</label>
                            </div>
                        )) : ""
                        }
                        <div>
                            <h4>Fixed Prices</h4>
                            <div className={classes.input_button}>
                                <input type="radio" name="PRICE" onChange={handleradio} id="free" value="Unpaid" />
                                <label htmlFor="free">Free</label>
                            </div>
                            <div className={classes.input_button}>
                                <input type="radio" name="PRICE" onChange={handleradio} id="paid" value="Paid" />
                                <label htmlFor="paid">Paid</label>
                            </div>
                            <h4>Favourite</h4>
                            <div className={classes.input_button}>
                                <input type="radio" name="FAVOURITE" onChange={handleradio} id="strategy" value="strategy" />
                                <label htmlFor="strategy">Strategy</label>
                            </div>
                            <div className={classes.button}>
                                <button onClick={reset}>Reset</button>
                                <button onClick={apply}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter