import React,{useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import Sidebar from "../../components/MarketPlace/Sidebar/Sidebar";
import classes from "./CreateStrategy.module.css";
import swal from 'sweetalert';
import { TextField,Select,MenuItem,InputLabel,FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {ReactComponent as CustomClose} from "../../assets/customClose.svg";
import ListBuilder from '../../components/MarketPlace/ListBuilder/ListBuilder';


const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#00D37F',
  },
  '&:hover .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#00D37F'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333333'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& label.Mui-root': {
    color: 'white'
  },
  '& .MuiInputBase-root': {
    color: 'white'
  }
});

const HiddenLabelTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#00D37F',
    display: "none",
  },
    "& legend": {
      display: "none"
  },
  '&:hover .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#00D37F'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333333'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& label.Mui-root': {
    color: 'white'
  },
  '& .MuiInputBase-root': {
    color: 'white'
  }
});

const DisabledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#5A5A5A !important',
  },
  '&:hover .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333333'
    }
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#333333'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333333 !important',
      color:"#5A5A5A",
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333333'
    }
  },
  '& label.Mui-root': {
    color: '#5A5A5A'
  },
  '& .MuiInputBase-root': {
    color: '#5A5A5A',
    backgroundColor:"#333333 !important",
  }
});

const CssSelect = styled(Select)({
    color:"white",
    '& svg':{
      fill:"#00D37F"
    },
    '&:hover fieldset':{
      borderColor:'#00D37F !important'
    },
    '& input':{
      color:'white'
    },
    '& fieldset':{
      borderColor:"#333333",
    },
    '&.Mui-focused fieldset': {
      // color:'black',
      borderColor: '#00D37F !important'
    }
});

const HiddenLabelSelect = styled(Select)({
  color:'white',
  "& label.Mui-focused": {
      display: "none"
    },
    "& legend": {
      display: "none"
    },
'& svg':{
  fill:"#00D37F"
},
'&:hover fieldset':{
  borderColor:'#00D37F !important'
},
'& input':{
  color:'white'
},
'& fieldset':{
    border: '2px solid',
    borderColor:"#333333",
    
},
'&.Mui-focused fieldset': {
  // color:'black',
  borderColor: '#00D37F !important'
}
});

function CreateStrategy() {
  const history = useHistory();
  const [categories,setCategories]=useState([]);
  const [subCategories,setSubCategories]=useState([]);
  const [strategies,setStrategies]=useState([]);
  const [functionality,setFunctionality]=useState([]);
  const [user,setUser]=useState("");
  const [tagFilter,setTagFilter]=useState([]);
  const [quantDetails,setQuantDetails]=useState({name:"",imgUrl:"",status:"active",risk:"",tags:[],description:"",frequency:"weekly",createdBy:"MF_Admin",price:"5000",categories:[{categoryId:1}],subCategories:[],stocks:[],functionality:[]})
  const [quantErrors, setQuantErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [reactivate,setReactivate]=useState("never");
  const [checkCondition,setCheckCondition]=useState(1);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  // const [selected,setSelected] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuantDetails({ ...quantDetails, [name]: value });
  };

  const handleCategory=(e)=>{
    let newCategory=e.target.value;
    let newQuantDetails={...quantDetails};
    newQuantDetails['categories']=[{categoryId:newCategory}];
    setQuantDetails(newQuantDetails);
  }

  const handlefunctionality=(e)=>{
    let flag=1;
    let newfunctionalityId=e.target.value[e.target.value.length - 1][0];
    let newfunctionalityName=e.target.value[e.target.value.length - 1][1];
    quantDetails['functionality'].forEach((ele)=>{
      if(ele.functionalityId==newfunctionalityId){
        flag=0;
      }
    })

    if(flag){
      let newQuantDetails={...quantDetails};
      newQuantDetails['functionality']=[...quantDetails['functionality'],{functionalityId:newfunctionalityId,functionalityName:newfunctionalityName}];
      setQuantDetails(newQuantDetails);
    }
  }

  const handleSubCategory=(e)=>{
      let flag=1;
      let newsubCategoryId=e.target.value[e.target.value.length - 1][0];
      let newsubCategoryName=e.target.value[e.target.value.length - 1][1];

      quantDetails['subCategories'].forEach((ele)=>{
        if(ele.subCategoryId==newsubCategoryId){
          flag=0;
        }
      })

      if(flag){
        let newQuantDetails={...quantDetails};
        newQuantDetails['subCategories']=[...quantDetails['subCategories'],{subCategoryId:newsubCategoryId,subCategoryName:newsubCategoryName}];
        setQuantDetails(newQuantDetails);
      }
  }

  const handleDelete=(data)=>{
    let newtags=quantDetails.tags.filter((ele)=>ele!=data);
    let newQuantDetails={...quantDetails};
    newQuantDetails['tags']=newtags;
    setQuantDetails(newQuantDetails);
  }

  const handleSubCategoryDelete=(data)=>{
    let newsubCategory=quantDetails.subCategories.filter((ele)=>ele.subCategoryId!=data);
    let newQuantDetails={...quantDetails};
    newQuantDetails['subCategories']=newsubCategory;
    setQuantDetails(newQuantDetails);
  }

  const handleFunctionalityDelete=(data)=>{
    let newFunctionality=quantDetails.functionality.filter((ele)=>ele.functionalityId!=data);
    let newQuantDetails={...quantDetails};
    newQuantDetails['functionality']=newFunctionality;
    setQuantDetails(newQuantDetails);
  }

  const handleStocks=(details)=>{
    let newQuantDetails={...quantDetails};
    newQuantDetails['stocks']=details;
    setQuantDetails(newQuantDetails);
  }

  const handleReactivate=(e)=>{
    setReactivate(e.target.value);
  }

  const handleCondition=(e)=>{
    setCheckCondition(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuantErrors(validate(quantDetails));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Name is required!';
    }
    if (!values.description) {
      errors.description = 'Description is required!';
    }
    if (!values.imgUrl) {
      errors.imgUrl = 'ImgURL is required';
    } 

    if (!values.risk) {
      errors.risk = 'Risk has to be selected';
    }

    if(!values.tags.length){
      errors.tags="atleast one tag has to be selected";
    }

    if(!values.subCategories.length){
      errors.subCategories="Atleast one sub-category needs to be selected";
    }

    if(!values.functionality.length){
      errors.functionality="Atleast one functionality needs to be selected";
    }

    if(isNaN(quantDetails.price)){
      errors.price="Enter a valid number";
    }else{
      setQuantDetails({...quantDetails,["price"]:parseInt(quantDetails.price)})
    }

    return errors;
  };

  useEffect(()=>{
    if(localStorage.getItem("MF_user")){
      setUser(JSON.parse(localStorage.getItem("MF_user")));
    }else{
     swal({
       title: 'Please login to your account to continue!',
       text: 'you will be redirected to Sign in page in 3 seconds',
       icon: 'warning',
       timer: 3000
     }).then(() => {
       setTimeout(() => {
         history.push('/signin');
       }, 750);
     });
    }
  },[])

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/categories`,{
      mode:'cors',
    })
    .then((res)=>{
    return res.json();
    })
    .then((res)=>{
      setCategories(res);
    })

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/subcategories`,{
      mode:'cors',
    })
    .then((res)=>{
    return res.json();
    })
    .then((res)=>{
      setSubCategories(res);
    })

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/strategies`,{
      mode:'cors',
    })
    .then((res)=>{
    return res.json();
    })
    .then((res)=>{
      setStrategies(res);
    })

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/functionality`,{
      mode:'cors',
    })
    .then((res)=>{
    return res.json();
    })
    .then((res)=>{
      setFunctionality(res);
    })
  },[])
  

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/filters/TAGS`)
    .then(res=>{
      return res.json();
    })
    .then(res=>{
      setTagFilter(res.filter.metadata);
    })
  },[])


  useEffect(() => {
    if (Object.keys(quantErrors).length === 0 && isSubmit) {

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','authorization':`Bearer ${JSON.parse(localStorage.getItem("MF_user")).accessToken}`},
          body: JSON.stringify(quantDetails)
        };

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/products`, requestOptions)
        .then((res) => {
          if (!res.ok) {
            // get error message from body or default to response status
            let error = 'Quant is not updated';
            if(res.status==401){
              error="Unauthorized";
            }
            return Promise.reject(error);
          }
          return res.json();
        })
        .then((res) => {
          swal({
            title: 'Quant registered successfully',
            icon: 'success',
            timer:3000,
          })
          setQuantDetails({name:"",imgUrl:"",status:"active",risk:"",tags:[],description:"",frequency:"weekly",createdBy:"MF_Admin",price:"5000",categories:[{categoryId:1}],subCategories:[],stocks:[],functionality:[]});
          setIsSubmit(false);
        })
        .catch((err) => {
          if(typeof(err)=="string"){
            if(err.includes("Unauthorized")){
              swal({
                title: 'Quant not updated',
                text: 'User is unauthorized or idle for long time please login again',
                icon: 'warning'
              });
              setIsSubmit(false);
            }
            else if (err.includes('Quant is not updated')) {
              swal({
                title: 'Quant not updated',
                text: 'Something wrong with quants',
                icon: 'warning'
              });
              setIsSubmit(false);
            }
          }else {
            swal({
              title: 'Quant not registered',
              text: 'Sorry for the inconvenience, try again in some time',
              icon: 'warning'
            });
            setIsSubmit(false);
          }
        });
  }  
  }, [quantErrors]);

  return (<>
    {user?(
      <div>
      <Sidebar>
        <div className={classes.createStrategy}>
          <h4>Create Strategy</h4>
          <div className={classes.quantDetails}>
            <h5>Strategy Info</h5>
            <div className={classes.quantDetails_desc}>
              <div>
            <CssTextField
              label="Strategy Name"
              id="custom-css-outlined-input"
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleChange}
              name="name"
              value={quantDetails.name}
            />
            <p className={classes.errors}>{quantErrors.name}</p>
            </div>
            <div>
            <CssTextField
              label="img_url"
              id="custom-css-outlined-input"
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleChange}
              name="imgUrl"
              value={quantDetails.imgUrl}
            />
            <p className={classes.errors}>{quantErrors.imgUrl}</p>
            </div>
            <div>
            <FormControl>
            <InputLabel id="demo-simple-select-label" style={{color:"#BFC0C0"}}>Risk</InputLabel>
            <CssSelect
              label="Risk"
              id="custom-css-outlined-input"
              IconComponent = {KeyboardArrowDownIcon}
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleChange}
              name="risk"
              value={quantDetails.risk}
            >
              <MenuItem value={"Low"}>Low</MenuItem>
              <MenuItem value={"Medium"}>Medium</MenuItem>
              <MenuItem value={"High"}>High</MenuItem>
            </CssSelect>
            </FormControl>
            <p className={classes.errors}>{quantErrors.risk}</p>
            </div>
            </div>
            <div className={classes.tags}>
            <FormControl>
            <InputLabel id="demo-simple-select-label" style={{color:"#BFC0C0"}}>Tags</InputLabel>
            <CssSelect
              label={"Tags"}
              id="custom-css-outlined-input"
              IconComponent = {KeyboardArrowDownIcon}
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              multiple={true}
              // multiple
              className={classes.first_tf}
              onChange={handleChange}
              name="tags"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip sx={{color:"#BFC0C0",backgroundColor:"#191919",borderRadius:"3px"}} className={classes.chips} key={value} label={value} onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    deleteIcon={<CustomClose />}
                    onDelete={(event)=>{handleDelete(value)}} 
                    />
                  ))}
                </Box>
              )}
              value={quantDetails.tags}    
            >
              {tagFilter?tagFilter.map(ele=>(
              <MenuItem value={ele.key}>{ele.key}</MenuItem>
              )):""}
            </CssSelect>
            </FormControl>
            </div>
            <p className={classes.errors}>{quantErrors.tags}</p>
            <div className={classes.description}>
            <CssTextField
              label="Description"
              id="custom-css-outlined-input"
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleChange}
              name="description"
              rows={6}
              maxRows={10}
              value={quantDetails.description}
            />
            <p className={classes.errors}>{quantErrors.description}</p>
            </div>
          </div>
          <div>
            <div className={classes.StrategyDetails}>
            <h5>Strategy details</h5>
            <div className={classes.StrategyDetails_desc}> 
                <div>
                <FormControl>
                <InputLabel id="demo-simple-select-label" style={{color:"#BFC0C0"}}>Category Name</InputLabel>
                <CssSelect
                  label="Category Name"
                  id="custom-css-outlined-input"
                  IconComponent = {KeyboardArrowDownIcon}
                  InputLabelProps={{
                    style: { color: '#BFC0C0' }
                  }}
                  multiline={true}
                  // multiple={true}
                  className={classes.first_tf}
                  onChange={handleCategory}
                  name="categoryName"
                  value={quantDetails.categories[0].categoryId}
                >
                  {categories.map((ele,ind)=>(
                    <MenuItem value={ele._id}>{ele.name}</MenuItem>
                )
              )}
                </CssSelect>
                </FormControl>
                </div>
                <div>
                <FormControl>
                <InputLabel id="demo-simple-select-label" style={{color:"#BFC0C0"}}>Sub Category</InputLabel>
                <CssSelect
                  label="Sub Category"
                  id="custom-css-outlined-input"
                  IconComponent = {KeyboardArrowDownIcon}
                  InputLabelProps={{
                    style: { color: '#BFC0C0' }
                  }}
                  multiline={true}
                  multiple={true}
                  className={classes.first_tf}
                  onChange={handleSubCategory}
                  name="categoryName"
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip sx={{color:"#BFC0C0",backgroundColor:"#191919",borderRadius:"3px"}} className={classes.chips} key={value.subCategoryId} label={value.subCategoryName} onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        deleteIcon={<CustomClose />}
                        onDelete={(event)=>{handleSubCategoryDelete(value.subCategoryId)}} 
                        />
                      ))}
                    </Box>
                  )}
                  value={quantDetails.subCategories}
                >{subCategories.map((ele,ind)=>(
                  <MenuItem value={[ele._id,ele.name]}>{ele.name}</MenuItem>
              )
            )}
                </CssSelect>
                </FormControl>
                <p className={classes.errors}>{quantErrors.subCategories}</p>
                </div>
                <div>
                <FormControl>
                <InputLabel id="demo-simple-select-label" style={{color:"#BFC0C0"}}>Funtionality</InputLabel>
                <CssSelect
                  label="Funtionality"
                  id="custom-css-outlined-input"
                  IconComponent = {KeyboardArrowDownIcon}
                  InputLabelProps={{
                    style: { color: '#BFC0C0' }
                  }}
                  multiline={true}
                  multiple={true}
                  className={classes.first_tf}
                  onChange={handlefunctionality}
                  name="functionality"
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip sx={{color:"#BFC0C0",backgroundColor:"#191919",borderRadius:"3px"}} className={classes.chips} key={value.functionalityId} label={value.functionalityName} onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        deleteIcon={<CustomClose />}
                        onDelete={(event)=>{handleFunctionalityDelete(value.functionalityId)}} 
                        />
                      ))}
                    </Box>
                  )}
                  value={quantDetails.functionality}
                >
                  {functionality.map((ele,ind)=>(
                  <MenuItem value={[ele._id,ele.name]}>{ele.name}</MenuItem>
              )
            )}
                </CssSelect>
                </FormControl>                
                <p className={classes.errors}>{quantErrors.functionality}</p>
                </div>
                <div>
                <FormControl>
                <InputLabel id="demo-simple-select-label" style={{color:"#BFC0C0"}}>Strategy Name</InputLabel>
                <CssSelect
                  label="Strategy Name"
                  id="custom-css-outlined-input"
                  IconComponent = {KeyboardArrowDownIcon}
                  InputLabelProps={{
                    style: { color: '#BFC0C0' }
                  }}
                  multiline={true}
                  // multiple={true}
                  className={classes.first_tf}
                  onChange={handleChange}
                  name="strategy"
                >
                  {strategies.map((ele,ind)=>(
                    <MenuItem value={ele.strategy}>{ele.strategy}</MenuItem>
                )
              )}
                </CssSelect>
                </FormControl>
                </div>
            </div>
            <div>
            <div className={classes.inputContainer} onChange={(e)=>{setQuantDetails({ ...quantDetails, futures: e.target.value==="Yes" });}}>
                  <p>Future Option</p>
                  <div>
                  <div>
                    <input type="radio" name="future" id="future" value="Yes"/>
                    <label htmlFor="future">Yes</label>
                  </div>
                  <div>
                    <input type="radio" name="future" id="future_no" value="No"/>
                    <label htmlFor="future_no">No</label>
                  </div>
                  </div>
                  </div>
            </div>
            <div >
              <h6 style={{marginTop:"1.5rem",fontSize:"1.1rem"}}>Python Code</h6>
              <div className={classes.pythonCode}>
                <div>
                <HiddenLabelTextField
              label="Add Code"
              id="custom-css-outlined-input"
              InputLabelProps={{
                style: { color: '#BFC0C0',fontSize:"1rem"}
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleChange}
              name="PythonCode"
              rows={3}
              maxRows={7}
              value={quantDetails.code}
            />
              </div>
              <div>
                <div>
                  <button className={classes.edit}>Edit</button>
                </div>
                <div>
                  <button className={classes.copy}>Copy</button>
                </div>
              </div>
              </div>
            </div>
            </div>
           </div>
          <h4 className={classes.heading}>Advanced Settings</h4>
          <div className={classes.advancedSettings}>
              <div>
                <div>
                  <p>Reactivate on exit after</p>
                  <HiddenLabelSelect
              label=" "
              id="custom-css-outlined-input"
              IconComponent = {KeyboardArrowDownIcon}
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleReactivate}
              name="reactivate"
              value={reactivate}
              fullWidth="true"
            >
              <MenuItem value={"never"}>Never</MenuItem>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
              <MenuItem value={"quaterly"}>Quaterly</MenuItem>
            </HiddenLabelSelect>
                </div>
                <div>
                  <p>Capital Required</p>
                  <InputLabel shrink style={{color:"#BFC0C0",fontSize:"1rem"}}>User needed capital to deploy</InputLabel>
                  <HiddenLabelTextField
                      className={classes.field}
                      label=" "
                      variant='outlined'
                      fullWidth="true"
                      defaultValue="0"
                      name="price"
                      value={quantDetails.price}
                      onChange={handleChange}
                      style={{color:"#5A5A5A !important"}}
                      />
                    <p className={classes.errors}>{quantErrors.price}</p>
                </div>
              </div>
              <div>
              <div>
                  <p>Check conditions every</p>
                  <HiddenLabelSelect
              label=" "
              id="custom-css-outlined-input"
              IconComponent = {KeyboardArrowDownIcon}
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleCondition}
              name="condition"
              value={checkCondition}
              fullWidth="true"
            >
              <MenuItem value={1}>1 minute</MenuItem>
              <MenuItem value={5}>5 minute</MenuItem>
              <MenuItem value={10}>10 minute</MenuItem>
            </HiddenLabelSelect>
                </div>
                <div>
                  <p>Take trades</p>
                  <div className={classes.trades}>
                    <div>
                    <div>
                      <InputLabel shrink style={{color:"#BFC0C0",fontSize:"1rem"}}>Start Condition check after exchange open</InputLabel>
                  <HiddenLabelTextField
                      className={classes.field}
                      type="number"
                      label=" "
                      InputProps={{ inputProps: { min: 0} }}
                      variant='outlined'
                      fullWidth="true"
                      defaultValue="0"
                      style={{color:"#5A5A5A !important"}}
                      onKeyUp={(e)=>{if(e.target.value<0) {e.target.value=0;}}}
                      onBlur={(e)=>{if(e.target.value==0){e.target.value=0;}}}
                      />
                    </div>
                    <div>
                      <InputLabel shrink style={{color:"#BFC0C0",fontSize:"1rem"}}>Last Condition check before exchange close</InputLabel>
                  <HiddenLabelTextField
                      className={classes.field}
                      type="number"
                      label=" "
                      InputProps={{ inputProps: { min: 0} }}
                      variant='outlined'
                      fullWidth="true"
                      defaultValue="0"
                      style={{color:"#5A5A5A !important"}}
                      onKeyUp={(e)=>{if(e.target.value<0) {e.target.value=0;}}}
                      onBlur={(e)=>{if(e.target.value==0){e.target.value=0;}}}
                      />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <h4 className={classes.heading}>MarketPlace Settings</h4>
          <div className={classes.marketSettings}>
              <div>
                <div>
                  <div className={classes.inputContainer}>
                  <p>Public Strategy</p>
                  <div>
                  <div>
                    <input type="radio" name="publicStrategy" id="publicStrategy" value="Yes"/>
                    <label htmlFor="publicStrategy">Yes</label>
                  </div>
                  <div>
                    <input type="radio" name="publicStrategy" id="publicStrategy_no" value="No"/>
                    <label htmlFor="publicStrategy_no">No</label>
                  </div>
                  </div>
                  </div>
                  <div className={classes.inputContainer}>
                  <div className={classes.disabled}>
                  <div >
                    <div>
                    <p>Monthly Fixed Fee</p>
                      <DisabledTextField
                      className={classes.field}
                      label=" "
                      variant='outlined'
                      disabled="true"
                      style={{color:"#5A5A5A !important"}}
                      value="0"/>
                    </div>
                  </div>
                  <div>
                    <div>
                    <p>Variable Fee (%) </p>
                    <DisabledTextField
                      className={classes.field}
                      label=" "
                      variant='outlined'
                      disabled="true"
                      InputLabelProps={{
                        style: { color: '#BFC0C0' }
                      }}
                      value="0"/>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className={classes.inputContainer}>
                  <p>Display Positions to Non Subscribers</p>
                  <div>
                    <div>
                      <input type="radio" name="position" id="position" value="Yes"/>
                      <label htmlFor="position">Yes</label>
                    </div>
                    <div>
                      <input type="radio" name="position" id="position_no" value="No"/>
                      <label htmlFor="position_no">No</label>
                    </div>
                  </div>
                  </div>
                  <div className={classes.inputContainer}>
                  <p>Allow others to duplicate strategy</p>
                  <div>
                  <div>
                    <input type="radio" name="duplicate" id="duplicate" value="Yes"/>
                    <label htmlFor="duplicate">Yes</label>
                  </div>
                  <div>
                    <input type="radio" name="duplicate" id="duplicate_no" value="No"/>
                    <label htmlFor="duplicate_no">No</label>
                  </div>
                  </div>
                  </div>
                  <div className={classes.inputContainer}>
                  <p>Confirm each subscriber manually</p>
                  <div>
                  <div>
                    <input type="radio" name="confirm" id="confirm" value="Yes"/>
                    <label htmlFor="confirm">Yes</label>
                  </div>
                  <div>
                    <input type="radio" name="confirm" id="confirm_no" value="No"/>
                    <label htmlFor="confirm_no">No</label>
                  </div>
                  </div>
                  </div>
                  </div>
                </div>
              <div>
                <div className={classes.inputContainer}>
                  <p>Subscribers with Private Link Only</p>
                  <div>
                  <div>
                    <input type="radio" name="private" id="private" value="Yes"/>
                    <label htmlFor="private">Yes</label>
                  </div>
                  <div>
                    <input type="radio" name="private" id="private_no" value="No"/>
                    <label htmlFor="private_no">No</label>
                  </div>
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.disabled}>
                  <div >
                    <div>
                    <p>Minimum</p>
                      <HiddenLabelTextField
                      className={classes.field}
                      type="number"
                      label=" "
                      InputProps={{ inputProps: { min: 0} }}
                      variant='outlined'
                      fullWidth="true"
                      defaultValue="0"
                      style={{color:"#5A5A5A !important"}}
                      onKeyUp={(e)=>{if(e.target.value<0) {e.target.value=0;}}}
                      onBlur={(e)=>{if(e.target.value==0){e.target.value=0;}}}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                    <p>Maximum Deploy</p>
                    <HiddenLabelTextField
                      className={classes.field}
                      type="number"
                      label=" "
                      InputProps={{ inputProps: { min: 0} }}
                      variant='outlined'
                      fullWidth="true"
                      InputLabelProps={{
                        shrink:true,
                        style: { color: '#BFC0C0' }
                      }}
                      defaultValue="0"
                      onKeyUp={(e)=>{if(e.target.value<0) {e.target.value=0;}}}
                      onBlur={(e)=>{if(e.target.value==0){e.target.value=0;}}}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                <div className={classes.inputContainer}>
                  <p>Invoice Frequency for Variable Fee</p>
                  <HiddenLabelSelect
              label=" "
              id="custom-css-outlined-input"
              IconComponent = {KeyboardArrowDownIcon}
              InputLabelProps={{
                style: { color: '#BFC0C0' }
              }}
              multiline={true}
              className={classes.first_tf}
              onChange={handleChange}
              name="frequency"
              value={quantDetails.frequency}
              fullWidth="true"
            >
              <MenuItem value={"monthly"}>Monthly</MenuItem>
              <MenuItem value={"weekly"}>Weekly</MenuItem>
              <MenuItem value={"quaterly"}>Quaterly</MenuItem>
            </HiddenLabelSelect>
                </div>
                <div className={classes.inputContainer}>
                  <p>Recreate old positions for new subscriber</p>
                  <div>
                  <div>
                    <input type="radio" name="new_subscriber" id="new_subscriber" value="Yes"/>
                    <label htmlFor="new_subscriber">Yes</label>
                  </div>
                  <div>
                    <input type="radio" name="new_subscriber" id="new_subscriber_no" value="No"/>
                    <label htmlFor="new_subscriber_no">No</label>
                  </div>
                  </div>
                  </div>
                <div className={classes.inputContainer}>
                  <p>Hide Subscribers from others</p>
                  <div>
                  <div>
                    <input type="radio" name="hide_subscriber" id="hide_subscriber" value="Yes"/>
                    <label htmlFor="hide_subscriber">Yes</label>
                  </div>
                  <div>
                    <input type="radio" name="hide_subscriber" id="hide_subscriber_no" value="No"/>
                    <label htmlFor="hide_subscriber_no">No</label>
                  </div>
                  </div>
                  </div>
              </div>
          </div>
        <button style={{padding:"0.5rem 1rem"}} onClick={handleShow}>List Builder</button>
          <div className={classes.createContainer}>
          <button className={classes.create} onClick={handleSubmit}>Create</button>
          </div>
        <ListBuilder show={show} handleClose={handleClose} handleShow={handleShow} curstock={quantDetails.stocks} handleStocks={handleStocks}/>
        </div>
      </Sidebar>
    </div>
    
    ):""}
    </>
  )
}

export default CreateStrategy