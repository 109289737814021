import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './Navbar.module.css';
import Logo from '../../../assets/Logo.svg';
import Notification from '../../../assets/fi-rr-bell.svg';
import Search from '../../../assets/search.svg';
import User from '../../../assets/user.svg';
import Power from '../../../assets/power.svg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';

function Navbar() {
  const history = useHistory();
  const [user, setUser] = useState('');
  useEffect(() => {
    if (localStorage.getItem('MF_user')) {
      setUser(JSON.parse(localStorage.getItem('MF_user')));
    }
  }, []);

  const handleSidebar = () => {
    document.querySelector('.sidebar_nav').classList.toggle('hamburger');
  };

  const handleLogout = () => {
    localStorage.removeItem('MF_user');
    swal({
      title: 'Successfully logged out from your account!',
      text: 'you will be redirected to Sign in page in 3 seconds',
      icon: 'success',
      timer: 2000
    }).then(() => {
      setTimeout(() => {
        setUser('');
        history.push('/signin');
      }, 750);
    });
  };

  return (
    <div className={classes.navbar}>
      <div className={classes.logo}>
        <img src={Logo} alt="Logo" />
      </div>
      {user ? (
        <>
          <div className={classes.searchbar}>
            <input type="text" alt="search" placeholder="Search" />
            <img src={Search} alt="search" />
          </div>
          <div>
            <Link to="/order" className={classes.link}>
              <img
                src={Notification}
                alt="notification"
                className={classes.notification}
              />
            </Link>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className={classes.profile}>
                <img src={user.avatar} alt="" />
                <p>{user.name}</p>
              </Dropdown.Toggle>

              <Dropdown.Menu className={classes.menu}>
                <Dropdown.Item>
                  <Link to="/profile" className={classes.link}>
                    <img src={User} alt="profile" />
                    Change Password
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item onClick={handleLogout}>
                  <img src={Power} alt="power" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      ) : (
        <>
          <div className={classes.signup}>
            <Link to="/signup">
              <button>Sign Up</button>
            </Link>
          </div>
          <div className={classes.signin}>
            <Link to="/signin">
              <button>Sign In</button>
            </Link>
          </div>
        </>
      )}
      <div className={classes.hamburger}>
        <GiHamburgerMenu size={25} onClick={handleSidebar} />
      </div>
    </div>
  );
}

export default Navbar;
