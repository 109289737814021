import React, { useEffect, useRef, useState } from 'react';
import classes from './home.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import Ellips from '../../assets/Ellipse62.png';
import Profile from '../../assets/home/clientProfile.svg';
import Button from '../Ui/Button';
import ScrollAnimation from 'react-animate-on-scroll';
import { HomeStyle } from './home.style';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Rating } from '@mui/material';
function Testimonial() {
  const [swiperModules, setSwiperModules] = useState([Autoplay, Navigation]);
  const [windowSize, setWindowSize] = useState(0);
  useEffect(() => {
    /* if(window.innerWidth <= 768) {
      
      setSwiperModules([Navigation]);
    }else{
      setSwiperModules([Autoplay, Navigation]);
    } */
    setWindowSize(window.innerWidth);
  }, [windowSize]);
  window.addEventListener('resize', () => {
    /* if(window.innerWidth <= 768) {
      
      setSwiperModules([Navigation]);
    }else{
      setSwiperModules([Autoplay, Navigation]);
    } */
    setWindowSize(window.innerWidth);
  });
  const swiperRef = useRef(null)
  const swiperRef2 = useRef(null)
  return (
    <div className={classes.testimonial_container}>
      <Container>
        <Row>
          <Col xs={12} lg={6} md={12} sm={12}>
            <ScrollAnimation duration={2} animateIn="fadeIn">
              <div style={{ marginTop: 120 }}>
                <h2>
                  WE LOVE OUR
                  <br />
                  CUSTOMERS{' '}
                  <span
                    className={classes.subHeadingTestimonial}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {' '}
                    and they{' '}
                    <span className={classes.bgTitleLine}>love us.</span>
                  </span>
                </h2>
              </div>
            </ScrollAnimation>
            <p className={classes.subTitle}>We work only with the most trusted financial brands.</p>
            {/* <div className={classes.imgWrapper}>
              <img src={Ellips} alt="elips" className={classes.btnImg} />
              <button className={`button ${classes.homeBtn}`}>
                Join With Us
              </button>
            </div> */}
            <a
              href="/signup"
              style={{
                textDecoration: 'none'
              }}
            >
              <Button wrapperClass={classes.btnImg} btnClass={classes.homeBtn}>
                {' '}
                Join With Us
              </Button>
            </a>
          </Col>
          <Col xs={12} lg={6} md={12} sm={12}>
            <Row>
              <Col xs={12} lg={6} md={6} sm={12}>
                <div className={classes.testimonialSlider}
                  onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
                  onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
                >
                  <Swiper
                  ref={swiperRef}
                    direction={windowSize > 768 ? "vertical" : "horizontal"}
                    // spaceBetween={30}
                    style={{
                      height: '100%',
                      width: '100%',
                      minHeight: 0
                    }}
                    // loop={windowSize > 768 ? true : false}
                    loop={true}
                    slidesPerView={windowSize > 768 ? 3 : 1}
                    autoplay={{
                      // delay: windowSize > 768 ? 1200 : 100000,
                      delay: 1200,
                      disableOnInteraction: true,
                      autoplayDisableOnInteraction: true,
                      // pauseOnMouseEnter: true,
                      autoplayResume: true
                    }}
                    allowTouchMove={false}
                    navigation={true}
                    modules={swiperModules}
                    //  onSwiper={(sw) => setSwiper(sw)}
                  >
                    <SwiperSlide>
                      <div className={`${classes.testimonialCard} `} >
                        <p>
                          Found MoneyFactory super intuitive and easy to use.
                          The Simulated Mode helped me build confidence as an
                          investor as I can learn about the market before
                          deploying funds and assess the performance of the
                          quants as well.
                        </p>
                        <Rating
                          name="half-rating-read"
                          defaultValue={5}
                          readOnly
                        />
                        <hr />
                        <Row>
                          {/* <Col lg={3} md={3} xs={6} sm={6}>
                            <img
                              src={Profile}
                              alt="profile pic"
                              className={classes.profileImg}
                            />
                          </Col> */}
                          {/* lg={9} md={9} xs={6} sm={6} */}
                          <Col>
                            <h5>Ammar Ajman </h5>
                            {/* <h6>CEO, Company Name</h6> */}
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={classes.testimonialCard}>
                        <p>
                          Absolutely love how easy it is to use and how
                          convenient the Simulated Mode is and how it helps one
                          make smarter investment decisions. Cannot wait for the
                          app to launch.
                        </p>
                        <Rating
                          name="half-rating-read"
                          defaultValue={5}
                          readOnly
                        />
                        <hr />
                        <Row>
                          {/* <Col lg={3} md={3} xs={6} sm={6}>
                            <img
                              src={Profile}
                              alt="profile pic"
                              className={classes.profileImg}
                            />
                          </Col> */}
                          {/* lg={9} md={9} xs={6} sm={6} */}
                          <Col>
                            <h5>Fatima Kausar</h5>
                            <h6>Entrepreneur</h6>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={classes.testimonialCard}>
                        <p>
                          We need a tool like MoneyFactory in today’s day and
                          age. There is so much info on investing out there and
                          it gets super confusing so something that helps me
                          understand when to invest and when to sell is a boon.
                          Still waiting for the app. That would make things
                          super easy.
                        </p>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          readOnly
                          precision={0.5}
                          sx={HomeStyle.rating}
                        />
                        <hr />
                        <Row>
                          {/* <Col lg={3} md={3} xs={6} sm={6}>
                            <img
                              src={Profile}
                              alt="profile pic"
                              className={classes.profileImg}
                            />
                          </Col> */}
                          {/* lg={9} md={9} xs={6} sm={6} */}
                          <Col >
                            <h5>Siddharth Sharma</h5>
                            <h6>HR Manager</h6>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide  >
                      <div  className={classes.testimonialCard}>
                        <p>
                          As a young investor I want to learn about the ropes of
                          the industry and learn them fast and MoneyFactory
                          comes at the right time. The Assisted mode is a great
                          one to work with. I get notified at the right time and
                          the decision making rests with me.
                        </p>
                        <Rating
                          name="half-rating-read"
                          defaultValue={4}
                          readOnly
                          sx={HomeStyle.rating}
                        />
                        <hr />
                        <Row>
                          {/* <Col lg={3} md={3} xs={6} sm={6}>
                            <img
                              src={Profile}
                              alt="profile pic"
                              className={classes.profileImg}
                            />
                          </Col> */}
                          {/* lg={9} md={9} xs={6} sm={6} */}
                          <Col>
                            <h5>Krupa Jain</h5>
                            <h6>Freelance writer</h6>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </Col>

              <Col xs={12} lg={6} md={6} sm={12}>
                <div className={classes.testimonialSlider}
                  onMouseEnter={() => swiperRef2.current.swiper.autoplay.stop()}
                  onMouseLeave={() => swiperRef2.current.swiper.autoplay.start()}
                >
                  <Swiper
                   ref={swiperRef2}
                    direction={windowSize > 768 ? "vertical" : "horizontal"}
                    // spaceBetween={30}
                    style={{
                      height: '100%',
                      width: '100%',
                      minHeight: 0
                    }}
                    // loop={windowSize > 768 ? true : false}
                    loop={true}
                    slidesPerView={windowSize > 768 ? 2 : 1}
                    autoplay={{
                      delay: 1200,
                      disableOnInteraction: true,
                      // pauseOnMouseEnter: true,
                      autoplayDisableOnInteraction: true,
                      autoplayResume: true,
                      reverseDirection: true
                    }}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    //  onSwiper={(sw) => setSwiper(sw)}
                  >
                    <SwiperSlide>
                      <div className={classes.testimonialCard}>
                        <p>
                          I have an experience of over 5 years of in investment
                          platforms. But, never come across such an intelligent
                          and smart investment platform. My first preference is
                          always MoneyFactory.
                        </p>
                        <Rating
                          name="half-rating-read"
                          defaultValue={4}
                          readOnly
                          sx={HomeStyle.rating}
                        />
                        <hr />
                        <Row>
                          {/* <Col lg={3} md={3} xs={6} sm={6}>
                            <img
                              src={Profile}
                              alt="profile pic"
                              className={classes.profileImg}
                            />
                          </Col> */}
                          {/* lg={9} md={9} xs={6} sm={6} */}
                          <Col>
                            <h5>Uday Kumarh</h5>
                            <h6>Client Service Manager Accenture</h6>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={classes.testimonialCard}>
                        <p>
                          I had a bad experience with a few investment platforms
                          and vowed never to invest again! But on the
                          recommendation pf a friend, I invested a small amount
                          with MoneyFactory and loved the platform. It’s super
                          transparent and a great tool for understanding how to
                          invest in the first place workout necessarily losing
                          money.
                        </p>
                        <Rating
                          name="half-rating-read"
                          defaultValue={3.5}
                          readOnly
                          precision={0.5}
                          sx={HomeStyle.rating}
                        />
                        <hr />
                        <Row>
                          {/* <Col lg={3} md={3} xs={6} sm={6}>
                            <img
                              src={Profile}
                              alt="profile pic"
                              className={classes.profileImg}
                            />
                          </Col> */}
                          {/* lg={9} md={9} xs={6} sm={6} */}
                          <Col>
                            <h5>Arun Kumar</h5>
                            <h6>BDM at Artoindia</h6>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={classes.testimonialCard}>
                        <p>
                          I believe there is always a place for development,
                          MoneyFactory is a far more important tool than real
                          estate investment, it gives us much higher returns
                          than the real estate market, I would always choose
                          money for my long-term or short-term investments, as
                          it outperforms a human being and simplifies the data I
                          would personally have to put in the time and effort to
                          know it, I wouldn't have to worry about my money
                          anymore because I have got MoneyFactory by my side 👍
                        </p>
                        <Rating
                          name="half-rating-read"
                          defaultValue={4.5}
                          readOnly
                          precision={0.5}
                          sx={HomeStyle.rating}
                        />
                        <hr />
                        <Row>
                          {/* <Col lg={3} md={3} xs={6} sm={6}>
                            <img
                              src={Profile}
                              alt="profile pic"
                              className={classes.profileImg}
                            />
                          </Col> */}
                          {/* lg={9} md={9} xs={6} sm={6} */}
                          <Col>
                            <h5>Mohammed Hashim</h5>
                            <h6>Entrepreneur</h6>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Testimonial;
