import React, { useState, useEffect } from 'react';
import classes from './PlanCard.module.css';
import exchange1 from '../../../../assets/exchange.svg';
import useRazorpay from 'react-razorpay';

import { Form, Button } from 'react-bootstrap';

const PlanCard = ({ id, type, title, price, exchange, color }) => {
  const [user, setUser] = useState('');
  const ReactRazorpay = useRazorpay();


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const vals = {
        id: id,
        title: title,
        price:price*1.18
    }
    const requestSubOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(vals)
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/subscriptions/create`,
      requestSubOptions
    )
      .then((res) => {
        // if (!res.ok) {
        //   // get error message from body or default to response status
        //   const error = 'Payment is not updated';
        //   return Promise.reject(error);
        // }
        if (res.ok) return res.json();
      })
      .then((res) => {
        // swal({
        //   title: 'Payment is success',
        //   icon: 'success',
        //   timer: 300
        // });

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, //'rzp_test_knKMlfEDu1nMll',
          subscription_id: res.razorpay_subscription_id,
          currency: 'INR',
          name: 'MoneyFactory AI',
          description: 'MoneyFactory Subscription for: Rs' + price  + ' - '+title,
          image: '/static/images/moneyfactoryicon.png',
          handler: (respon) => {
            //Save the transaction details

            const trans = {
              label: title + ' - Subscription for: Rs' + price * 100,
              amount: price * 118,
              external_payment_id: respon.razorpay_payment_id,
              external_subscription_id:res.razorpay_subscription_id,
              title:title,
              type:type,
              email: user.email,
              currency: 'INR',
              status: 'SUCCESS',
              payment_mode: respon.razorpay_payment_mode,
              trans_details: options
            };
            // Add code to save the transaction data

            const requestOptions = {
              method: 'POST',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(trans)
            };

            fetch(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/payment/create`,
              requestOptions
            )
              .then((res) => {
                // if (!res.ok) {
                //   // get error message from body or default to response status
                //   const error = 'Payment is not updated';
                //   return Promise.reject(error);
                // }
                if (res.ok) return res.json();
              })
              .then((res) => {
                // swal({
                //   title: 'Payment is success',
                //   icon: 'success',
                //   timer: 300
                // });
              })
              .catch((err) => {
                if (typeof err == 'string') {
                  if (err.includes('Payment is not updated')) {
                    // swal({
                    //   title: 'Payment not updated',
                    //   text: 'Something wrong with Payment',
                    //   icon: 'warning'
                    // });
                  }
                } else {
                  // swal({
                  //   title: 'Payment not saved',
                  //   text: 'Sorry for the inconvenience, try again in some time',
                  //   icon: 'warning'
                  // });
                }
              });
          },
          prefill: {
            name: user.name,
            email: user.email,
            contact: user.phone_number
          },
          notes: {
            address: 'MoneyFactory AI Corporate Office'
          },
          theme: {
            color: '#23636A'
          }
        };

        const rzpay = new ReactRazorpay(options);

        rzpay.on('payment.failed', function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });

        rzpay.open();



      })
      .catch((err) => {
        console.log(err);
      });

  };

  useEffect(() => {
    if (localStorage.getItem('MF_user')) {
      setUser(JSON.parse(localStorage.getItem('MF_user')));
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <div className={classes.pricing_card}>
          <div className={classes.top_secn}>
            <div style={{ background: color }} className={classes.pricing_btn}>
              {title}
            </div>
            <div className={classes.price}>
              <div className={classes.amount}>₹{price}</div>
              <div className={classes.month}> / {type==='Annual'?'Annum':type.substr(0,type.length-2)}</div>
              <div className={classes.desc}>
              &nbsp;&nbsp;+ 18% GST
          </div>
            </div>
          </div>
          <div className={classes.desc}>
            Make your plans easy by subscribing for free. get it now
          </div>
          <div className={classes.section}>
            <div className={classes.sub_section}>
              <div className={classes.icon}>
                <img src={exchange1} alt="" />
              </div>
              <div className={classes.recommend}>
                <div className={classes.title}>Exchanges</div>
                <div className={classes.value}>{exchange}</div>
              </div>
            </div>
            <Button type="submit" className={`${classes.subscribe}`}>
              {/* <div className={classes.subscribe}> */}
              Subscribe Now
              {/* </div> */}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PlanCard;
