import React from 'react';
import classes from './DifferentWays.module.css';
import CardItem from '../CardItem/CardItem';
import Simulated from '../../assets/home/simulated.svg';
import Automatic from '../../assets/home/automatic.svg';
import Assisted from '../../assets/home/assisted.svg';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
const investingMode = [
  {
    img: Simulated,
    alt:'Simulated Investment Mode',
    title: 'Simulated',
    content:
      'Improve your understanding of the market and learn to trade better with the AI’s help. Then use the knowledge to make better investments.',
    best: 'Best for: Preparing you to enter the financial markets'
  },
  {
    img: Assisted,
    alt:'Assisted Investment Mode',
    title: 'Assisted',
    content:
      'Opt to get instant alerts about profitable trades and get investment opportunities at just the right time. Choose to make your move and enjoy the profits',
    best: 'Best for: Advancing your journey into the world of investing'
  },
  {
    img: Automatic,
    alt:'Automatic Investment Mode',
    title: 'Automatic',
    content:
      'Hand over control to our AI-powered platform and let your investments run on autopilot while you sit back and enjoy your profits.',
    best: 'Best for: Helping you grow your money exponentially'
  }
];

function DifferentWays() {
  return (
    <div className={classes.differentWays}>
      <Container>
        <Row>
          <Col md={12} xs={12} sm={12} lg={6}>
          <ScrollAnimation duration={2} animateIn='fadeIn'>
            <h2>SAVE MORE TIME &<br/><span className={classes.subHeading} > MAKE MORE <span className={classes.bgTitleLine}>MONEY</span></span></h2>
          </ScrollAnimation>
          </Col>
          
          <Col md={12} xs={12} sm={12} lg={6}>
            <p>Did you know that <span style={{color:'#00D37F',fontWeight: '700'}}>99%</span> <br/>investors have never made a<br/> profit?</p>
          </Col>
        </Row>
        <h3 className={classes.ways}>
          3 different investing modes on MoneyFactory can help you change that:
        </h3>
        <Row>
          {investingMode.map((x, k) => {
            return (
              <Col key={k + 1} md={4} lg={4} xs={12} sm={12}>
                <Card className={classes.investCard}>
                  <Card.Body>
                    <ScrollAnimation animateIn='flipInX'>
                    <img src={x.img} style={{
                      maxWidth: '100%',
                    }}  alt={x.alt} className={classes.investCardImgs}/>
                    </ScrollAnimation>
                    <Card.Text>
                      
                      <h4 className={classes.investTitle}>{x.title}</h4>
                      <div className={classes.investContent}>{x.content}</div>
                      <div className={classes.investBest}>{x.best}</div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default DifferentWays;
