import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import classes from './AngelBroker.module.css';
import swal from 'sweetalert';

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const PTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const AngelBrokerDetails = () => {
    const initialValues = {
        clientCode: '',
        password: ''
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (formErrors.trim.length === 0 && isSubmit) {
            var id = JSON.parse(localStorage.getItem('MF_user')).id;
            fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/angelonebroker/addbroker/angelone`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        loggedInUserId: id,
                        clientcode: formValues.clientCode,
                        password: formValues.password
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = 'Error in creating';
                        swal({
                            title: 'Error',
                            text: error,
                            icon: 'error',
                            timer: 3000
                        }).then(() => { });
                        return Promise.reject(error);
                    }
                    return response.json();
                })
                .then((json) => {
                    swal({
                        title: 'Broker added!',
                        text: 'broker has been added.',
                        icon: 'success',
                        timer: 3000
                    }).then(() => { });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [formErrors, formValues, isSubmit]);

    const contentStyle = {
        background: '#1d1d1d',
        maxWidth: '60vw',
        width: '100%'
    };
    const overlayStyle = {
        background: 'rgba(0,0,0,0.5)',

        overflowY: 'auto'
    };
    //   const arrowStyle = { color: '#fff' }; // style for an svg element

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validate(formValues);
        setIsSubmit(true);
    };

    const validate = (values) => {
        if (!values.clientCode || !values.password) {
            setFormErrors('Field cannot be empty');
        }
        // return error;
    };

    return (
        <div>
            {isSubmit ? (
                <div className={classes.btn}>Add</div>
            ) : (
                <Popup
                    trigger={<div className='d-flex'><div className={classes.btn}>Add</div><div className={`${classes.btn} ml-3`}>Edit</div></div>}
                    modal
                    {...{
                        contentStyle,
                        overlayStyle
                    }}
                >
                    {(close) => (
                        <div className={classes.modal}>
                            <div className={classes.header}>
                                <div className={classes.header_text}>Connect to Broker</div>
                                <a className={classes.close} onClick={close}>
                                    <span> &times; </span>
                                </a>
                            </div>
                            <div className={classes.content}>
                                <div className={classes.inputs}>
                                    <div>
                                        <CssTextField
                                            label="Client Code"
                                            id="custom-css-outlined-input"
                                            InputLabelProps={{
                                                style: { color: '#fff' }
                                            }}
                                            className={classes.first_tf}
                                            onChange={handleChange}
                                            name="clientCode"
                                            value={formValues.clientCode}
                                        />
                                        <p className={classes.errors}>{formErrors}</p>
                                    </div>
                                    <div>
                                        <CssTextField
                                            label="Password"
                                            type="password"
                                            id="custom-css-outlined-input"
                                            InputLabelProps={{
                                                style: { color: '#fff' }
                                            }}
                                            // multiline={true}
                                            className={classes.first_tf}
                                            onChange={handleChange}
                                            name="password"
                                            value={formValues.password}
                                        />
                                        <p className={classes.errors}>{formErrors}</p>
                                    </div>
                                </div>
                                <div className={classes.buttons}>
                                    <button className={classes.cancel} onClick={close}> Cancel </button>
                                    <button className={classes.save} onClick={handleSubmit}>
                                        {' '}
                                        Save{' '}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </div>
    );
};

export default AngelBrokerDetails;
