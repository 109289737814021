import React from 'react';
import classes from './blogCard.module.css';
import Rectangle118 from '../../assets/blogCardImgs/Rectangle118.png';
import { Link } from 'react-router-dom';
import moment from 'moment';

function Cards(props) {
  const blogs = props && props.blogs && props.blogs.length > 0 ? props.blogs.slice(1) : [];
  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  // column section
  return (
    <div className={classes.blogCard_container}>
      {blogs.length > 0 ?
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-xs-1 g-4">
           {blogs.slice(0, props.page * props.perPage).map((x, k) => {
            
            return (
              // -md-4 mt-4 padding-0
              <div className="col" key={x.id}>
                <div className={classes.mainCard}>
                  <div className={classes.imgWrapper}>
                  <img src={ x.attributes?.CoverImage?.data?.attributes?.url ?  props.BlogBaseURL+"/st"+(x.attributes?.CoverImage?.data?.attributes?.url) : Rectangle118} className={classes.image} alt="cardImg" />
                    <button type="button" className={`btn ${classes.btnCard}`}>
                      <p className={classes.subCardBtnText}>{
                        x.attributes?.categories?.data?.map((x, k) => {
                          return x.attributes?.Name;
                        })
                      }</p>
                    </button>
                  </div>
                  <div className={classes.textWrapper}>
                    <div className={classes.date}>{moment(x.attributes?.publishedAt).format('Do MMMM, YYYY')}</div>
                    <div className={classes.title} >{
                      extractContent(x.attributes?.Heading)
                    }</div>

                    <div className={classes.description}>{
                      extractContent(x.attributes?.Content).length > 133 ? extractContent(x.attributes?.Content).substring(0,135)+"..." : extractContent(x.attributes?.Content)
                    }</div>

                    <Link to={`/blog-details/${x.id}`}><div className={classes.readMore}>Read More</div></Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        : <div className={classes.noDataFound}>No blogs found</div>}
        {
          (blogs.length > props.page * props.perPage) && (
            <button type="button" onClick={props.loadMore} className={`${classes.carReadMoreBtn} btn btn-success`}>
              Load More
            </button>
          )
        }
        
      
    </div>
  );
}

export default Cards;
