import React from 'react';
import classes from './Stories.module.css';

const Stories = () => {
  return (
    <div className={classes.storiesCard}>
      <div className={classes.story}>
        <img src="https://images.unsplash.com/photo-1612010167102-d1e8f83833e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" />
        <div className={classes.st_caption}>Gyan</div>
      </div>
      <div className={classes.story}>
        <img src="https://images.unsplash.com/photo-1642543348745-03b1219733d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" />
        <div className={classes.st_caption}>Zeus</div>
      </div>
      <div className={classes.story}>
        <img src="https://images.unsplash.com/photo-1633431871820-ca72e0da2e2b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=469&q=80" />
        <div className={classes.st_caption}>Revenue</div>
      </div>
    </div>
  );
};

export default Stories;
