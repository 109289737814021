import React from 'react';
import { Container } from 'react-bootstrap';
import classes from './Testimonials.module.css';
import StartNow from './StartNow';
import Siat from './SIAT/Siat';

const Testimonials = () => {
  return (
    <>
    
      <div className={classes.TestimonialWrapper}>
        
        <div style={{marginTop:100}}>
        <StartNow/>
       
        </div>
        <Siat />
      </div>
    </>
  );
};

export default Testimonials;
