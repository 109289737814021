import React from 'react';
import classes from './AffilatePage.module.css';
import Navbar from './components/Navbar/Navbar';
import Invest from './components/Invest/Invest';
import Footer from '../../components/Footer/Footer';
import WhyMoneyFactory from './components/WhyMoneyFactory/WhyMoneyFactory';
import Benifits from './components/Benifits/Benifits';
import Offers from './components/Offers/Offers';
import Seo from '../../components/SEO';
import Testimonials from './components/Testimonials/Testimonials';

const AffilatePage = () => {
  return (
    <div className={classes.affilatePage}>
      <Seo
        title="Investment Experts | AI assisted Investment Solutions - MoneyFactory"
        canonical={window.location.pathname}
        description="Grow your money safely with our investment expertise & AI assisted solutions. Save your time with our simulated, assisted and automatic investing strategies."
      />
      <Navbar />
      <Invest />
      <WhyMoneyFactory />
      <Benifits />
      <Offers />
      <Testimonials />
      <Footer joinWithUs={true} />
    </div>
  );
};

export default AffilatePage;
