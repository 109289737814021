import React from 'react'
import Community from '../../components/Community/Community'
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar'

const CommunityPage = () => {
  return (
    <Sidebar><Community /></Sidebar>
  )
}

export default CommunityPage