// code very similar to signin page

import React, { useState, useEffect, useCallback, useRef } from 'react';
import SignImage from '../../assets/SignUp.png';
import { useHistory } from 'react-router-dom';
import classes from './Signup.module.css';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import userPool from '../../components/Auth/UserPool';
import Seo from '../../components/SEO';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const PTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

function SignIn() {
    const history = useHistory();
    const initialValues = {
        name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        id: 'signUp'
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [formStatus, setFormStatus] = useState('initiate');
    const [counter, setCounter] = React.useState(30);
    const [OTP, setOTP] = useState('');
    const [resend, setResend] = useState(false);
    const [resendOtp, setResendOtp] = useState(false);
    var userID = '';

    // USEREFS
    let OTPref = useRef(OTP);
    let formStatusref = useRef(formStatus);
    let resendOtpref = useRef(resendOtp);
    OTPref.current = OTP;
    formStatusref.current = formStatus;
    resendOtpref.current = resendOtp;

    // USEEFFECT
    useEffect(() => {
        if (counter === 0) {
            setResend(false);
        }
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const check = (otp, resolve) => {
        if (otp) {
            resolve();
        } else {
            setTimeout(() => {
                check(OTPref.current, resolve);
            }, 1000);
        }
    };

    // Promise which resolves when OTP is inserted
    const otpInserted = () => {
        return new Promise(function (resolve, myReject) {
            // "Producing Code" (May take some time)
            if (OTPref.current) {
                resolve();
            } else {
                setTimeout(() => {
                    check(OTPref.current, resolve);
                }, 1000);
            }
        });
    };

    // OTP validations
    const otpVerify = (e) => {
        e.preventDefault();
        let otpvalue = document.querySelector('#otp').value;
        if (otpvalue.length === 6) {
            otpvalue = parseInt(otpvalue);
            if (isNaN(otpvalue)) {
                setFormErrors({ otp: 'It should contain only numbers' });
            } else {
                setOTP(otpvalue);
                setFormErrors({});
            }
        } else {
            setFormErrors({ otp: 'Enter 6 digit OTP' });
        }
    };

    const signupFunc = () => {
        return new Promise((resolve, reject) => {
            try {
                userPool.signUp(
                    '+91' + formValues.phone,
                    'Password@123',
                    [{ Name: 'email', Value: formValues.email }],
                    null,
                    (err, data) => {
                        if (err) {
                            // console.error(err);
                            reject(err.message);
                        } else {
                            resolve();
                        }
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    };

    const sendOTP = () => {
        setResendOtp(true);
        initiateAuthFunc()
            .then(() => {
                setFormStatus('registered');
            })
            .catch(() => {
                // setOTP('');
                // sendOTP();
            });
    };

    const initiateAuthFunc = () => {
        return new Promise((resolve, reject) => {
            fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/sendOTP`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formValues)
            }).then((res) => {
                if (res.ok) { return res.json(); } else {
                    res.json().then((r) => { reject(r.msg); })
                }
            }).then((res) => {
                if (formStatusref.current !== 'otp') {
                    setCounter(60);
                }

                if (formStatusref.current === 'otp' && !resendOtpref.current) {
                    setFormErrors({ otp: 'Wrong OTP' });
                }

                setOTP('');
                setResend(true);
                setIsSubmit(false);
                setFormStatus('otp');

                otpInserted().then(() => {
                    setIsSubmit(true);
                    fetch(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/confirmOTP`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            ...formValues,
                            otp: OTPref.current.toString()
                        })
                    }).then((res) => {
                        if (res.ok) { return res.json(); } else {
                            res.json().then((r) => { reject(r.msg); })
                        }
                    }).then((res) => {
                        swal({
                            title: 'User registered successfully',
                            text: 'you will be redirected to Sign in page in 3 seconds',
                            icon: 'success',
                            timer: 3000
                        }).then(() => {
                            setTimeout(() => {
                                history.push('/signin');
                            }, 750);
                        });
                        resolve();
                    })
                    .catch((err) => {
                        if (typeof err == 'string') {
                            if (err.includes('User already exists')) {
                                swal({
                                    title: 'User not registered',
                                    text: 'email already exists',
                                    icon: 'warning'
                                }).then(() => {
                                    setTimeout(() => {
                                        setFormStatus('initiate');
                                        setFormErrors({});
                                        setFormValues(initialValues);
                                    }, 750);
                                });
                            }
                        } else {
                            swal({
                                title: 'User not registered',
                                text: 'Sorry for the inconvenience, try again in some time',
                                icon: 'warning'
                            }).then(() => {
                                setTimeout(() => {
                                    setFormStatus('initiate');
                                    setFormErrors({});
                                    setFormValues(initialValues);
                                }, 750);
                            });
                            reject();
                        }
                    });
                });
            });
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleOnClick = useCallback(() => history.push('/signin'), [history]);

    const getInfo = (e) => {
        setIsSubmit(true);
        if (!Object.keys(validate(formValues)).length) {
            setFormErrors(validate(formValues));
            initiateAuthFunc()
                .then(() => {
                    // setFormStatus('registered');
                })
                .catch((err) => {
                    if (err.includes('User does not exist')) {
                        signupFunc()
                            .then(() => {
                                initiateAuthFunc()
                                    .then((data) => {
                                        // setFormStatus('registered');
                                    })
                                    .catch((data) => {
                                        setFormErrors({ otp: 'OTP Has expired' });
                                        setTimeout(() => {
                                            setFormStatus('initiate');
                                            setFormValues(initialValues);
                                        }, 2000);
                                    });
                            })
                            .catch((data) => {
                                if (data.includes('Email already exists'))
                                    setFormErrors({ email: 'Email already exists' });
                            });
                    } else {
                        // sendOTP();
                        setFormErrors({ otp: 'OTP Has expired' });
                        setTimeout(() => {
                            setFormStatus('initiate');
                            setFormValues(initialValues);
                        }, 2000);
                    }
                });
        } else {
            setFormErrors(validate(formValues));
            setIsSubmit(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formStatus === 'initiate') {
            new Promise((resolve) => {
                fetch(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/validate`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formValues)
                }).then((res) => {
                    if (res.ok) { return res.json(); } else {
                        res.json().then((r) => {
                            swal({
                                title: 'User not registered',
                                text: r.message,
                                icon: 'warning'
                            }).then(() => {
                                setTimeout(() => {
                                    setFormStatus('initiate');
                                    setFormErrors({});
                                    setFormValues(initialValues);
                                }, 750);
                            });
                        })
                    }
                }).then((res) => {
                    getInfo(e);
                    resolve();
                })
            })
        }
        if (formStatus === 'otp') {
            otpVerify(e);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const phoneregex =
            /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/;

        if (!values.name) {
            errors.name = 'First Name is required!';
        }

        if (!values.last_name) {
            errors.name = 'Last Name is required!';
        }
        if (!values.email) {
            errors.email = 'Email is required!';
        } else if (!regex.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        }
        if (!values.password) {
            errors.password = 'Password is required';
        } else if (values.password.length < 6) {
            errors.password = 'Password must be more than 6 characters';
        }

        if (!values.phone) {
            errors.phone = 'Mobile number is required';
        } else if (!phoneregex.test(values.phone)) {
            errors.phone = 'Enter proper Phone number';
        }

        return errors;
    };

    return (
        <div className={classes.signin}>
            <Seo title="" canonical={window.location.pathname} description="" />
            <div>
                <img src={SignImage} alt="" />
            </div>
            <div>
                {formStatus === 'initiate' && (
                    <>
                        <h2>Sign Up</h2>
                        <p>Create a account and enjoy service!</p>
                        <div className={classes.form_container}>
                            <div className={classes.form_name}>
                                <div className={classes.form_name_1}>
                                    <CssTextField
                                        label="First Name"
                                        id="custom-css-outlined-input"
                                        InputLabelProps={{
                                            style: { color: '#fff' }
                                        }}
                                        multiline={false}
                                        name="name"
                                        onChange={handleChange}
                                        className={classes.first_tf}
                                        value={formValues.name}
                                    />
                                    <p className={classes.errors}>{formErrors.name}</p>
                                </div>
                                <div>
                                    <CssTextField
                                        label="Last Name"
                                        id="custom-css-outlined-input"
                                        InputLabelProps={{
                                            style: { color: '#fff' }
                                        }}
                                        multiline={false}
                                        name="last_name"
                                        onChange={handleChange}
                                        className={classes.first_tf}
                                        value={formValues.last_name}
                                    />
                                    <p className={classes.errors}>{formErrors.last_name}</p>
                                </div>
                            </div>
                            <div>
                                <CssTextField
                                    label="Email"
                                    id="custom-css-outlined-input"
                                    type="email"
                                    InputLabelProps={{
                                        style: { color: '#fff' }
                                    }}
                                    multiline={false}
                                    className={classes.first_tf}
                                    onChange={handleChange}
                                    name="email"
                                    value={formValues.email}
                                />
                                <p className={classes.errors}>{formErrors.email}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <p style={{ alignSelf: 'center', marginBottom: '0px', paddingRight: '10px' }}>+91 </p>
                                <CssTextField
                                    label="Mobile Number"
                                    id="custom-css-outlined-input"
                                    InputLabelProps={{
                                        style: { color: '#fff' }
                                    }}
                                    multiline={false}
                                    className={classes.first_tf}
                                    name="phone"
                                    onChange={handleChange}
                                    value={formValues.phone}
                                />
                                <p className={classes.errors}>{formErrors.phone}</p>
                            </div>
                            <div>
                                <PTextField
                                    id="outlined-password-input"
                                    label="Create Password"
                                    type="password"
                                    autoComplete="current-password"
                                    InputLabelProps={{
                                        style: { color: '#fff' }
                                    }}
                                    name="password"
                                    onChange={handleChange}
                                    className={classes.first_tf}
                                    value={formValues.password}
                                />
                                <p className={classes.errors}>{formErrors.password}</p>
                            </div>
                            <p>
                                By clicking Agree & Join, you agree to the MoneyFactory{' '}
                                <span>User Agreement, Privacy Policy,</span> and{' '}
                                <span>Cookie Policy.</span>
                            </p>
                        </div>

                        <div className={classes.bottom_buttons}>
                            <div>
                                <button type='submit' onClick={handleSubmit}>Sign Up</button>
                            </div>
                            <div>
                                <p>Already have an account?</p>
                                <Link to="/signin">
                                    <button onClick={handleOnClick} className={classes.signinBtn}>
                                        Sign In
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className={classes.footer}>
                            <p>Copyright © 2022 MoneyFactory. All rights reserved.</p>
                        </div>
                    </>
                )}
                {formStatus === 'otp' && (
                    <>
                        <h3>OTP Verification</h3>
                        <p className="mt-2">
                            Enter One Time Password we sent on your mobile number{' '}
                            {formValues.phone}
                        </p>
                        <form method="post" onSubmit={handleSubmit}>
                            <CssTextField
                                label="otp"
                                id="otp"
                                InputLabelProps={{
                                    style: { color: '#fff' }
                                }}
                                className={`${classes.first_tf} ${classes.fullwidth}`}
                                name="otp"
                                placeholder="Enter 6 digit OTP"
                                pattern="[0-9]{6}"
                                required
                            />
                            <p className={classes.otperror} style={{ marginBottom: 0 }}>
                                {formErrors.otp}
                            </p>
                            <span style={{ color: '#FFFFFF' }}>
                                Didn`t receive code?
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => sendOTP()}
                                    style={resend ? { pointerEvents: 'none' } : {}}
                                    className={
                                        resend ? `${classes.disabled}` : `${classes.enabled}`
                                    }
                                >
                                    Resend OTP {resend ? `in ${counter}s` : ''}
                                </a>
                            </span>
                            <br />
                            <Button
                                type="submit"
                                className={`${classes.button} ${classes.width} mt-5 mb-3`}
                            >
                                {isSubmit ? (
                                    <Spinner animation="border" variant="light" />
                                ) : (
                                    'CONTINUE'
                                )}
                            </Button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default SignIn;
