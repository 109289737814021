import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import classes from './Benifits.module.css';
import Process from './Process/Process';
import GauranteedCommision from '../assets/gauranteedCommission.png';
import firstSubscription from '../assets/firstSubs.png';
import recurringPayout from '../assets/recurringPayout.png';
import immidiatePayout from '../assets/immidiatePayout.png';
import compitativePricing from '../assets/compitativePricing.png';
import joiningFee from '../assets/joiningFee.png';
const investingMode = [
  {
    img: GauranteedCommision,
    alt: 'Guaranteed commission',
    title: 'Guaranteed commission',
    content:
      'Every time someone subscribes to a plan on MoneyFactory you earn a commission'
  },
  {
    img: firstSubscription,
    alt: 'Upto Rs 1,000 for the first subscription',
    title: 'Upto Rs 1,000 for the first subscription',
    content: `For every referral's first subscription, get up to Rs 1,000* as your commission (depending on on location, trading platform & deposit amount)`
  },
  {
    img: recurringPayout,
    alt: '20% Recurring Payout',
    title: '20% Recurring Payout',
    content:
      'For every month, your referral continues to subscribe, you get an additional 20% commission.'
  },
  {
    img: immidiatePayout,
    alt: 'Immediate payouts',
    title: 'Immediate payouts',
    content:
      'Cash in your payout as soon as your  referral subscribes to a plan on MoneyFactory'
  },
  {
    img: compitativePricing,
    alt: 'Competitive pricing',
    title: 'Competitive pricing',
    content: 'Zero brokerage and 100% transparency'
  },
  {
    img: joiningFee,
    alt: 'No joining fee',
    title: 'No joining fee',
    content:
      'Simply sign up to get an affiliate link and share it with your network'
  }
];

function Benifits() {
  return (
    <div className={classes.differentWays}>
      <Container>
        <h2>
          What's in It
          <span className={classes.subHeading}> For you?</span>
        </h2>
        <Row>
          {investingMode.map((x, k) => {
            return (
              <Col key={k + 1} md={4} lg={4} xs={12} sm={12}>
                <Card className={classes.investCard}>
                  <Card.Body>
                    <ScrollAnimation animateIn="flipInX">
                      <div className={classes.cardImgContainer}>
                        <img
                          src={x.img}
                          style={{
                            maxWidth: '100%'
                          }}
                          alt={x.alt}
                          className={classes.investCardImgs}
                        />
                      </div>
                    </ScrollAnimation>
                    <Card.Text>
                      <h4 className={classes.investTitle}>{x.title}</h4>
                      <div className={classes.investContent}>{x.content}</div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Process />
    </div>
  );
}

export default Benifits;
