import React, { useState, useEffect } from 'react'
import classes from './MyQuantTable.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import doticon from '../../../assets/fi-rr-menu-dots-vertical.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import pause from '../../../assets/fi-sr-pause.svg';
import start from '../../../assets/fi-sr-play.svg';
import exit from '../../../assets/fi-sr-sign-out-alt.svg';
import { Select, MenuItem as Menulist } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Mode from '../../../assets/mode.svg';
import Deploy from '../../../assets/deploy.svg';
import Capital from '../../../assets/capital.svg';
import UpArrow from '../../../assets/upArrow.svg';
import DownArrow from '../../../assets/downArrow.svg';
import swal from 'sweetalert';
import './myquant.css'

const HiddenLabelSelect = styled(Select)({
    color: 'white',
    "& label.Mui-focused": {
        display: "none"
    },
    "& legend": {
        display: "none"
    },
    '& svg': {
        fill: "#00D37F"
    },
    '&:hover fieldset': {
        borderColor: '#00D37F !important'
    },
    '& input': {
        color: 'white'
    },
    '& fieldset': {
        border: '2px solid',
        borderColor: "#333333",

    },
    '&.Mui-focused fieldset': {
        // color:'black',
        borderColor: '#00D37F !important'
    }
});

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <img src={doticon} alt="" className={classes.doticon} />
        {children}
    </div>
));

const MenuItem = ({ icon, title }) => {
    return (
        <div className={classes.menuItem}>
            <div className={classes.menuItemIcon}>
                <img src={icon} alt="" />
            </div>

            <div className={classes.menuItemTitle}>{title}</div>
        </div>
    );
};

function MyQuantTable({ quantDetails, brokerName, brokerLogo }) {

    const [selected, setSelected] = useState([]);

    const checkele = (ele) => {
        if (selected.find(e => ele == e)) {
            return true;
        } else {
            return false;
        }
    }

    const updateQuantStatus = (quantId, status) => {
        if (!selected.length || selected.length === quantDetails.stocksignals.length) {
            const options = {
                quantId,
                status,
                userId: JSON.parse(localStorage.getItem("MF_user")).id,
            }

            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            }

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/update/quantstatus`, requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        // get error message from body or default to response status
                        const error = 'not subscribed';
                        return Promise.reject(error);
                    }
                    return res.json();
                })
                .then((res) => {
                    swal({
                        title: 'Successfully updated',
                        text: 'stocks updated successfully',
                        icon: 'success',
                        timer: 3000
                    })
                })
        } else {
            const options = {
                quantId,
                symbol: selected,
                status,
                userId: JSON.parse(localStorage.getItem("MF_user")).id,
            }

            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            }

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/update/signalstockstatus`, requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        // get error message from body or default to response status
                        const error = 'not subscribed';
                        return Promise.reject(error);
                    }
                    return res.json();
                })
                .then((res) => {
                    swal({
                        title: 'Successfully updated',
                        text: 'stocks updated successfully',
                        icon: 'success',
                        timer: 3000
                    })
                })
        }
    }

    const exitStock = (signalId) => {
        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ signalId })
        }
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/broker/exit/signals`, requestOptions)
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                swal({
                    title: 'Successfully Exited',
                    text: 'stocks exited successfully',
                    icon: 'success',
                    timer: 3000
                })
            })
    }

    const updateStockStatus = (quantId, symbol, status) => {
        const options = {
            quantId,
            symbol: [symbol],
            status,
            userId: JSON.parse(localStorage.getItem("MF_user")).id,
        }

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(options)
        }

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/update/signalstockstatus`, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    // get error message from body or default to response status
                    const error = 'not subscribed';
                    return Promise.reject(error);
                }
                return res.json();
            })
            .then((res) => {
                swal({
                    title: 'Successfully updated',
                    text: 'stocks updated successfully',
                    icon: 'success',
                    timer: 3000
                })
            })
    }

    const handleSelectAllClick = (event) => {
        let selectAllCheck = document.querySelectorAll(`.${quantDetails.quant.quant.name.replaceAll(' ', '_')}_checkbox input`);
        if (event.target.checked) {
            let newSelected = [];
            selectAllCheck.forEach((ele) => {
                newSelected.push(ele.value);
            })
            setSelected([...newSelected]);
            return;
        } else {
            setSelected([]);
        }
    };

    const handleSelectClick = (eve) => {
        if (eve.target.checked) {
            setSelected([...selected, eve.target.value]);
        } else {
            let allele = [...selected];
            allele = allele.filter((ele) => ele != eve.target.value);
            setSelected(allele);
        }
    }

    const calcTotalPnl = () => {
        let total = 0;
        quantDetails.stocksignals.map(ele => {
            total += ele?.pldata?.pl;
        })
        return Number(total).toFixed(2);
    }

    const profitPercentage = () => {
        let capital = (quantDetails.quant.quant.price * quantDetails.quant.quantity);
        let totalPnl = calcTotalPnl();
        let percent = (totalPnl / capital) * 100;
        percent = Math.round(percent * 100) / 100;
        return percent;
    }

    const checkNegativeNo = (value) => {
        if (value < 0) {
            return true;
        } else {
            return false;
        }
    }

    const checkNaN = (value) => {
        if (isNaN(value)) {
            return true;
        } else {
            return false;
        }
    }



    return (
        <div>
            <div className={classes.table}>
                <div className={classes.heading}>
                    <div className={classes.align}>
                        <div>
                            <div className={classes.container}>
                                <img src={quantDetails ? quantDetails.quant.quant.imgUrl : ""} alt="Quant Logo" width="55" />
                            </div>
                        </div>
                        <div>
                            <div>
                                {quantDetails.quant.quant.name}
                            </div>
                            <div>
                                <button className={classes.button}>Active</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={Deploy} alt="deploy" />
                        </div>
                        <div>
                            <div className={classes.text}>
                                Deployed On
                            </div>
                            <div>
                                {quantDetails.quant.createdDate}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={Capital} alt="capital" />
                        </div>
                        <div>
                            <div className={classes.text}>
                                Capital
                            </div>
                            <div>
                                {quantDetails.quant.quant.price * quantDetails.quant.quantity}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={Mode} alt="Mode" />
                        </div>
                        <div>
                            <div className={classes.text}>
                                Mode
                            </div>
                            <div>
                                {quantDetails.quant.mode}
                            </div>
                        </div>
                    </div>
                    <div className={classes.align}>
                        <div>
                            <img src={brokerLogo} alt="Broker" width="40" height="40" />
                        </div>
                        <div>
                            <div className={classes.text}>
                                {brokerName}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="" className={classes.menu}>
                                    <Dropdown.Item className={classes.dropItem} onClick={() => { updateQuantStatus(quantDetails.quant.quant._id, 'start') }}>
                                        <MenuItem icon={start} title={'Start'} />
                                    </Dropdown.Item>
                                    <Dropdown.Item className={classes.dropItem} onClick={() => { updateQuantStatus(quantDetails.quant.quant._id, 'pause') }}>
                                        <MenuItem icon={pause} title={'Pause'} />
                                    </Dropdown.Item>
                                    <Dropdown.Item className={`${classes.dropItem}` + " " + `${selected.length ? "disable" : "enable"}`} onClick={() => { updateQuantStatus(quantDetails.quant.quant._id, 'exit') }}>
                                        <MenuItem icon={exit} title={'Exit ALL'} />
                                    </Dropdown.Item>
                                    <Dropdown.Item className={`${classes.dropItem} ${selected.length ? "enable" : "disable"}`} onClick={() => { updateQuantStatus(quantDetails.quant.quant._id, 'start') }}>
                                        <MenuItem icon={exit} title={'Exit Selected'} />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <TableContainer className={classes.TableContainer}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        onChange={handleSelectAllClick}
                                        sx={{
                                            color: "#5A5A5A",
                                            '&.Mui-checked': {
                                                color: "#00D37F",
                                            },
                                        }}
                                        inputProps={{
                                            'aria-label': 'select all',
                                        }}
                                    />
                                </TableCell>

                                <TableCell sx={{ color: "white" }}>
                                    Instrument
                                </TableCell>

                                <TableCell>
                                    <div className={classes.flex}>
                                        Price
                                        <div className={classes.Arrow}>
                                            <div>
                                                <img src={UpArrow} alt="uparrow" />
                                            </div>
                                            <div>
                                                <img src={DownArrow} alt="uparrow" />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell>
                                    <div className={classes.flex}>
                                        Quantity
                                        <div className={classes.Arrow}>
                                            <div>
                                                <img src={UpArrow} alt="uparrow" />
                                            </div>
                                            <div>
                                                <img src={DownArrow} alt="uparrow" />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell>
                                    <div className={classes.flex}>
                                        LTP
                                        <div className={classes.Arrow}>
                                            <div>
                                                <img src={UpArrow} alt="uparrow" />
                                            </div>
                                            <div>
                                                <img src={DownArrow} alt="uparrow" />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell>
                                    <div className={classes.flex}>
                                        Current Value
                                        <div className={classes.Arrow}>
                                            <div>
                                                <img src={UpArrow} alt="uparrow" />
                                            </div>
                                            <div>
                                                <img src={DownArrow} alt="uparrow" />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell>
                                    <div className={classes.flex}>
                                        P&L
                                        <div className={classes.Arrow}>
                                            <div>
                                                <img src={UpArrow} alt="uparrow" />
                                            </div>
                                            <div>
                                                <img src={DownArrow} alt="uparrow" />
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>

                                <TableCell>
                                    Order State
                                </TableCell>

                                <TableCell className={classes.right}>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            Action
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                quantDetails.stocksignals.map((ele, ind) => {

                                    return (
                                        <TableRow key={ind}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    sx={{
                                                        color: "#5A5A5A",
                                                        '&.Mui-checked': {
                                                            color: "#00D37F",
                                                        },
                                                    }}
                                                    onChange={handleSelectClick}
                                                    className={`${quantDetails.quant.quant.name.replaceAll(' ', '_')}_checkbox`}
                                                    inputProps={{
                                                        'aria-label': 'select all',
                                                    }}
                                                    checked={selected.length ? checkele(ele.symbol) : false}
                                                    value={ele.symbol}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                {ele.symbol}
                                            </TableCell>

                                            <TableCell>
                                                {ele.signal == 1 ? Number(ele?.price?.entry_price).toFixed(2) : Number(ele?.price?.exit_price).toFixed(2)}
                                            </TableCell>

                                            <TableCell>
                                                <div>
                                                    {ele?.qty}
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                <div className={classes.green}>
                                                    ₹ {ele.ltp ? Number(ele.ltp).toFixed(2) : "---"}
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                <div>
                                                    {Number(ele?.data?.currentvalue).toFixed(2)}
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                <div
                                                    className={checkNegativeNo(ele?.pldata?.pl) ? classes.red : classes.green}>
                                                    {Number(ele?.pldata?.pl).toFixed(2)}
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                {
                                                    ele.signal == 1 ? <span className='f_green_color bs_btn'>Entry</span> : <span className='f_red_color bs_btn'>Exit</span>
                                                }
                                            </TableCell>

                                            <TableCell className={classes.right}>
                                                <div className='d-flex align-items-center'>
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle} />
                                                        <Dropdown.Menu size="sm" title="" className={classes.menu}>
                                                            <Dropdown.Item className={classes.dropItem} onClick={() => { updateStockStatus(quantDetails.quant.quant._id, ele.symbol, 'start') }}>
                                                                <MenuItem icon={start} title={'Start'} />
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className={classes.dropItem} onClick={() => { updateStockStatus(quantDetails.quant.quant._id, ele.symbol, 'pause') }}>
                                                                <MenuItem icon={pause} title={'Pause'} />
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className={classes.dropItem} onClick={() => { exitStock(ele._id) }}>
                                                                <MenuItem icon={exit} title={'Exit'} />
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <button className={ele.last_state === "start" ? 'rount_ntn entry_dot' : 'rount_ntn exit_dot'}></button>
                                                    <button type='button' className={ele.last_state === "start" ? "cm_btn start_btn" : ele.last_state === "pause" ? "cm_btn paused_btn" : "cm_btn exit_btn"}>{ele.last_state}</button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }


                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Total PNL & ROI%</TableCell>
                                <TableCell className={checkNegativeNo(calcTotalPnl()) ? classes.red : classes.green}>{checkNegativeNo(calcTotalPnl()) ? `- ₹ ${-1 * calcTotalPnl()}` : !checkNaN(calcTotalPnl()) ? `₹ ${calcTotalPnl()}` : "---"}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{quantDetails ? !checkNaN(profitPercentage()) ? `${profitPercentage()}%` : "---" : ""}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.view_more}>
                    <div>
                        View More
                    </div>
                    <div>
                        <KeyboardArrowDownIcon sx={{ fill: "#00D37F" }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyQuantTable