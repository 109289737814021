import Homepage from './pages/LandingPage/Hompage/Homepage';
import { Route, Switch, useLocation } from 'react-router-dom';
import Proviso from './pages/ProvisoPage/Proviso';
import { AnimatePresence } from 'framer-motion';
// import MainPage from './pages/LandingPage/Hompage/Mainpage';
import BlogPage from './pages/Blog/blog';
import BlogDetailsPage from './pages/BlogDetails/blogDetails';
import NF from './pages/404';
import Dashboard from './pages/DashboardPage/Dashboard2';
import WelcomeModule from './pages/Welcome';
import Dashboard2 from './pages/DashboardPage/Dashboard';
import Discover from './components/MarketPlace/Discover/Discover';
import Description from './components/MarketPlace/Description/Description.jsx';
import Signup from './pages/SignUp/Signup';
import SignIn from './pages/SignIn/SignIn';
import CreateStrategy from './pages/CreateStrategy/CreateStrategy';
import Profile from './pages/ProfilePage/Profile';
import MyQuants from './pages/MyQuants/MyQuants';
import ManageSubscriptions from './pages/AdminSubscriptions/ManageSubscriptions';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/Resetpassword/resetpassword';
import SubscriptionPlans from './pages/SubscriptionPlans/SubscriptionPlans';
import CommunityPage from './pages/CommunityPage/CommunityPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import SetUp from './pages/Setup';
import AffilatePage from './pages/AffilatePage/AffilatePage';
import Login from './Login/Login';
import Getaccess from './pages/Earlyaccess';
import OrderApprove from './pages/OrderApprove/OrderApprove';

function App() {
  const location = useLocation();

  // ReactGA.initialize(process.env.REACT_APP_GA_ID);
  // ReactGA.send({ hitType: 'pageview', page: '/' });
  return (
    <div className="App">
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" render={(props) => <Homepage />} />
          <Route exact path="/partner-with-us" render={(props) => <Login />} />
          <Route exact path="/join-us" render={(props) => <Getaccess />} />
          <Route
            exact
            path="/affilate"
            render={(props) => <AffilatePage />}
          />
          <Route
            exact
            path="/proviso"
            render={(props) => <Proviso {...props} />}
          />
          <Route exact path="/setup" render={(props) => <SetUp {...props} />} />
          <Route
            exact
            path="/discover"
            render={(props) => <Discover {...props} />}
          />
          <Route
            exact
            path="/invest"
            render={(props) => <Homepage {...props} />}
          ></Route>
          <Route
            exact
            path="/order"
            render={(props) => <OrderApprove {...props} />}
          ></Route>
          <Route
            exact
            path="/dashboard"
            render={(props) => <Dashboard {...props} />}
          ></Route>
          <Route
            exact
            path="/welcome"
            render={(props) => <WelcomeModule {...props} />}
          ></Route>
          <Route
            exact
            path="/description/:quantId"
            render={(props) => <Description {...props} />}
          ></Route>
          <Route
            exact
            path="/dashboard2"
            render={(props) => <Dashboard2 {...props} />}
          ></Route>
          <Route
            exact
            path="/signup"
            render={(props) => <Signup {...props} />}
          ></Route>
          <Route
            exact
            path="/createStrategy"
            render={(props) => <CreateStrategy {...props} />}
          ></Route>
          <Route
            exact
            path="/signin"
            render={(props) => <SignIn {...props} />}
          ></Route>
          <Route
            exact
            path="/profile"
            render={(props) => <Profile {...props} />}
          ></Route>
          <Route
            exact
            path="/myquants"
            render={(props) => <MyQuants {...props} />}
          />
          <Route
            exact
            path="/subscriptions"
            render={(props) => <ManageSubscriptions {...props} />}
          ></Route>
          <Route
            exact
            path="/forgotPassword"
            render={(props) => <ForgotPassword {...props} />}
          ></Route>
          <Route
            exact
            path="/resetpassword"
            render={(props) => <ResetPassword {...props} />}
          ></Route>
          <Route
            exact
            path="/plan"
            render={(props) => <SubscriptionPlans {...props} />}
          ></Route>
          <Route
            exact
            path="/community"
            render={(props) => <CommunityPage {...props} />}
          ></Route>
          <Route
            exact
            path="/blog"
            render={(props) => <BlogPage {...props} />}
          ></Route>
          <Route
            exact
            path="/privacy-policy"
            render={(props) => <PrivacyPolicy {...props} />}
          ></Route>
          <Route
            exact
            path="/disclaimer"
            render={(props) => <Disclaimer {...props} />}
          ></Route>
          <Route
            exact
            path="/blog-details/:id"
            render={(props) => <BlogDetailsPage {...props} />}
          ></Route>
          <Route path="*" render={(props) => <NF {...props} />} />
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
