import React from 'react';
import Navbar from '../Navbar/Navbar';
import classes from './home.module.css';
import MBgrad from '../MBgrad/MBgrad';
import IMRE from '../IMRE/IMRE';
import Siat from '../SIAT/Siat';
import Eibp from '../EIBP/Eibp';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Accordion, Container, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import Ellips from '../../assets/Ellipse62.png';
// import FAQSchema from '../SEO/FaqSchema';
import Button from '../Ui/Button';
import ScrollAnimation from 'react-animate-on-scroll';
function Testimonial() {
  return (
    <>
    {/* <FAQSchema /> */}
    <div className={classes.faq_container} id="faq">
      <Container>
        <ScrollAnimation duration={2} animateIn="fadeIn">
          <h2 className={classes.faqTitle}>FAQs</h2>
        </ScrollAnimation>
        <Accordion defaultActiveKey="0" className={classes.faqQunDiv}>
          <Accordion.Item eventKey="0" className={classes.faqItems}>
            <Accordion.Header className={classes.faqHeader}>
              Why invest with MoneyFactory?
            </Accordion.Header>
            <Accordion.Body className={classes.faqBody}>
              Understanding the market and learning where to invest your money
              can often take years of practice. MoneyFactory bridges that gap
              by providing you with AI-assisted investment solutions that help
              you navigate the market while reducing your margin for error. You
              get high-performing quants to invest in, a smart assistant that
              helps you make better decisions during buying and selling your
              stocks, and 3 easy investing modes that take away any guesswork
              from your decision-making.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className={classes.faqItems}>
            <Accordion.Header className={classes.faqHeader}>
              How do you ensure the safety of my money?
            </Accordion.Header>
            <Accordion.Body className={classes.faqBody}>
              At no point during the process does MoneyFactory have access to
              your funds. They stay in your broker account throughout, keeping
              them safe.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.faqItems}>
            <Accordion.Header className={classes.faqHeader}>
            What is the minimum investment amount?
            </Accordion.Header>
            <Accordion.Body className={classes.faqBody}>
              You can start with as low as Rs 100 if you opt for the daily
              investing plan. For weekly investing, the minimum amount is Rs
              25,000
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.faqItems}>
            <Accordion.Header className={classes.faqHeader}>
              Is there a lock-in period for investing with MoneyFactory? Are
              there any charges for withdrawal?
            </Accordion.Header>
            <Accordion.Body className={classes.faqBody}>
              Absolutely Not. We help you invest in performing stocks and you
              can close your investments anytime. There is no lock-in period and
              no charges for withdrawal.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.faqItems}>
            <Accordion.Header className={classes.faqHeader}>
            What are the types of investment options with MoneyFactory?
            </Accordion.Header>
            <Accordion.Body className={classes.faqBody}>
              With MoneyFactory you get 3 different investing modes. <br />
              <ul>
                <li>
                  <b>Simulated Mode:</b> &nbsp; Here you take the help of our AI-driven
                  platform to learn how to trade better by only making simulated
                  investments and seeing how they play out in the real world.
                </li>
                <li>
                  <b>Assisted Mode:</b> &nbsp; Here, our intelligent assistant tracks
                  the price fluctuation in shares and instantly notifies you
                  about profitable trades so that you can make your move.{' '}
                </li>
                <li>
                  <b>Automatic Mode:</b> &nbsp; Here, you let our AI do all the work
                  and take control of your portfolio to invest and sell at the
                  optimal time, taking you towards exponential profits.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.faqItems}>
            <Accordion.Header className={classes.faqHeader}>
              Can I invest in stocks with little money?
            </Accordion.Header>
            <Accordion.Body className={classes.faqBody}>
              There is no minimum limit to start investing in the stock market.
              You just need enough funds to cover the price of a stock. So if a
              stock is priced at Rs 10 a share, you just need that much to start
              investing in India.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.faqItems}>
            <Accordion.Header className={classes.faqHeader}>
              Are stocks a good investment option for beginners?
            </Accordion.Header>
            <Accordion.Body className={classes.faqBody}>
              Buying stocks of individual companies is a great option for
              beginners if done right. Although a risky investment, if you start
              with small capital and consider long-term investment strategies,
              you can stand to make high returns. However, if you are not
              completely sure of your strategy, investing in mutual funds or
              quants — like the ones on MoneyFactory — is a better option
              because these allow you to invest in a basket of stocks, thereby
              slightly mitigating your risk.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <div className={classes.imgWrapper}>
          <span>
            <Button wrapperClass={classes.btnImg} btnClass={classes.homeBtn}>
              {' '}
              LOAD MORE
            </Button>
          </span>
        </div> */}
      </Container>
    </div>
    </>
  );
}

export default Testimonial;
