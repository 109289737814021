import React from 'react';
import { BlogStyle } from './blog.style';
import { Box, Button } from '@mui/material';
import blogBannerReadMore from '../../assets/blogBannerVector.png';
import moment from 'moment';
import { Link } from 'react-router-dom';
function Cards(props) {
  const banner = props.blogs;
  
  const bannerStyle = (banner?.attributes?.CoverImage?.data?.attributes?.url) ? {
    backgroundImage: `url(${props.BlogBaseURL}/st/${banner?.attributes?.CoverImage?.data?.attributes?.url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  } : {};
  // column section
  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  return (
    <>
      {banner && banner && banner.attributes ? (
        <Box key={banner.id} style={bannerStyle} sx={BlogStyle.banner}>
          <Box sx={BlogStyle.subBanner}></Box>

          <Box sx={BlogStyle.bannerTextWrapper}>
            <Button sx={BlogStyle.btnBanner}>
              <Box component="span" sx={BlogStyle.subBtnBanner}>
                
                {
                  banner.attributes?.categories?.data?.map((x, k) => {
                    return x.attributes?.Name;
                  })
                }
              </Box>
            </Button>
            <Box sx={BlogStyle.bannerDate}>{moment(banner.attributes.publishedAt).format('Do MMMM, YYYY')}</Box>
            <Box sx={BlogStyle.bannerText}>
              {extractContent(banner.attributes?.Heading).length > 55 ? extractContent(banner.attributes?.Heading).substring(0, 58) + '...' : extractContent(banner.attributes?.Heading)}
              {/* banner.attributes.Heading */}
            </Box>
            <Link to={`/blog-details/${banner.id}`}><Box sx={BlogStyle.bannerReadMore}>Read More<Box component="span" sx={BlogStyle.bannerReadMoreBtn}><img src={blogBannerReadMore} alt="read more" style={{paddingBottom: '7px'}}/></Box></Box></Link>
            
          </Box>
        </Box>
      ) : ('')}
    </>
  );
}

export default Cards;
