import React from 'react';
import classes from './SidebarItem.module.css';

function SidebarItem({ Icon, category }) {
  return (
    <div className={`${classes.sidebarItem} sidebaritem_wrap`}>
      <div className={classes.flex}>
        <img src={Icon} alt="icon" />
        <h3>{category}</h3>
      </div>
    </div>
  );
}

export default SidebarItem;
