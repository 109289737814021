import React from 'react';
import classes from './Eibp.module.css';
import eibp from '../../assets/equities.svg';

function Eibp() {
  return (
    <div className={classes.eibp_container}>
      <div className={classes.flex}>
        <div className={classes.tex}>
          <h2 className="fade-right">
            Efficient Investing,
            <br /> brilliantly personalized
          </h2>
          <p className="fade-right">
            Just answer a few questions, and we’ll give you a personalized
            portfolio of wonderfully diversified, stocks designed to weather the
            market and grow your money.
          </p>
        </div>
        <div className={`${classes.img} fade-right-delay`}>
          <img loading="lazy" src={eibp} alt="equity" />
        </div>
      </div>
    </div>
  );
}

export default Eibp;
