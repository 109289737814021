import React from 'react';
import classes from './WhyMoneyFactory.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import ScrollAnimation from 'react-animate-on-scroll';
import WhyMoneyFactoryImg from '../assets/whyMoneyFactoryImg.svg';
import astraunotImg from '../assets/astraunotVector.png';
function WhyMoneyFactory() {
  return (
    <div className={classes.backgroundImageContainer}>
      <Container fluid>
        <div className={classes.rightFluid}>
          <div className={classes.invest_container}>
            <Row>
              <Col xs={12} sm={12} md={5} lg={5}>
                <div className={classes.investText}>
                  <div className={classes.subHeadingText}>
                    <p>Why MoneyFactory</p>
                  </div>
                  <ScrollAnimation duration={2} animateIn="fadeIn">
                    <h1>
                      Safe and easy access to
                      <span> your money</span>
                    </h1>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="bounceInLeft">
                    <p>
                    We make it easy to get your money back into your hands when it matters most. Start earning passive income today.
                    </p>
                  </ScrollAnimation>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className={`${classes.mainImg}`}>
                  <ScrollAnimation animateIn="flipInX">
                    <motion.nav
                      whileHover={{ scale: 1.0 }}
                      whileTap={{ scale: 0.8 }}
                    >
                      <img
                        src={WhyMoneyFactoryImg}
                        alt="INVEST LIKE THE TOP 1% with Moneyfactory"
                        width="100%"
                      />
                    </motion.nav>
                  </ScrollAnimation>
                </div>
              </Col>
            </Row>
            {/* <div className={classes.vectorImg}> */}
            {/* </div> */}
          </div>
        </div>
      </Container>
      <img className={classes.vectorImg} src={astraunotImg} alt="" />
    </div>
  );
}

export default WhyMoneyFactory;
