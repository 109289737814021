import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';


import UpArrow from '../../../assets/upArrow.svg';
import DownArrow from '../../../assets/downArrow.svg';

import '../Myaccount/my_account.css'
import '../SubscribedQuants/subscribedquants.css'

const Report = () => {

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div className='subscriber_wrap cm_center'>
            <p>Coming soon</p>
        </div>
        // <div className='right_aide_section'>
        //     <div className='my_report_elect_btn_wrap'>
        //         <div className='my_report_select_wrap'>
        //             <div className='select_left_wrap'>
        //                 <p>Sort By</p>
        //                 <FormControl>
        //                     <InputLabel id="month_wise_select_label">Month Wise</InputLabel>
        //                     <Select
        //                         labelId="month_wise_select_label"
        //                         id="month_wise_select"
        //                         value={age}
        //                         label="Month Wise"
        //                         onChange={handleChange}
        //                     >
        //                         <MenuItem value={10}>Ten</MenuItem>
        //                         <MenuItem value={20}>Twenty</MenuItem>
        //                         <MenuItem value={30}>Thirty</MenuItem>
        //                     </Select>
        //                 </FormControl>
        //             </div>

        //             <div className='select_left_wrap'>
        //                 <p>Filter</p>
        //                 <FormControl>
        //                     <InputLabel id="filter_select_label">Month Wise</InputLabel>
        //                     <Select
        //                         labelId="filter_select_label"
        //                         id="filter_select"
        //                         value={age}
        //                         label="Month Wise"
        //                         onChange={handleChange}
        //                     >
        //                         <MenuItem value={10}>Ten</MenuItem>
        //                         <MenuItem value={20}>Twenty</MenuItem>
        //                         <MenuItem value={30}>Thirty</MenuItem>
        //                     </Select>
        //                 </FormControl>
        //             </div>
        //         </div>

        //         <div className='xls_pdf_btn_wrap'>
        //             <button type='button' className='cm_pdf_btn'>
        //                 <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                     <path d="M20.4958 6.47257V14.285C20.4958 14.8603 20.9622 15.3266 21.5375 15.3266C22.1128 15.3266 22.5791 14.8603 22.5791 14.285V6.47257C22.5791 3.02637 19.7754 0.222656 16.3292 0.222656H6.74601C3.2998 0.222656 0.496094 3.02637 0.496094 6.47257V14.285C0.496094 14.8603 0.962441 15.3266 1.53775 15.3266C2.11305 15.3266 2.5794 14.8603 2.5794 14.285V6.47257C2.5794 4.1751 4.44854 2.30596 6.74601 2.30596H16.3292C18.6267 2.30596 20.4958 4.1751 20.4958 6.47257Z" fill="white" />
        //                     <path d="M18.3606 9.80331C18.3606 9.228 17.8942 8.76166 17.3189 8.76166H15.5481C14.6866 8.76166 13.9856 8.06073 13.9856 7.19918V5.42837C13.9856 4.85307 13.5193 4.38672 12.944 4.38672C12.3687 4.38672 11.9023 4.85307 11.9023 5.42837V7.19918C11.9023 9.20946 13.5378 10.845 15.5481 10.845H17.3189C17.8942 10.845 18.3606 10.3786 18.3606 9.80331Z" fill="white" />
        //                     <path d="M13.8801 24.8059H11.5979C10.961 24.8059 10.4427 24.2877 10.4427 23.6507V18.4518C10.4427 17.8765 9.97633 17.4102 9.40103 17.4102C8.82572 17.4102 8.35938 17.8765 8.35938 18.4518V23.6507C8.35938 25.4364 9.81217 26.8892 11.5979 26.8892H13.8801C14.4554 26.8892 14.9218 26.4228 14.9218 25.8475C14.9218 25.2722 14.4554 24.8059 13.8801 24.8059Z" fill="white" />
        //                     <path d="M19.7425 21.108C19.0136 21.108 18.4639 20.7609 18.4639 20.3007C18.4639 19.8556 18.8398 19.4935 19.3019 19.4935C19.6813 19.4935 20.309 19.5404 21.3745 19.9461C21.9121 20.1508 22.5139 19.881 22.7186 19.3433C22.9234 18.8057 22.6535 18.2039 22.1159 17.9992C21.0605 17.5973 20.1664 17.4102 19.3019 17.4102C17.6911 17.4102 16.3806 18.7069 16.3806 20.3007C16.3806 21.9486 17.8259 23.1913 19.7425 23.1913C20.3488 23.1913 20.8609 23.561 20.8609 23.9986C20.8609 24.4287 20.4412 24.8059 19.9628 24.8059C19.5506 24.8059 18.6096 24.7111 17.6971 24.356C17.1612 24.1473 16.5572 24.4128 16.3486 24.9489C16.1399 25.485 16.4053 26.0888 16.9415 26.2975C18.0637 26.7342 19.2704 26.8892 19.9628 26.8892C20.7373 26.8892 21.4744 26.596 22.0386 26.0636C22.6226 25.5125 22.9442 24.7792 22.9442 23.9986C22.9442 22.4047 21.508 21.108 19.7425 21.108Z" fill="white" />
        //                     <path d="M5.12617 22.2438C5.11174 22.2036 5.0957 22.1644 5.07878 22.1258C5.09518 22.0884 5.11086 22.0504 5.12492 22.0115L6.59454 18.8965C6.84 18.3762 6.61724 17.7555 6.09694 17.51C5.57653 17.2645 4.95586 17.4873 4.7104 18.0076L3.51348 20.5448L2.32491 18.0099C2.08069 17.4891 1.46044 17.2648 0.939511 17.509C0.418685 17.7532 0.194365 18.3735 0.438633 18.8943L1.89538 22.001C1.91043 22.0435 1.92705 22.0851 1.94491 22.1258C1.92653 22.1677 1.9096 22.2105 1.89419 22.2543L0.436133 25.4111C0.194938 25.9334 0.422748 26.5523 0.945032 26.7935C1.08649 26.8588 1.23498 26.8897 1.38117 26.8897C1.77481 26.8897 2.15158 26.6654 2.32751 26.2846L3.51354 23.7167L4.708 26.2868C4.95044 26.8086 5.57018 27.0348 6.09157 26.7925C6.61334 26.55 6.83964 25.9305 6.59719 25.4088L5.12617 22.2438Z" fill="white" />
        //                 </svg>
        //             </button>

        //             <button type='button' className='cm_pdf_btn'>
        //                 <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                     <path d="M6.63251 11.3945H4.9191C4.70393 11.3945 4.49756 11.4802 4.3456 11.6326C4.19364 11.785 4.10868 11.9916 4.10938 12.2068L4.12131 18.3577C4.12131 18.8049 4.48385 19.1674 4.93103 19.1674C5.37821 19.1674 5.74074 18.8049 5.74074 18.3577V16.4901C6.07181 16.4885 6.42549 16.487 6.63251 16.487C8.05524 16.487 9.21275 15.3448 9.21275 13.9408C9.21275 12.5368 8.05524 11.3945 6.63251 11.3945ZM6.63251 14.8676C6.42366 14.8676 6.06868 14.869 5.73632 14.8708C5.73459 14.526 5.73308 14.1548 5.73308 13.9408C5.73308 13.7576 5.73216 13.3743 5.73119 13.014H6.63246C7.15327 13.014 7.59327 13.4384 7.59327 13.9408C7.59327 14.4432 7.15332 14.8676 6.63251 14.8676Z" fill="white" />
        //                     <path d="M13.5088 11.3945H11.8175C11.6025 11.3945 11.3963 11.48 11.2444 11.6322C11.0925 11.7844 11.0074 11.9908 11.0078 12.2058C11.0078 12.2058 11.0199 18.157 11.0199 18.1786C11.0207 18.3933 11.1068 18.599 11.2592 18.7503C11.4109 18.9009 11.616 18.9854 11.8296 18.9854H11.8327C11.8838 18.9851 13.0901 18.9806 13.5724 18.9721C15.402 18.9402 16.73 17.3495 16.73 15.1899C16.7299 12.9198 15.4355 11.3945 13.5088 11.3945ZM13.5441 17.353C13.3344 17.3566 12.9736 17.3596 12.6366 17.3618C12.6344 16.6584 12.6299 13.7449 12.6287 13.014H13.5088C14.995 13.014 15.1105 14.6795 15.1105 15.19C15.1105 16.253 14.6262 17.3341 13.5441 17.353Z" fill="white" />
        //                     <path d="M22.0667 12.9476C22.5139 12.9476 22.8764 12.585 22.8764 12.1378C22.8764 11.6907 22.5139 11.3281 22.0667 11.3281H19.591C19.1438 11.3281 18.7812 11.6907 18.7812 12.1378V18.2462C18.7812 18.6934 19.1438 19.056 19.591 19.056C20.0381 19.056 20.4007 18.6934 20.4007 18.2462V15.9439H21.87C22.3172 15.9439 22.6798 15.5814 22.6798 15.1342C22.6798 14.687 22.3172 14.3245 21.87 14.3245H20.4007V12.9476H22.0667Z" fill="white" />
        //                     <path d="M24.4563 7.93664H23.8625V7.70565C23.8625 6.66479 23.4681 5.67558 22.752 4.92017L19.3887 1.37248C18.6277 0.56978 17.5568 0.109375 16.4506 0.109375H5.67084C4.33141 0.109375 3.24169 1.19909 3.24169 2.53852V7.93664H2.6479C1.30847 7.93664 0.21875 9.02635 0.21875 10.3658V20.0824C0.21875 21.4218 1.30847 22.5115 2.6479 22.5115H3.24169V25.3186C3.24169 26.658 4.33141 27.7477 5.67084 27.7477H21.4333C22.7728 27.7477 23.8625 26.658 23.8625 25.3186V22.5115H24.4563C25.7957 22.5115 26.8854 21.4218 26.8854 20.0824V10.3658C26.8854 9.02635 25.7957 7.93664 24.4563 7.93664ZM4.86112 2.53852C4.86112 2.09205 5.22436 1.72881 5.67084 1.72881H16.4506C17.1143 1.72881 17.7568 2.00503 18.2135 2.4867L21.5768 6.03439C22.0064 6.48762 22.243 7.08114 22.243 7.70565V7.93664H4.86112V2.53852ZM22.243 25.3186C22.243 25.765 21.8798 26.1283 21.4333 26.1283H5.67084C5.22436 26.1283 4.86112 25.765 4.86112 25.3186V22.5115H22.243V25.3186ZM25.266 20.0824C25.266 20.5289 24.9027 20.8921 24.4563 20.8921H2.6479C2.20142 20.8921 1.83818 20.5289 1.83818 20.0824V10.3658C1.83818 9.91931 2.20142 9.55607 2.6479 9.55607H24.4563C24.9027 9.55607 25.266 9.91931 25.266 10.3658V20.0824Z" fill="white" />
        //                 </svg>
        //             </button>
        //         </div>
        //     </div>


        //     <div className='my_report_table_wrap'>
        //         <TableContainer component={Paper} className="cm_table">
        //             <Table aria-label="simple table">
        //                 <TableHead>
        //                     <TableRow>
        //                         <TableCell>Segment</TableCell>
        //                         <TableCell>Total Buy Value</TableCell>
        //                         <TableCell>
        //                             <div className="d-flex align-items-center">
        //                                 Total Sell Value
        //                                 <div className="arrow ml-2">
        //                                     <div>
        //                                         <img src={UpArrow} alt="uparrow" />
        //                                     </div>
        //                                     <div>
        //                                         <img src={DownArrow} alt="uparrow" />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </TableCell>
        //                         <TableCell>
        //                             <div className="d-flex align-items-center">
        //                                 Realized Gain Loss
        //                                 <div className="arrow ml-2">
        //                                     <div>
        //                                         <img src={UpArrow} alt="uparrow" />
        //                                     </div>
        //                                     <div>
        //                                         <img src={DownArrow} alt="uparrow" />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </TableCell>
        //                         <TableCell>
        //                             <div className="d-flex align-items-center">
        //                                 Realized Gain Loss
        //                                 <div className="arrow ml-2">
        //                                     <div>
        //                                         <img src={UpArrow} alt="uparrow" />
        //                                     </div>
        //                                     <div>
        //                                         <img src={DownArrow} alt="uparrow" />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </TableCell>
        //                     </TableRow>
        //                 </TableHead>
        //                 <TableBody>
        //                     <TableRow>
        //                         <TableCell>
        //                             Equity
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 2300.00
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 505.00
        //                         </TableCell>
        //                         <TableCell className='exit_color'>
        //                             -₹ 1,795.00
        //                         </TableCell>
        //                         <TableCell className='exit_color'>
        //                             -₹ 78.04
        //                         </TableCell>
        //                     </TableRow>
        //                     <TableRow>
        //                         <TableCell>
        //                             Derivatives
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 236.00
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 235.95
        //                         </TableCell>
        //                         <TableCell className='exit_color'>
        //                             -₹ 0.85
        //                         </TableCell>
        //                         <TableCell className='exit_color'>
        //                             -₹ 0.36
        //                         </TableCell>
        //                     </TableRow>
        //                     <TableRow>
        //                         <TableCell>
        //                             Commodities
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 236.00
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 235.95
        //                         </TableCell>
        //                         <TableCell className='exit_color'>
        //                             -₹ 0.85
        //                         </TableCell>
        //                         <TableCell className='exit_color'>
        //                             -₹ 0.36
        //                         </TableCell>
        //                     </TableRow>
        //                     <TableRow>
        //                         <TableCell>
        //                             Grand Total
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 2536.80
        //                         </TableCell>
        //                         <TableCell>
        //                             ₹ 740.95
        //                         </TableCell>
        //                         <TableCell className='exit_color'>
        //                             -₹ 1,795.85
        //                         </TableCell>
        //                         <TableCell className='exit_color'>

        //                         </TableCell>
        //                     </TableRow>
        //                 </TableBody>
        //             </Table>
        //         </TableContainer>
        //     </div>
        // </div>
    )
}

export default Report