import React from 'react';
import classes from './Testimonials.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import Button from '../../../../components/Ui/Button';
import { useHistory } from 'react-router-dom';

function StartNow() {
  const history=useHistory()
  return (
    <div className={classes.saveMore_container}>
      <Container>
        <div className={classes.saveMore_inner_container}>
          <Row className={`align-items-center`}>
            <Col md={6} lg={6} xs={12} sm={12}>
              <div className={classes.textSection}>
                <h2>
                  Sign Up Now and Start
                  <br /> Earning!
                </h2>
                <p>Without spending years learning how the market works</p>
              </div>
            </Col>
            <Col md={2} lg={4} xs={12} sm={12}></Col>
            <Col md={2} lg={2} xs={12} sm={12}>
              {/* <div className={classes.imgWrapper}>
                <img src={Ellips} alt="elips" className={classes.btnImg} />
                <button className={`button ${classes.homeBtn}`}>
                  Join With Us
                </button>
              </div> */}
              <div className={classes.saveMoreSecondContent}>
                <a
                  href="/partner-with-us"
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  <Button
                    wrapperClass={classes.btnImg}
                    btnClass={classes.homeBtn}
                    customProps={true}
                   
                  >
                    {' '}
                    Partner with MoneyFactory
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default StartNow;
