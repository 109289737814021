import React from 'react';
import classes from './Community.module.css';
import Posts from './Posts/Posts';
import Stories from './Stories/Stories';
import Suggestions from './Suggestions/Suggestions';
const Community = () => {
  return (
    <div className={classes.com_soon}>
      <div className={classes.com}>
        <div className={classes.com_header}>Community</div>
        <div className={classes.com_body}>
          <div className={classes.com_left}>
            <Stories />
            <Posts />
          </div>
          <Suggestions />
        </div>
      </div>
      <div className={classes.soon}>Coming Soon</div>
    </div>
  );
};

export default Community;
