import React from 'react';
import classes from './blogDetail.module.css';
import { Container } from 'react-bootstrap';
import Rectangle97 from '../../assets/blogCardImgs/Rectangle97.png';
import Rectangle99 from '../../assets/blogCardImgs/Rectangle99.png';
import Rectangle101 from '../../assets/blogCardImgs/Rectangle101.png';
import Rectangle105 from '../../assets/blogCardImgs/Rectangle105.png';
import Rectangle106 from '../../assets/blogCardImgs/Rectangle106.png';
import Rectangle107 from '../../assets/blogCardImgs/Rectangle107.png';
import Rectangle111 from '../../assets/blogCardImgs/Rectangle111.png';
import Rectangle112 from '../../assets/blogCardImgs/Rectangle112.png';
import Rectangle113 from '../../assets/blogCardImgs/Rectangle113.png';
import Rectangle117 from '../../assets/blogCardImgs/Rectangle117.png';
import Rectangle118 from '../../assets/blogCardImgs/Rectangle118.png';
import Rectangle119 from '../../assets/blogCardImgs/Rectangle119.png';
import { Link } from 'react-router-dom';

const cardContent = [
  {
    img: Rectangle97,
    date: '18th july, 2022',
    title: 'How does smallcase subscription work?',
    describe:
      "Lorem Ipsum has been the industry's standard dummy text ever since.",
    more: 'Read More'
  },
  {
    img: Rectangle99,
    date: '18th july, 2022',
    title: 'How does smallcase subscription work?',
    describe:
      "Lorem Ipsum has been the industry's standard dummy text ever since.",
    more: 'Read More'
  },
  {
    img: Rectangle101,
    date: '18th july, 2022',
    title: 'How does smallcase subscription work?',
    describe:
      "Lorem Ipsum has been the industry's standard dummy text ever since.",
    more: 'Read More'
  }
];

function PopularBlogs() {
  // column section
  return (
    <Container>
      <div className={classes.blogPopular}>
        <div style={{ textAlign: 'left' }}>
          <span>
            <button type="button" className={`btn ${classes.btnCard1}`}>
              <span className={classes.btnCard1Text}>Banknifty</span>
            </button>
            <button type="button" className={`btn ${classes.btnCard1}`}>
              <span className={classes.btnCard1Text}>Trading</span>
            </button>
            <button type="button" className={`btn ${classes.btnCard1}`}>
              <span className={classes.btnCard1Text}>Investment</span>
            </button>
            <button type="button" className={`btn ${classes.btnCard1}`}>
              <span className={classes.btnCard1Text}>Zeusquants</span>
            </button>
          </span>
        </div>
        {/* <div className="row row-cols-2 row-cols-lg-4"> */}
        {/* <div className="col"><button type="button" className={`btn ${classes.btnCard1}`}>Banknifty</button></div>
            {/* <div className="col"><button type="button" className={`btn ${classes.btnCard1}`}>Trading</button></div>
            <div className="col"><button type="button" className={`btn ${classes.btnCard1}`}>Investment</button></div>
            <div className="col"><button type="button" className={`btn ${classes.btnCard1}`}>Zeusquants</button></div> */}
        {/* </div> */}
        <div className={classes.mainTitle}>Most Popular Blogs</div>
        <div
          className={`row row-cols-1 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 g-4 ${classes.PopularBlogsCard}`}
        >
          {cardContent.map((x, k) => {
            return (
              // -md-4 mt-4 padding-0
              <div className="col" key={k + 1}>
                <div className={classes.mainCard}>
                  <div className={classes.imgWrapper}>
                    <img src={x.img} className={classes.image} alt="cardImg" />
                    {/* <button type="button" className={`btn ${classes.btnCard}`}>
                      <p className={classes.subCardBtnText}>Banknifty</p>
                    </button> */}
                  </div>
                  <div className={classes.textWrapper}>
                    <div className={classes.date}>{x.date}</div>
                    <div className={classes.title}>{x.title}</div>
                    <div className={classes.description}>{x.describe}</div>
                    <Link to={'/blog-details'}><div className={classes.readMore}>{x.more}</div></Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default PopularBlogs;
