

function Card(props) {
    return (
        <>
            <div style={{ backgroundImage: 'url('.concat(props.img, ')'), height: '252px', width: '489px', backgroundSize: '489px 252px', textAlign: 'left', margin: '15px' }}>
                <h3 style={{ padding: '28px 0px 5px 25px' }}>{props.title}</h3>
                <p style={{ paddingLeft: '23px', maxWidth: '315px', fontSize: '14px' }}>{props.subtitle}</p>
                <button type='button' onClick={() => { props.link() }} className='cm_next_btn' style={{ marginLeft: '23px' }}>Get Started</button>
            </div>
        </>
    );
}

export default Card;
