import React from 'react';
import classes from './IMRE.module.css';
import imrei from '../../assets/imre.svg';

function IMRE() {
  return (
    <div className={classes.imre_container}>
      <div className={classes.flex}>
        <div className={classes.tex}>
          <h2 className="fade-right">
            Investing made <br />
            ridiculously easy
          </h2>
          <p className="fade-right">
            Investing isn’t easy. We just make it feel that way. Get started in
            minutes, pick one or couple of our propreitary investment strategies
            and start your pofitable investing journey.
          </p>
          <button className="button fade-right">Get Started</button>
        </div>
        <div className={`${classes.img} fade-right-delay`}>
          <img
            src={imrei}
            loading="lazy"
            alt="intelligent"
            width="709"
            height="595"
          />
        </div>
      </div>
    </div>
  );
}

export default IMRE;
