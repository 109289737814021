import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import classes from './Navbar.module.css';
import Logo from '../../assets/Logo.svg';
import { Switch, Route, Link } from 'react-router-dom';
import LogoBlack from '../../assets/LogoBlack.svg';
import Ellips from '../../assets/Ellipse62.png';
import Button from '../Ui/Button';

function NavbarBox() {
  return (
    <div className={classes.mainNav}>
      <Container>
        <Navbar className={`${classes.navbar} container`} expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <Switch>
                <Route
                  path="/invest"
                  render={() => <img src={LogoBlack} alt="logo" />}
                />
                <Route
                  path="/blog"
                  render={() => <img src={LogoBlack} alt="logo" />}
                />
                <Route
                  path="/blog-details"
                  render={() => <img src={LogoBlack} alt="logo" />}
                />
                 <img src={LogoBlack} alt="logo" />
              </Switch>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </Container>

          <Navbar.Collapse id="basic-navbar-nav">
            <Navbar.Text>
              <ul className="navbar-nav ms-auto flex-nowrap">
                <li className="nav-item">
                  <a href="/" className={`nav-link m-2 menu-item nav-active`}>
                    <span className={classes.navLink}>Home</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    // target='_blank' 
                    rel="canonical"
                    href="/blog"
                    className={`nav-link m-2 menu-item nav-active`}
                  >
                    <span className={classes.navLink}>Blogs</span>
                  </a>
                </li>
                <li className={`nav-item ${classes.navItemBtn} ms-5`}>
                <Button className={classes.navBtn} wrapperClass={classes.navBtnWrapper}>
                  <a
                  // target='_blank'
                  rel="canonical"
                  href="/signup"
                    className={`nav-link menu-item nav-active`}
                  >
                    <span className={classes.navBtnText}>LOGIN/REGISTER</span>
                  </a>
                </Button>
                  {/* <img src={Ellips} className={classes.navEllips} alt="elips" />
                <button className={classes.navBtn}>
                  <Link
                    to="/signin"
                    className={`nav-link m-2 menu-item nav-active`}
                  >
                    <span className={classes.navBtnText}>LOGIN/REGISTER</span>
                  </Link>
                </button> */}
                </li>
              </ul>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>

    // <nav
    //   className={`navbar navbar-expand-lg navbar-light bg-light ${classes.navbarCustom}`}
    // >
    //   <div className="container">
    //     <div className="d-flex flex-grow-1">
    //       <span className="w-100 d-lg-none d-block"></span>
    //       <a className="navbar-brand d-none d-lg-inline-block" href="/">
    //         <Switch>
    //           <Route
    //             path="/invest"
    //             render={() => <img src={LogoBlack} alt="logo" />}
    //           />
    //           <Route
    //             path="/blog"
    //             render={() => <img src={LogoBlack} alt="logo" />}
    //           />
    //           <Route path="/" render={() => <img src={Logo} alt="logo" />} />
    //         </Switch>
    //       </a>

    //       <div className="w-100 text-right">
    //         <button
    //           className="navbar-toggler"
    //           type="button"
    //           data-bs-toggle="collapse"
    //           data-bs-target="#myNavbar"
    //         >
    //           <span className="navbar-toggler-icon"></span>
    //         </button>
    //       </div>
    //     </div>
    //     <div
    //       className="collapse navbar-collapse flex-grow-1 text-right"
    //       id="myNavbar"
    //     >
    //       <ul className="navbar-nav ms-auto flex-nowrap">
    //         <li className="nav-item">
    //           <Link to="/" className={`nav-link m-2 menu-item nav-active`}>
    //             <span className={classes.navLink}>Home</span>
    //           </Link>
    //         </li>
    //         <li className="nav-item ms-3">
    //           <Link to="/blog" className={`nav-link m-2 menu-item nav-active`}>
    //             <span className={classes.navLink}>Blogs</span>
    //           </Link>
    //         </li>
    //         <li className={`nav-item ${classes.navItemBtn} ms-5`}>
    //             <img src={Ellips} className={classes.navEllips} alt="elips"/>
    //             <button className={classes.navBtn}><Link to="/signin" className={`nav-link m-2 menu-item nav-active`}><span className={classes.navBtnText}>LOGIN/REGISTER</span></Link></button>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
  );
}

export default NavbarBox;
