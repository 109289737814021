import React, { useEffect, useState } from 'react';
import classes from './ActiveQ.module.css';
import ActiveQH from './ActiveQHeader/ActiveQH';
import ActiveQuantsCard from './ActiveQuantsCard/ActiveQuantsCard';

const ActiveQ = ({ myQuants }) => {
  return (
    <div className={classes.activeQ}>
      <ActiveQH />
      <div className={classes.aq_cards}>
        {myQuants.map((q, i) => {
          return <ActiveQuantsCard key={i} quants={q} />;
        })}
      </div>
    </div>
  );
};

export default ActiveQ;
