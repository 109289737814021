import React, { useEffect, useState } from 'react';
import classes from './ActiveQ.module.css';
import ActiveQuantsCard from './ActiveQuantsCard/ActiveQuantsCard';
import { Link } from 'react-router-dom';

const ActiveQ = ({ myQuants }) => {
    return (
        <div className={`${classes.activeQ} activeQ_wrap`}>
            <div className='common_quants_title_wrap'>
                <h3>Active Quants</h3>
                <Link to="/myquants" className="all_links_text">See All</Link>
            </div>
            <div className={`${classes.aq_cards} activeQ_inner`}>
                {myQuants.slice(0,4).map((q, i) => {
                    return <ActiveQuantsCard key={i} quants={q} />;
                })}
            </div>
        </div>
    );
};

export default ActiveQ;
