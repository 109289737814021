import React, { useState, useEffect } from 'react';
import amtBg from '../../../assets/referamtbg.svg';
import reffericon from '../../../assets/referIcon.svg';


const Refer = () => {
  useEffect(() => {
    var useid = JSON.parse(localStorage.getItem('MF_user')).id;
    const requestOptions = {
      method: 'POST',
      mode: "cors",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: useid })
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/referralReward`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setCash(json)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const [cash, setCash] = useState([]);
  var referralCode = JSON.parse(localStorage.getItem('MF_user')).referralCode;
  return (
    <div style={{ width: '100%', justifyContent: 'center' }}>
      <div style={{ backgroundImage: 'url('.concat(amtBg, ')'), height: '108px', width: '552px', backgroundSize: '552px 108px', textAlign: 'left', margin: '15px', display: 'flex' }}>
        <div style={{ width: '150px', paddingLeft: '15px' }}>
          <h5>MF cash balance</h5>
          <h2>&#8377; {cash.availableBalance}</h2>
        </div>
        <div style={{ width: '150px', paddingLeft: '15px' }}>
          <h5>Unclaimed MF cash</h5>
          <h2>&#8377; {cash.recivedBalance}</h2>
        </div>
      </div>
      <div style={{ margin: "15px", height: '228px', width: '552px', backgroundColor: '#5C45CC' }}>
        <div style={{ display: 'flex', padding: '10px' }}>
          <img src={reffericon} alt='logo' />
          <div style={{ textAlign: 'left' }}>
            <h5>Refer a Friend</h5>
            <p>Invite a friend and get ₹ 100 for every user who makes the first investment</p>
          </div>
        </div>
        <div style={{textAlign:'left', paddingLeft:'48px'}}>
          <h6>Share your code</h6>
          <div style={{width:'292px', height:'50px', backgroundColor:'white', color:'#5C45CC', display:'grid', textAlign:'center', alignContent:'space-evenly'}}><b>{referralCode}</b></div>
        </div>
      </div>
      <div style={{padding:'54px', textAlign:'left'}}>
        <p><b>How It Works?</b></p>
        <p>Share the referral code with your friends.</p>
        <p>Your friend signs up with the referral code and makes the first investment through us.</p>
        <p>You get ₹ 100 referal payout for every user. There's no limit referrals.</p>
        <p>Inspire your friends to build a portfolio, one step at a time.</p>
      </div>
    </div>
  );
};

export default Refer;
