import React, { Fragment } from 'react';
import classes from './home.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, Container, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import step1 from '../../assets/home/step1.svg';
import step2 from '../../assets/home/step2.svg';
import step3 from '../../assets/home/step3.svg';
import processVector from '../../assets/home/processVector.svg';
import ScrollAnimation from 'react-animate-on-scroll';
const easySteps = [
  {
    img: './static/images/begin-1.png',
    alt:'Step 1- Signup for free',
    title: 'Step 1',
    content: 'Sign up for a free account on MoneyFactory',
    effect: 'bounceInLeft'
  },
  {
    img: step2,
    alt:' Link you demat account',
    title: 'Step 2 - Link you demat account',
    content: 'Link your existing Demat account/broker',
    effect: 'flipInX'
  },
  {
    img: step3,
    alt:'Subscribe to our strategies',
    title: 'Step 3 - Subscribe to our strategies',
    content: 'Subscribe to our recommended strategies',
    effect: 'bounceInRight'
  }
];

function OurProcess() {
  return (
    <div className={classes.process_container}>
      <Container>
        <ScrollAnimation duration={2} animateIn='fadeIn'>
        <h2>
          BEGIN IN
          <span className={classes.subHeading}> 3 EASY STEPS</span>
        </h2>
        </ScrollAnimation>
        <Row className={`justify-content-center`}>
          {easySteps.map((x, k) => {
            return (
              <Fragment key={k + 1}>
                <Col md={3} lg={3} xs={12} sm={12}>
                  <Card className={classes.processCard}>
                    <Card.Body>
                      <Card.Text>
                        <ScrollAnimation animateIn={x.effect}>
                          <img src={x.img} className={classes.threeStepsImg} alt={x.alt} />
                        </ScrollAnimation>
                        <div className={classes.processTitle}>{x.title}</div>
                        <div className={classes.processContent}>
                          {x.content}
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                {k !== easySteps.length - 1 ? (
                  <Col md={1} lg={1} xs={12} sm={1} className={classes.hideArrow}>
                    <div className={classes.processVector}>
                      {' '}
                      <img src={processVector} alt="process vector" />
                    </div>
                  </Col>
                ) : null}
              </Fragment>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default OurProcess;
