import React from 'react';
import classes from './BrokerCard.module.css';
import check from '../../../../assets/fi-rr-check.svg';
import _5paisa from '../../../../assets/_5paisa.svg';
import zerodha from '../../../../assets/_zerodha.svg';
import angleBroking from '../../../../assets/_angleBroking.svg';
import fyres from '../../../../assets/_fyres.svg';
import icici from '../../../../assets/_icici.svg';
import kotak from '../../../../assets/_kotak.svg';
import motilal from '../../../../assets/_motilal.svg';
import upstox from '../../../../assets/_upstox.svg';

import _5PaisaBrokerDetails from '../BrokerDetails/5paisa/BrokerDetails';
import AngelBrokerDetails from '../BrokerDetails/AngelOne/AngelBrokerDetails';
import FyersBrokerDetails from '../BrokerDetails/Fyers/FyersBrokerDetails';

const BrokerCard = ({ i, title, isSelected, details }) => {
    var id = JSON.parse(localStorage.getItem('MF_user')).id;
    const logos = [
        _5paisa,
        angleBroking,
        zerodha,
        fyres,
        icici,
        kotak,
        motilal,
        upstox
    ];
    return (
        <div className={classes.card}>
            <div className={classes.start}>
                <div className={classes.icon}>
                    <img src={logos[i]} alt="" />
                </div>
                <div className={classes.title}>{title}</div>
            </div>
            {i === 0 ? (
                isSelected ? (
                    <div className={classes.selected}>
                        <img src={check} alt="" />
                    </div>
                ) : (
                    <_5PaisaBrokerDetails details={details} />
                )
            ) : i == 1 ? (
                isSelected ? (
                    <div className={classes.selected}>
                        <img src={check} alt="" />
                    </div>
                ) : (
                    <AngelBrokerDetails />
                )
            ) : i == 3 ? (

                <div>
                    <p className={classes.buttons}><button className={classes.save}> <a href={`https://api.fyers.in/api/v2/generate-authcode?client_id=PVMRE9IYLC-102&redirect_uri=${process.env.REACT_APP_BACKEND_BASE_URL}/api/fyersbroker/redirectUrl&response_type=code&state=${id}`} target="_blank" rel="noreferrer"> Authorize </a> </button></p>
                    <img src={check} alt="" />
                </div>

            ) : (
                <></>
            )}
        </div>
    );
};

export default BrokerCard;
