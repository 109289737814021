import React from 'react';
import AdminSubscriptions from '../../components/Admin/ManageSubscriptions/ManageSubscriptions';
import classes from './ManageSubscriptions.module.css';

const ManageSubscriptions = () => {
  return (
    <div>
      <AdminSubscriptions />
    </div>
  );
};

export default ManageSubscriptions;
