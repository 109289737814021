import React, { useState, useEffect } from 'react';
import classes from './AddBroker.module.css';
import BrokerCard from './BrokerCard/BrokerCard';
import swal from 'sweetalert';

const AddBroker = () => {
    const [userBrokers, setUserBrokers] = useState({});
    const [loading, setLoading] = useState(true);

    const brokers = [
        {
            //   src: 'heh',
            title: '5Paisa',
            details:'_5paisa',
            isSelected: false
        },
        {
            title: 'Angel One',
            details:'angelOne',
            isSelected: false
        },
        {
            title: 'Zerodha',
            details:'zerodha',
            isSelected: false
        },
        {
            title: 'Fyers',
            details:'fyers',
            isSelected: false
        },
        {
            title: 'ICICI Direct',
            details:'icici',
            isSelected: false
        },
        {
            title: 'Kotak Securities',
            details:'kotak',
            isSelected: false
        },
        {
            title: 'Upstox',
            details:'upstock',
            isSelected: false
        }
    ];

    useEffect(() => {
        var id = JSON.parse(localStorage.getItem('MF_user')).id;

        const trans = {
            userId: id
        };

        const requestOptions = {
            method: 'POST',
            mode: "cors",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(trans)
        };

        // fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/fyersbroker/addbroker/fyers`, requestOptions)
        //     .then((res) => {
        //         if (!res.ok) {
        //             // get error message from body or default to response status
        //             const error = 'Error: Please verify your Fyers user and password pin';
        //             return Promise.reject(error);
        //         }
        //         return res.json();
        //     })
        //     .then((res) => {
        //         swal({
        //             title: 'Fyers login is successful for the day',
        //             icon: 'success',
        //             timer: 300,
        //         })
        //         setLink(res.url);
        //     });



        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/${id}`)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else console.log(res);
            })
            .then((data) => {
                if (data.profile.broker_accounts) {
                    setUserBrokers(data.profile.broker_accounts);
                }
                setLoading(false)
            });
    }, []);

    return (
        <div className={classes.right_secn}>
            <div className={classes.title}> Add New Broker</div>
            {brokers.map((b, i) => {
                return (
                    <BrokerCard
                        key={i}
                        i={i}
                        title={b.title}
                        isSelected={loading}
                        details={userBrokers[b.details] ||{}}
                    />
                );
            })}
        </div>
    );
};

export default AddBroker;
