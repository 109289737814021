import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'ap-south-1_h9bs65SqE',
    ClientId: '3tv43ei6fm8q1n6hv9fmghd49e',
    authenticationFlowType: 'CUSTOM_AUTH'
};

const userPool = new CognitoUserPool(poolData);

export default userPool;