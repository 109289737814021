import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import { BlogStyle } from './blog.style';

function Cards(props) {
  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    setSearch(e.target.value);
  }

  const handleClick = () => {
    props.filterBlogs(search);
  }
  
  return (
    <Box>
      <TextField
        sx={BlogStyle.inputSearch}
        label="Search here"
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={BlogStyle.inputAdronment}>
              <IconButton onClick={handleClick} type="submit" aria-label="search">
                <SearchIcon sx={BlogStyle.inputSearchIcon} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

export default Cards;
