import blogBanner from '../../assets/Rectangle15.png';

export const BlogStyle = {
  inputSearch: {
    
    width: '100% !important',
    maxWidth: '437px !important',
    '.MuiInputBase-input': {
      background: '#FFFFFF',
      borderRadius: '8px',
      borderColor: 'white !important'
    },

    '.MuiInputBase-root': {
      background: '#FFFFFF',
      borderRadius: '8px',
      borderColor: 'white !important',
      pr: '0 !important',
      height: '55px'
    }

    // '.MuiInputBase-input': {
    //   width: '437px !important',
    //   height: '55px',
    //   background: '#FFFFFF',
    //   borderRadius: '8px'
    // }
  },

  inputAdronment: {
    '.MuiInputAdornment-root': {
      background: '#FFFFFF',
      borderRadius: '8px',
      height: '55px !important'
    }
  },

  inputSearchIcon: {
    width: '45px !important',
    height: '45px !important',
    background: '#00D37F',
    borderRadius: '8px',
    color: 'white'
  },

  banner: {
    width: '100%',
    position: 'relative',
    height: '550px',
    background: `url(${blogBanner}) no-repeat, #FFFFFF`,
    backgroundSize : 'cover',
    borderRadius: '10px',
    mt: 5
  },

  subBanner: {
    background:
      'linear-gradient(360deg, rgba(5, 26, 28, 0.87) 0%, rgba(8, 40, 44, 0) 107.48%)',
    borderRadius: '0px 0px 10px 10px',
    width: '100%',
    height: '432px',
    bottom: 0,
    left: '0 !important',
    position: 'absolute'
  },

  bannerTextWrapper: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    textAlign: 'left',
    pl: 3,
    pb: 3
  },

  btnBanner: {
    width: 'auto',
    height: '35px',
    background: '#CEFD1E',
    borderRadius: '17.5px'
  },

  subBtnBanner: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#191919'
  },

  bannerDate: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '27px',
    color: '#FFFFFF',
    opacity: 0.8,
    pt: 2
  },

  bannerText: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '140%',
    /* or 50px */
    pt: 1,
    color: '#FFFFFF',
    '@media (max-width: 768px)': {
      fontSize: '20px'
    }
  },

  bannerReadMore: {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '25px',
    /* identical to box height */
    pt: 1,
    position: 'relative',
    color: '#00D37F'
  },

  bannerReadMoreBtn: {
    pl: '5px',
    pb: 1,
    ml: '12px',
    position: 'absolute',
    top: 12,
    borderRadius: '50%',
    display: 'inline-block',
    height: '22px',
    width: '22px',
    border: '1px solid #00D37F !important'
  }
};
