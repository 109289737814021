import React from 'react';
import classes from './BillingDetails.module.css';
import BillingCard from './BilingCard/BillingCard';
import { colors } from '@mui/material';

const BillingDetails = () => {
  const pricing = [
    {
      title: 'Starter',
      price: '999',
      recommendedCap: '1 to 2.5 Lakhs',
      performanceRout: 'Upto 2.5 Lakhs - Zero',
      color: 'rgba(206, 253, 30, 1)'
    },
    {
      title: 'Growth',
      price: '2999',
      recommendedCap: '2.5 to 4.99 Lakhs',
      performanceRout: '2.5 to 5 Lakhs - 5%',
      color: 'rgba(0, 211, 127, 1)'
    },
    {
      title: 'Pro',
      price: '4999',
      recommendedCap: '5 Lakhs and Above',
      performanceRout: '5 Lakhs above Flat 10%',
      color: 'rgba(252, 176, 23, 1)'
    }
  ];

  return (
    <div className={classes.right_secn}>
      <div className={classes.title}>Pricing</div>
      <div className={classes.card}>
        <BillingCard
          title={pricing[0].title}
          price={pricing[0].price}
          color={pricing[0].color}
          recommendedCap={pricing[0].recommendedCap}
          performanceRout={pricing[0].performanceRout}
        />
        <BillingCard
          title={pricing[1].title}
          price={pricing[1].price}
          color={pricing[1].color}
          recommendedCap={pricing[1].recommendedCap}
          performanceRout={pricing[1].performanceRout}
        />
        <BillingCard
          title={pricing[2].title}
          price={pricing[2].price}
          color={pricing[2].color}
          recommendedCap={pricing[2].recommendedCap}
          performanceRout={pricing[2].performanceRout}
        />
        {/* <BillingCard />
        <BillingCard /> */}
      </div>
    </div>
  );
};

export default BillingDetails;
