import React, { useEffect, useState } from 'react';
import ABCard from '../../components/MarketPlace/ABCard/ABCard';
import Cashback from '../../components/MarketPlace/Cashback/Cashback';
import PVCard from '../../components/MarketPlace/PVCard/PVCard';
import classes from './Welcome.module.css';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar';
import ActiveQ from '../../components/MarketPlace/ActiveQ/ActiveQ';
import NewDeployedBar from '../../components/MarketPlace/NewDeployedBar/NewDeployedBar';
import welcomeDashboard from '../../assets/welcomeDashboard.svg';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import swal from 'sweetalert';
import {
  DashboardPageOne, DashboardPageTwo, DashboardPageThree, DashboardPageFour
} from "./pages";

// for now static data

function WelcomePage() {
  let cancel = false;
  const [name, setName] = useState('Test');
  const [card, setcard] = useState(() => new Set());
  const [PageCount, setPageCount] = useState(1);
  const [categories, setCategories] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [data, setData] = useState([]);
  const[progressValue, setProgressValue] = useState(50)

  const calc = (qty, ltp, price) => {
    return Math.round(
      ((Math.round(ltp * Math.round(qty)) * 100) / 100 - price * qty) * 100
    );
  };
  const calcTotalPnl = (quants) => {
    let total = 0;
    quants.stocksignals.map((ele) => {
      total += ele?.pldata?.pl;
    });

    return isNaN(total) ? 0 : Number(total).toFixed(2);
  };


  useEffect(() => {
    if (cancel) return;
    setName(JSON.parse(localStorage.getItem('MF_user'))?.name);
    const userId = JSON.parse(localStorage.getItem('MF_user'))?.id;

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/${userId}`)
      .then((res) => {
        if (!res.ok) {
          return Promise.reject('error');
        }
        return res.json();
      })
      .then((res) => {
        res.profile.broker_accounts.forEach((element) => {
          // if (element.brokerId === 1) setfivepaise(true);
          // if (element.brokerId === 2) setangelone(true);
          // let newcard = card;
          // newcard.push(element.brokerId);
          // setcard(newcard);
          setcard((prev) => new Set(prev).add(element.brokerId));
        });

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/categories`, {
          mode: 'cors',
      })
          .then((res) => {
              return res.json();
          })
          .then((res) => {
              setCategories(res);
          })

          fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/quants`, {
            mode: 'cors'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                setData(res);
            })

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/portfolios`, {
              mode: 'cors'
          })
              .then((res) => {
                  return res.json();
              })
              .then((res) => {
                  setPortfolioData(res);
              })

      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      cancel = true;
    };
  }, []);

  const nextPage = () => {
    setPageCount(PageCount + 1);
    setProgressValue(progressValue + 50);
  };

  const backPage = () => {
    setPageCount(PageCount - 1);
    setProgressValue(progressValue - 50);
  }

  const discoverData = categories.filter(x => x.name === 'NIFTY 50')

  return (
      <Sidebar>
        <ProgressBar className={classes.dashboardProgress} variant="success" now={progressValue} key={1} />
        <div className={classes.dashboard_container}>
        {PageCount === 1 ? <h4 className={classes.db_header}>Hello, {name}</h4> : ''}
          {/* {card.size === 0 ? ( */}
            <Container>
              {PageCount === 1 ? <DashboardPageOne nextPage={nextPage} portFolioValue={portfolioData} userId={JSON.parse(localStorage.getItem('MF_user'))?.id}/> : ""}
              {PageCount === 2 ? <DashboardPageTwo nextPage={nextPage} backPage={backPage} discoverData={discoverData} data={data}/> : ""}
               {/* {PageCount === 3 ? <DashboardPageThree nextPage={nextPage} backPage={backPage} myQuants={myQuants}/> : ""} */}
              {/* {PageCount === 4 ? <DashboardPageFour nextPage={nextPage} backPage={backPage} /> : ""} */}
            </Container>
            {/* ): ''} */}
        </div>
      </Sidebar>
  );
}

export default WelcomePage;
