import React from 'react';
import classes from './Homepage.module.css';
import NavbarHome from '../../../components/Navbar/NavbarHome';
import Invest from '../../../components/Invest/Invest';
import DifferentWays from '../../../components/DifferentWays/DifferentWays';
import BuidingWealth from '../../../components/Home/BuidingWealth';
import OurProcess from '../../../components/Home/OurProcess';
import OurClients from '../../../components/Home/OurClients';
import Testimonial from '../../../components/Home/Testimonial';
import FAQs from '../../../components/Home/FAQs';
import SaveTime from '../../../components/Home/SaveTime';
import Footer from '../../../components/Footer/Footer';
import Seo from '../../../components/SEO'

function Homepage() {
  return (
    <div className={classes.homepage}>
      <Seo title="Investment Experts | AI assisted Investment Solutions - MoneyFactory" canonical={window.location.pathname} description="Grow your money safely with our investment expertise & AI assisted solutions. Save your time with our simulated, assisted and automatic investing strategies."/>
      <NavbarHome />
      <Invest />
      <DifferentWays />
      <BuidingWealth/>
      <OurProcess />
      <OurClients />
      <Testimonial />
      <FAQs />
      {/* <SaveTime/> */}
      <Footer joinWithUs={true} />
    </div>
  );
}

export default Homepage;
