import React from "react";
import {Helmet} from "react-helmet";
 
// class Application extends React.Component {
//   render () {
//     return (
//         <div className="application">
//             <Helmet>
//                 <meta charSet="utf-8" />
//                 <title>My Title</title>
//                 <link rel="canonical" href="http://mysite.com/example" />
//             </Helmet>
//             ...
//         </div>
//     );
//   }
// };

const Seo = (props) => {
    return(
        <Helmet 
            title = {props.title}
            meta = {[
                {
                    name : 'description',
                    content : props.description
                },
                {
                    name : 'keywords',
                    content : props.keywords
                },
                {
                    name : 'location',
                    content : props.location
                },
                {
                    name : 'og:type',
                    content : "website"
                },
                {
                    name : 'og:url',
                    content : props.canonical || window.location.origin + window.location.pathname  ||"https://www.moneyfactory.ai/"
                },
                {
                    name : 'og:title',
                    content : props.title
                },
                {
                    name : 'og:description',
                    content : props.description
                },
                {
                    name : 'og:image',
                    content : props.image
                },
               
            ]}
            // link = {[
            //     {
            //         rel : 'canonical',
            //         href : props.canonical ||  window.location.href
            //     }
            // ]}
        />
        // <Helmet>
        //     <title>{props.title}</title>
        //     <link rel="canonical" href={props.canonical} />
        // </Helmet>
    )
}

export default Seo;