import React from 'react';
import classes from './Navbar.module.css';
import Logo from '../../../../assets/Logo.svg';
import { Switch, Route, Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import Button from '../../../../components/Ui/Button';

function NavbarHome() {
  return (
    <div className={classes.navbarHome} id="home">
      <Container>
        <Navbar className={`${classes.navbar} container`} expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <Switch>
                <Route path="/" render={() => <img src={Logo} alt="logo" />} />
              </Switch>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className={classes.navToggleHome}
            />
          </Container>

          <Navbar.Collapse id="basic-navbar-nav">
            <Navbar.Text>
              <ul className="navbar-nav ms-auto flex-nowrap">
                <li className="nav-item">
                  <Link to="/" className={`nav-link m-2 menu-item nav-active`}>
                    <span className={classes.navLinkHome}>Home</span>
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <Link
                  to="/blog"
                  className={`nav-link m-2 menu-item nav-active`}
                >
                  <span className={classes.navLinkHome}>Blogs</span>
                </Link> */}
                  <a
                    href="/blog"
                    rel="canonical"
                    target="_blank"
                    className={`nav-link m-2 menu-item nav-active`}
                  >
                    <span className={classes.navLinkHome}>Blogs</span>
                  </a>
                </li>
                <li className={`nav-item ms-5 ${classes.LoginBtnNav}`}>
                  <Button
                    className={classes.navBtn}
                    wrapperClass={classes.navBtnWrapper}
                  >
                    <Link
                      to="/signup"
                      rel="canonical"
                      className={`nav-link menu-item nav-active`}
                    >
                      <span className={classes.navBtnText}>LOGIN/REGISTER</span>
                    </Link>
                  </Button>
                </li>
              </ul>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <hr />
      </Container>
    </div>
    // <nav className={classes.navbarHome}>
    //   <div className="container">
    //     <Switch>
    //       <Route
    //         path="/invest"
    //         render={() => <img src={LogoBlack} alt="logo" />}
    //       />
    //       <Route
    //         path="/blog"
    //         render={() => <img src={LogoBlack} alt="logo" />}
    //       />
    //       <Route path="/" render={() => <img src={Logo} alt="logo" />} />
    //     </Switch>
    //   </div>
    // </nav>
  );
}

export default NavbarHome;
