import React from 'react';
import classes from './Button.module.css';
import Ellips from '../../../assets/Ellipse62.svg';
import EllipsHover from '../../../assets/Ellipse62Hover.svg';
import EllipseWhiteHover from '../../../assets/EllipseWhiteHover.svg';
import { useState } from 'react';

function Button({ children, wrapperClass, btnClass, customProps }) {
  const [imgurl, setImgUrl] = useState(Ellips);
  return (
    <div className={wrapperClass} style={{minWidth:'fit-content'}}>
      <div
        className={customProps ? classes.CustomWhiteBtn : classes.CustomBtn}
        onMouseOver={() => customProps ? setImgUrl(EllipseWhiteHover) : setImgUrl(EllipsHover)}
        onMouseOut={() => setImgUrl(Ellips)}
      >
        <img src={imgurl} alt="elips" />
        <button className={btnClass}>{children}</button>
      </div>
    </div>
  );
}

export default Button;
