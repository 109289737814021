import React from 'react';
import classes from './Discover.module.css';
import Sidebar from '../Sidebar/Sidebar';
import Slider from '../../../assets/sliders.svg';
import Carousel from '../Carousel/Carousel';
import Filter from '../Filter/Filter.jsx'
import { useEffect, useState } from 'react';


function Discover() {
    window.scrollTo(0, 0);
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filterInterface, setFilterInterface] = useState({});
    const [filterchoice, setFilterchoice] = useState([{ SORTBY: '' }, { PRICE: '' }, { SUBCATEGORIES: [] }, { EXCHANGES: [] }, { FAVOURITE: '' }])

    const show_filter = () => {
        const filt = document.querySelector('.filter');
        document.body.style.height = "100vh";
        document.body.style.overflowX = "hidden";
        filt.style.transform = "translateX(0)";
    }

    const handleFilterchoice = (ele) => {
        setFilterchoice([...ele]);
    }

    useEffect(() => {
        window.addEventListener('load', () => {
            if (window.innerWidth > 1024) {
                document.body.style.background = "linear-gradient(to right,#0F0E0E 0%,#0F0E0E 250px,#1d1d1d 250px,#1d1d1d 100%)";
            } else {
                document.body.style.background = "#1d1d1d";
            }
        })


        window.addEventListener("resize", () => {
            if (window.innerWidth > 1024) {
                document.body.style.background = "linear-gradient(to right,#0F0E0E 0%,#0F0E0E 250px,#1d1d1d 250px,#1d1d1d 100%)";
            } else {
                document.body.style.background = "#1d1d1d";
            }
        })
    }, [])

    useEffect(() => {
        try {
            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/categories`, {
                mode: 'cors',
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    setCategories(res);
                })

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/quants`, {
                mode: 'cors'
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    setData(res);
                })

            Promise.all([fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/filters/SORTBY`), fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/subcategories`), fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/filters/EXCHANGES`)])
                .then(async (res) => Promise.all(res.map(async (data) => await data.json())))
                .then((res) => {
                    let obj = {};
                    res.forEach((ele) => {
                        if (Array.isArray(ele)) {
                            let filterType = "SUBCATEGORIES";
                            let metadata = ele;
                            let filterObj = {};
                            filterObj[filterType] = metadata;
                            Object.assign(obj, filterObj);
                        } else {
                            let filterType = ele.filter.filterType;
                            let metadata = ele.filter.metadata;
                            let filterObj = {};
                            filterObj[filterType] = metadata;
                            Object.assign(obj, filterObj);
                        }
                    })
                    setFilterInterface(obj);
                })
        } catch (err) {
            console.log(err);
        }
        // const carouselArray=document.querySelectorAll(".carouselContainer");
        // carouselArray.forEach((carousel,index)=>{
        //   let max_height=carousel.getBoundingClientRect().height;
        //   carousel.style.maxHeight=max_height+"px";
        //   document.querySelectorAll('.leftIcon')[index].style.pointerEvents="none";
        //   document.querySelectorAll('.leftIcon')[index].style.fill="grey";
        // })


    }, []);

    useEffect(() => {
        const temp = filterchoice.map((ele) => {
            for (let key in ele) {
                if (Array.isArray(ele[key])) {
                    return {
                        "key": key,
                        "values": [...ele[key]]
                    }
                } else {
                    if (ele[key]) {
                        return {
                            "key": key,
                            "values": [ele[key]]
                        }
                    } else {
                        return {
                            "key": key,
                            "values": []
                        }
                    }
                }
            }
        })
        const options = {
            mapFilters: temp
        }

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(options)
        }

        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/discover/quants`, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    // get error message from body or default to response status
                    const error = 'not subscribed';
                    return Promise.reject(error);
                }
                return res.json();
            })
            .catch((err) => {
                console.log(err);
            })
    }, [filterchoice])

    return (
        <>
            {/* <div className={classes.market}> */}
            <Sidebar>
                <Filter filterInterface={filterInterface} handleFilterchoice={handleFilterchoice} filterchoice={filterchoice} />
                <div className={classes.discover}>
                    <div className={classes.body}>
                        <div className={classes.banner}>
                            <h1>Choose Your Mode of Investing.</h1>
                        </div>
                        <div className={classes.quant}>
                            <div className={classes.heading}>
                                <h4>Discovery</h4>
                                <button onClick={() => { show_filter() }}><img src={Slider} alt="Filter" />Filter</button>
                            </div>
                            {
                                categories.map((ele, ind) => (
                                    <Carousel title={ele.name} id={ele._id} data={data} ind={ind} key={ele._id} />
                                )
                                )
                            }

                        </div>
                    </div>
                </div>
                <div className={`overlay ${classes.overlay}`}></div>
            </Sidebar>
            {/* </div> */}
        </>
    )
}

export default Discover