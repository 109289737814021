import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScrollAnimation from 'react-animate-on-scroll';
import { motion } from 'framer-motion';
import { Container } from 'react-bootstrap';
import classes from './Offer.module.css';
import OfferBannerImg from '../assets/offerBanner.svg';
import appStore from '../assets/appStore.svg';
import playStore from '../assets/playStore.svg';
import vectorImg1 from '../assets/vectorImg1.svg';
import vectorImg2 from '../assets/vectorImg2.svg';
import vectorImg3 from '../assets/vectorImg3.svg';
import Siat from './Siat/Siat';
function Offers() {
  return (
    <Container fluid>
      <div className={classes.rightFluid}>
        <div className={classes.invest_container}>
          <h1>Exclusive offer</h1>
          <div className={classes.subHeadingContainer}>
            <p>
              Each client you bring in, will earn you an additional Rs 500.
              Launch valid till 31 Dec 2022.
            </p>
          </div>
          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={`${classes.mainImg}`}>
                <ScrollAnimation animateIn="flipInX">
                  <motion.nav
                    whileHover={{ scale: 1.0 }}
                    whileTap={{ scale: 0.8 }}
                  >
                    <img
                      src={OfferBannerImg}
                      alt="INVEST LIKE THE TOP 1% with Moneyfactory"
                      width="100%"
                    />
                  </motion.nav>
                </ScrollAnimation>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <div className={classes.investText}>
                <ScrollAnimation animateIn="bounceInLeft">
                  <p>Download the app today to get started.</p>
                </ScrollAnimation>
                <ScrollAnimation duration={3} animateIn="fadeIn">
                  <div className={classes.appLinksButton}>
                    <img src={appStore} alt="APP STORE" width="100%" />
                    <img src={playStore} alt="PLAY STOREs" width="100%" />
                  </div>
                </ScrollAnimation>
              </div>
              <Siat />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default Offers;
