import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import classes from './ChangeProfile.module.css';
import swal from 'sweetalert';

import Dropzone, { useDropzone } from 'react-dropzone';

const contentStyle = {
  background: '#1d1d1d',
  color: 'white',
  maxWidth: '60vw',
  width: '100%'
};
const overlayStyle = { background: 'rgba(0,0,0,0.5)' };

const ChangeProfile = ({ img, setImg }) => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': []
      },
      onDrop: (acceptedFiles) => {
        setImg(acceptedFiles[0]);
      }
    });

  const handleDrop = (acceptedFiles) => {
    setImg(acceptedFiles[0]);
  };
  const [isUpload, setisUpload] = useState(false);
  function onImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      let i = e.target.files[0];
      // setImg(URL.createObjectURL(i));
      setImg(i);
    }
  }

  function uploadImage() {
    setisUpload(true);
    var data = new FormData();
    data.append('file', img);
    data.append('userId', JSON.parse(localStorage.getItem('MF_user')).id);
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/uploadImages/user/upload/avatar`,
      {
        method: 'PUT',
        body: data
      }
    )
      .then((res) => {
        if (!res.ok) {
          // get error message from body or default to response status
          const error = 'There was an issue updating profile picture';

          return Promise.reject(error);
        }
        return res.json();
      })
      .then((json) => {
        swal({
          title: 'Profile picture updated',
          text: 'New profile picture is added',
          icon: 'success',
          timer: 3000
        });
      })
      .catch((err) => {
        swal({
          title: 'Error',
          text: err,
          icon: 'error',
          timer: 2000
        });
      });
  }

  function clear() {
    setImg();
  }

  return isUpload ? (
    <div className={classes.change}>Change Profile Photo</div>
  ) : (
    <Popup
      trigger={<div className={classes.change}>Change Profile Photo</div>}
      modal
      contentStyle={contentStyle}
    >
      {(close) => (
        <div className={classes.modal}>
          <a className={classes.close} onClick={close}>
            <span> &times; </span>
          </a>
          <div className={classes.header}> Change Profile Photo </div>
          <div className={classes.content}>
            {img !== undefined ? (
              <img src={URL.createObjectURL(img)} alt="" />
            ) : (
              // <img src={URL.createObjectURL(img)} alt="" />
              <div>
                {/* <Dropzone
                  // onDrop={handleDrop}
                  accept={'image/png'}
                  // , image/png, image/gif, image/bmp"
                  minSize={1024}
                  maxSize={3072000}
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject
                  }) => {
                    // const additionalClass = isDragAccept
                    //   ? 'accept'
                    //   : isDragReject
                    //   ? 'reject'
                    //   : '';

                    return (
                      // {...getRootProps({
                      //   className: classes.dropzone
                      //   // className: `dropzone ${additionalClass}`
                      // })} */}
                <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  {/* <span>{isDragActive ? '📂' : '📁'}</span> */}
                  <p>Drag 'n' drop image, or click to select file</p>
                </div>
                {/* );
                  }}
                </Dropzone> */}
              </div>
            )}
          </div>
          <div className={classes.actions}>
            {/* <label className={classes.cp_button}>
              Choose a file
              <input
                // className={classes.cp_button}
                type="file"
                name="myImage"
                onChange={onImageChange}
              />
            </label> */}
            <button className={classes.cp_button} onClick={clear}>
              Clear image
            </button>
            <button className={classes.cp_button} onClick={uploadImage}>
              Upload picture
            </button>
            {/* <button
              className={classes.button}
              onClick={() => {
                close();
              }}
            >
              close modal
            </button> */}
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ChangeProfile;
