import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { NavLink } from 'react-router-dom';
import SidebarItem from '../SidebarItem/SidebarItem';
import Discovery from '../../../assets/Discover.svg';
import Dashboard from '../../../assets/Dashboard.svg';
import Quant from '../../../assets/Myquants.svg';
import Setting from '../../../assets/setting.svg';
import classes from './Sidebar.module.css';
import Logo from '../../../assets/Logo.svg';
import Community from '../../../assets/Community.svg';
import Navbar from '../Navbar/Navbar';
import Account from '../../../assets/my_account.svg'
import Subscription from '../../../assets/subscription.svg'
import "./sidebar.css"

function Sidebar({ children }) {
  const history = useHistory();
  const [user, setUser] = useState("");

  useEffect(() => {
    if (localStorage.getItem("MF_user")) {
      setUser(JSON.parse(localStorage.getItem("MF_user")));
    } else {
      swal({
        title: 'Please login to your account to continue!',
        text: 'you will be redirected to Sign in page in 3 seconds',
        icon: 'warning',
        timer: 3000
      }).then(() => {
        setTimeout(() => {
          history.push('/signin');
        }, 750);
      });
    }
  }, [])

  return (<>
    {user ? (
      <div className={`${classes.sidebar} sidebar`}>
        <div className={`sidebar_nav`}>
          <div className={classes.logo}>
            <img src={Logo} alt="logo" />
          </div>
          <div>
            <NavLink activeClassName='active' to="/dashboard"><SidebarItem Icon={Dashboard} category={`Dashboard`} /></NavLink>
          </div>
          <div>
            <NavLink activeClassName='active' to="/discover"><SidebarItem Icon={Discovery} category={`Discover`} /></NavLink>
          </div>
          <div>
            <NavLink activeClassName='active' to="/myquants"><SidebarItem Icon={Quant} category={`My Quants`} /></NavLink>
          </div>
          <div>
            <NavLink activeClassName='active' to="/profile"><SidebarItem Icon={Account} category={`My Account`} /></NavLink>
          </div>
          <div>
            <NavLink activeClassName='active' to="/setup"><SidebarItem Icon={Subscription} category={`My Plans`} /></NavLink>
          </div>
          <div>
            <NavLink activeClassName='active' to="/community"><SidebarItem Icon={Community} category={`Community`} /> </NavLink>
          </div>
        </div>
        <div>
          <Navbar />
          {children}</div>
      </div>
    ) : ""}</>

  );
}

export default Sidebar;
