import React, { useState } from 'react';
import classes from './home.module.css';
import MBgrad from '../MBgrad/MBgrad';
import IMRE from '../IMRE/IMRE';
import Siat from '../SIAT/Siat';
import Eibp from '../EIBP/Eibp';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { HomeStyle } from './home.style';
import wealthBlock from '../../assets/home/wealthBlock.svg';
import yellowRectangle from '../../assets/home/yellowRectangle.svg';
import greenRectangle from '../../assets/home/greenRectangle.svg';
import { Box, Slider, Tab, Tabs, Typography } from '@mui/material';
import Button from '../Ui/Button';
import ScrollAnimation from 'react-animate-on-scroll';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function Invest() {
  const [selectedYear, setSelectedYear] = useState(3);
  const [investAmount, setInvestAmount] = useState(100000);
  const [investRate, setInvestRate] = useState(40);

  const onKeyDown = (e) => {
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }
  };

  const checkCondition = (e, type) => {
    if (type === 'rate') {
      if (e.target.value < 10) {
        setInvestRate(10);
      }
      if (e.target.value > 80) {
        setInvestRate(80);
      }
    }

    if (type === 'amount') {
      if (e.target.value < 50000) {
        setInvestAmount(50000);
      }
      if (e.target.value > 1000000) {
        setInvestAmount(1000000);
      }
    }
  };

  const totalRetutn = (Number(investRate) / 100) * Number(investAmount);
  const totalValue = Number(investAmount) + totalRetutn * Number(selectedYear);

  const chartData = {
    labels: ['Total Value', 'Investment Amount'],
    datasets: [
      {
        label: '',
        data: [
          Number(investAmount) +
            (Number(investRate) / 100) *
              Number(investAmount) *
              Number(selectedYear),
          Number(investAmount)
        ],
        backgroundColor: ['#CEFD1E', '#00D37F'],
        borderColor: ['#CEFD1E', '#00D37F'],
        borderWidth: 1
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    responsive: false,
    maintainAspectRatio: false,
    cutout: 85
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.wealth_vector}></div>
      <div className={classes.wealth_container}>
        {/* <div className={classes.wealth_container}> */}
        <Container>
          <div className={classes.wealth_inner_container}>
            <Row>
              <Col md={6} lg={6} xs={12} sm={12}>
                <ScrollAnimation duration={2} animateIn="fadeIn">
                  <h2 className="mb-4">
                    START BUILDING
                    <br />
                    <span className={classes.subHeading}> WEALTH TODAY</span>
                  </h2>
                </ScrollAnimation>
                <p className="mt-4 mb-4">
                  Without spending years learning how the market works.
                </p>
                <div
                  className={
                    classes.imgWrapper + ' ' + classes.imgBuildingCenter
                  }
                >
                  <Doughnut
                    data={chartData}
                    options={options}
                    height={'234px'}
                  />
                  {/* <img src={wealthBlock} alt="wealth" /> */}
                </div>
                <Row className={classes.price}>
                  <Col md={6} lg={3} xs={6} sm={6}>
                    <span>
                      <img src={greenRectangle} alt="price" />
                      <span style={{ paddingLeft: '7px' }}>
                        ₹ {investAmount}
                      </span>
                    </span>
                  </Col>
                  <Col md={6} lg={3} xs={6} sm={6}>
                    <span>
                      <img src={yellowRectangle} alt="price" />
                      <span style={{ paddingLeft: '7px' }}>
                        ₹ {totalValue.toFixed(2)}
                      </span>
                    </span>
                  </Col>
                  <Col md={3} lg={3} xs={6} sm={6}></Col>
                  <Col md={3} lg={3} xs={6} sm={6}></Col>
                </Row>
              </Col>
              <Col
                md={6}
                lg={6}
                xs={12}
                sm={12}
                className={classes.wealthSlider}
              >
                {/* <Box sx={HomeStyle.tabWrapper}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  
                >
                  <Tab label="Type Here"/>
                  <Tab label="Type Here 1"/>
                </Tabs>
              </Box> */}
                {/* <TabPanel value={value} index={0}> */}
                <Row>
                  <Col className={classes.tabTitle}>Investment</Col>
                  {/* <Col className={classes.tabContent}>₹ | 3455</Col> */}
                  <Col className={classes.tabContent}>
                    <div className={classes.monthlyInvestWrapper}>
                      <span className={classes.monthlyValueWrapper}>
                        <span className={classes.monthlyCurrencyWrapper}>
                          <span className={classes.montlyCurreny}>₹</span>
                        </span>
                        <input
                          className={classes.monthlyInput}
                          max="1000000"
                          min="50000"
                          type="number"
                          value={investAmount}
                          id="MONTHLY_INVESTMENT"
                          placeholder="0"
                          onKeyDown={onKeyDown}
                          onChange={(e) => setInvestAmount(e.target.value)}
                          onBlur={(e) => checkCondition(e, 'amount')}
                          // onBlur={(e) => Number(e.target.value) < 50000 && Number(e.target.value) > 100000 ? setInvestAmount(100000) : null}
                        ></input>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Box width={'100%'} sx={{ mt: '34px' }}>
                  <Slider
                    sx={HomeStyle.sliderWrapper}
                    value={investAmount}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    min={50000}
                    max={1000000}
                    onChange={(e) => setInvestAmount(e.target.value)}
                  />
                </Box>
                {/* <ProgressBar now={40} variant="success"  className={classes.processTab}/> */}
                <Row className={classes.processSpace}>
                  <Col className={classes.tabTitle}>
                    Expected Return Rate (p.a.)
                  </Col>
                  {/* <Col className={classes.tabContent}>23%</Col> */}
                  <Col className={classes.tabContent}>
                    <div className={classes.monthlyInvestWrapper}>
                      <span className={classes.monthlyValueWrapper}>
                        <input
                          className={classes.RateInput}
                          max="80"
                          min="10"
                          type="number"
                          value={investRate}
                          id="Return_INVESTMENT"
                          placeholder="0"
                          onKeyDown={onKeyDown}
                          onChange={(e) => setInvestRate(e.target.value)}
                          onBlur={(e) => checkCondition(e, 'rate')}
                          // onBlur={(e) => Number(e.target.value) < 10 && Number(e.target.value) > 80 ? setInvestRate(40) : null}
                        ></input>
                        <span className={classes.inputPostValue}>%</span>
                      </span>
                    </div>
                  </Col>
                </Row>

                <Box width={'100%'} sx={{ mt: '34px' }}>
                  <Slider
                    sx={HomeStyle.sliderWrapper}
                    value={investRate}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    min={10}
                    max={80}
                    onChange={(e) => setInvestRate(e.target.value)}
                  />
                </Box>
                {/* <ProgressBar
                  now={60}
                  variant="success"
                  className={classes.processTab}
                /> */}
                <Row className={classes.processSpace}>
                  <Col className={classes.tabTitle}>Time Period</Col>

                  {/* <Col className={classes.tabContent}>10 Yr</Col> */}
                </Row>
                <Box>
                  <div className={classes.timePeriodWrapper}>
                    <div
                      className={`${classes.timePeriod} ${
                        selectedYear === 3 ? classes.active : ''
                      }`}
                      onClick={() => setSelectedYear(3)}
                    >
                      <span>3Y</span>
                    </div>
                    <div
                      className={`${classes.timePeriod} ${
                        selectedYear === 5 ? classes.active : ''
                      }`}
                      onClick={() => setSelectedYear(5)}
                    >
                      <span>5Y</span>
                    </div>
                    <div
                      className={`${classes.timePeriod} ${
                        selectedYear === 10 ? classes.active : ''
                      }`}
                      onClick={() => setSelectedYear(10)}
                    >
                      <span>10Y</span>
                    </div>
                  </div>
                </Box>
                {/* <ProgressBar
                  now={30}
                  variant="success"
                  className={classes.processTab}
                /> */}
                <Row className={`${classes.processSpace} ${classes.tabTitle}`}>
                  <Col>Investment Amount</Col>
                  <Col>Est. Returns</Col>
                  <Col>Total Value</Col>
                </Row>
                <Row className={classes.processPrice}>
                  <Col>₹ {investAmount}</Col>
                  <Col>₹ {(totalValue - investAmount).toFixed(2)}</Col>
                  <Col>₹ {totalValue.toFixed(2)}</Col>
                </Row>
                {/* </TabPanel> */}
                {/* <TabPanel value={value} index={1}>
                2
              </TabPanel> */}
                <div className={classes.wealthBrnWrap}>
                  <a href="/signup">
                    <Button
                      wrapperClass={classes.btnImg}
                      btnClass={classes.homeBtn}
                    >
                      {' '}
                      Invest Now
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Invest;
