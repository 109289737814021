import React, { useState, useEffect } from 'react';
import PlanCard from './PlanCard/PlanCard';
// import BillingCard from '../../ProfileSettings/BillingDetails/BilingCard/BillingCard';
import classes from './Plans.module.css';
import performance from '../../../assets/logo_perfomance.svg';

const Plans = () => {
  const pricingMonthly = [
    {
      id: 1,
      title: 'Starter',
      price: '99',
      exchange: 'Equity',
      color: 'rgba(206, 253, 30, 1)'
    },
    {
      id:2,
      title: 'Growth',
      price: '499',
      exchange: 'NSE/FNO',
      color: 'rgba(0, 211, 127, 1)'
    },
    {
      id:3,
      title: 'Pro',
      price: '1999',
      exchange: 'NSE/FNO/MCX',
      color: 'rgba(252, 176, 23, 1)'
    }
  ];

  const pricingQuaterly = [
    {
      id:4,
      title: 'Starter',
      price: '299',
      exchange: 'Equity',
      color: 'rgba(206, 253, 30, 1)'
    },
    {
      id:5,
      title: 'Growth',
      price: '1499',
      exchange: 'NSE/FNO',
      color: 'rgba(0, 211, 127, 1)'
    },
    {
      id:6,
      title: 'Pro',
      price: '5999',
      exchange: 'NSE/FNO/MCX',
      color: 'rgba(252, 176, 23, 1)'
    }
  ];

  const pricingAnnual = [
    {
      id:7,
      title: 'Starter',
      price: '1199',
      exchange: 'Equity',
      color: 'rgba(206, 253, 30, 1)'
    },
    {
      id:8,
      title: 'Growth',
      price: '5999',
      exchange: 'NSE/FNO',
      color: 'rgba(0, 211, 127, 1)'
    },
    {
      id:9,
      title: 'Pro',
      price: '23999',
      exchange: 'NSE/FNO/MCX',
      color: 'rgba(252, 176, 23, 1)'
    }
  ];

  const [type, setType] = useState('Quarterly');
  const [pricing, setpricing] = useState(pricingQuaterly);

  useEffect(() => {
    if (type === 'Monthly') {
      setpricing(pricingMonthly);
    } else if (type === 'Quarterly') {
      setpricing(pricingQuaterly);
    } else if (type === 'Annual') {
      setpricing(pricingAnnual);
    }
  }, [type]);

  function onChangeValue(event) {
    setType(event.target.value);
  }
  var style1 = {
    background: `#242424`,
    border: `1px solid #5a5a5a`,
    borderRadius: `4px`
  };

  var style2 = {
    background: `#00d37f`
  };
  var test = {
    title: 'Starter',
    price: '999',
    recommendedCap: '1 to 2.5 Lakhs',
    performanceRout: 'Upto 2.5 Lakhs - Zero',
    color: 'rgba(206, 253, 30, 1)'
  };

  return (
    <div className={classes.right_secn}>
      <div className={classes.title}>Subscription Plans</div>
      <div onChange={onChangeValue} className={classes.plantype}>
        <div
          className={classes.planradio}
          style={type === 'Monthly' ? style2 : style1}
        >
          <input
            type="radio"
            value="Monthly"
            name="type"
            checked={type === 'Monthly'}
          />{' '}
          Monthly
        </div>
        <div
          className={classes.planradio}
          style={type === 'Quarterly' ? style2 : style1}
        >
          <input
            type="radio"
            value="Quarterly"
            name="type"
            checked={type === 'Quarterly'}
          />{' '}
          Quarterly
        </div>
        <div
          className={classes.planradio}
          style={type === 'Annual' ? style2 : style1}
        >
          <input
            type="radio"
            value="Annual"
            name="type"
            checked={type === 'Annual'}
          />{' '}
          Annual
        </div>
      </div>
      <div className={classes.card}>
        {/* <BillingCard
          title={pricing[0].title}
          price={pricing[0].price}
          color={pricing[0].color}
          recommendedCap={pricing[0].recommendedCap}
          performanceRout={pricing[0].performanceRout}
        /> */}
        <PlanCard
          id={pricing[0].id}
          type={type}
          title={pricing[0].title}
          price={pricing[0].price}
          color={pricing[0].color}
          exchange={pricing[0].exchange}
        />
        <PlanCard
          id={pricing[1].id}
          type={type}
          title={pricing[1].title}
          price={pricing[1].price}
          color={pricing[1].color}
          exchange={pricing[1].exchange}
        />
        <PlanCard
          id={pricing[2].id}
          type={type}
          title={pricing[2].title}
          price={pricing[2].price}
          color={pricing[2].color}
          exchange={pricing[2].exchange}
        />
      </div>
      <div className={classes.title_perf}>Performance Fees</div>
      <div className={classes.perfom}>
        <div className={classes.sub_section}>
          <div className={classes.icon}>
            <img src={performance} alt="" />
          </div>
          <div className={classes.recommend}>
            <div className={classes.titlerec}>2.5 lacs</div>
            <div className={classes.value}>Nill</div>
          </div>
        </div>
        <div className={classes.sub_section}>
          <div className={classes.icon}>
            <img src={performance} alt="hehe" />
          </div>
          <div className={classes.recommend}>
            <div className={classes.titlerec}>2.5lacs - 5 Lacs</div>
            <div className={classes.value}>5%</div>
          </div>
        </div>

        <div className={classes.sub_section}>
          <div className={classes.icon}>
            <img src={performance} alt="hehe" />
          </div>
          <div className={classes.recommend}>
            <div className={classes.titlerec}>5 Lacs</div>
            <div className={classes.value}>10%</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
