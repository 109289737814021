import React, { useState, useEffect, useCallback } from 'react';
import SignImage from '../../assets/SignUp.png';
import { useHistory } from 'react-router-dom';
import classes from './SignIn.module.css';
import { BsLinkedin } from 'react-icons/bs';
import { BsGoogle } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import Seo from '../../components/SEO';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    }, '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const PTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    }, '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

function SignIn() {
    const history = useHistory();
    const initialValues = { email: '', password: '' };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    const handleOnClick = useCallback(() => history.push('/signup'), [history]);

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors.email = 'Email is required!';
        } else if (!regex.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        }
        if (!values.password) {
            errors.password = 'Password is required!';
        } else if (values.password.length < 6) {
            errors.password = 'Password must be more than 6 characters';
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formValues)
            };

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/login`, requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        return Promise.reject(res.text().then(text => {
                            setIsSubmit(false);
                            swal({
                                title: JSON.parse(text).message,
                                text: 'Please check the provided details and try again',
                                icon: 'warning'
                            });
                        }));
                    }

                    // functionality to be added

                    return res.json();
                })
                .then((res) => {
                    localStorage.setItem('MF_user', JSON.stringify(res));
                    swal({
                        title: 'User logged in successfully!',
                        text: 'you will be redirected to Dashboard page in 3 seconds',
                        icon: 'success',
                        timer: 3000
                    }).then(() => {
                        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/isusersubscried`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ userId: res.id })
                        })
                            .then((r) => {
                                r.json().then((re) => {
                                    if (re) {
                                        setTimeout(() => {
                                            history.push('/dashboard');
                                        }, 750);
                                    }
                                    else {
                                        setTimeout(() => {
                                            history.push('/setup');
                                        }, 750);
                                    }
                                })
                            });

                    });
                })
                .catch((err) => {
                    console.log(err);
                    if (err.includes('Invalid Password')) {
                        setIsSubmit(false);
                        swal({
                            title: 'User not registered',
                            text: 'email already exists',
                            icon: 'warning'
                        });
                    } else {
                        setIsSubmit(false);
                        swal({
                            title: 'User not registered',
                            text: 'Sorry for the inconvenience, try again in some time',
                            icon: 'warning'
                        });
                    }
                });
        }
    }, [formErrors, formValues, history, isSubmit]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={classes.signin}>
            <Seo title="" canonical={window.location.pathname} description="" />
            <div>
                <img src={SignImage} alt="" />
            </div>
            <div>
                <h2 className={classes.section_title}>Sign In</h2>
                <p>Welcome Back to MoneyFactory.</p>
                <p className={classes.social}>Continue with social</p>
                <div className={classes.combined_buttons}>
                    <div>
                        <button>
                            <FaFacebookF />
                        </button>
                    </div>
                    <div>
                        <button>
                            {' '}
                            <BsLinkedin />
                        </button>
                    </div>
                    <div>
                        <button>
                            <BsGoogle />
                        </button>
                    </div>
                </div>
                <div className={classes.form_container}>
                    <div>
                        <CssTextField
                            label="Email"
                            id="custom-css-outlined-input"
                            type="email"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            multiline={false}
                            className={classes.first_tf}
                            onChange={handleChange}
                            name="email"
                            value={formValues.email}
                        />
                        <p className={classes.errors}>{formErrors.email}</p>
                    </div>
                    <div>
                        <PTextField
                            id="outlined-password-input"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            className={classes.first_tf}
                            name="password"
                            onChange={handleChange}
                            value={formValues.password}
                        />
                        <p className={classes.errors}>{formErrors.password}</p>
                        <div className={classes.fp}>
                            <Link to='/forgotPassword'>Forgot Password?</Link>
                        </div>
                    </div>
                    <p>
                        By clicking Agree & Join, you agree to the MoneyFactory{' '}
                        <span>User Agreement, Privacy Policy,</span> and{' '}
                        <span>Cookie Policy.</span>
                    </p>
                </div>
                <div className={classes.bottom_buttons}>
                    <div>
                        <button onClick={handleSubmit}>Sign In</button>
                    </div>
                    <div>
                        <p>Don`t have an account?</p>
                        <button onClick={handleOnClick}>Sign Up</button>
                    </div>
                </div>

                <div className={classes.footer}>
                    <p>Copyright © 2022 MoneyFactory. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default SignIn;
