import React from 'react';
import classes from './ActiveQH.module.css';

function ActiveQH() {
  return (
    <div className={classes.aq_container}>
      <h4>Active Quants</h4>
      <div className={classes.aqog}>
        <div className={classes.aq_buttons}>
          <button className={classes.first_button}>Exit All</button>
          <button className={classes.second_button}>Overview</button>
        </div>
        <div className={classes.aq_buttons}>
          <button className={classes.third_button}>Moneyfactory</button>
          <button className={classes.fourth_button}>All</button>
        </div>
      </div>
    </div>
  );
}

export default ActiveQH;
