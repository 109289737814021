import React from 'react';
import Navbar from '../Navbar/Navbar';
import classes from './home.module.css';
import MBgrad from '../MBgrad/MBgrad';
import IMRE from '../IMRE/IMRE';
import Siat from '../SIAT/Siat';
import Eibp from '../EIBP/Eibp';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Accordion, Container, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import Ellips from '../../assets/Ellipse62.png';
import Button from '../Ui/Button';

function SaveMore() {
  return (
    <div className={classes.saveMore_container}>
      <Container>
        <div className={classes.saveMore_inner_container}>
          <Row className={`align-items-center`}>
            <Col md={6} lg={6} xs={12} sm={12}>
              <div className={classes.textSection}>
                <h2>
                  Save More Time & Make<br/> More <span>Money</span>
                </h2>
                <p>Without spending years learning how the market works</p>
              </div>
            </Col>
            <Col md={2} lg={4} xs={12} sm={12}></Col>
            <Col md={2} lg={2} xs={12} sm={12}>
              {/* <div className={classes.imgWrapper}>
                <img src={Ellips} alt="elips" className={classes.btnImg} />
                <button className={`button ${classes.homeBtn}`}>
                  Join With Us
                </button>
              </div> */}
              <div className={classes.saveMoreSecondContent}>
                <a href='/signup' style={{
                  textDecoration: 'none',
                }}>
                  <Button
                    wrapperClass={classes.btnImg}
                    btnClass={classes.homeBtn}
                    customProps={true}
                  >
                    {' '}
                    Join With Us
                  </Button>
                </a>
                </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default SaveMore;
