import React, { useEffect } from 'react';
import classes from './Carousel.module.css';
import QuantCard from '../QuantCard/QuantCard';
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';

function Carousel({ title, id, data, ind }) {
    let CarouselTrack;
    let totalCards;

    const groupCategories = (ele) => {
        for (let i = 0; i < ele.categories.length; i++) {
            if (ele.categories[i].categoryId._id === id) {
                return true;
            }
        }
    };

    const handleLoad = () => {
        document.querySelectorAll('.leftIcon')[ind].style.pointerEvents = "none";
        document.querySelectorAll('.leftIcon')[ind].style.fill = "grey";
    }
    useEffect(() => {
        handleLoad();

        return () => {

        }
    }, []);



    const findDifficultyColor = (ele) => {
        if (ele === "Low") {
            return "#4D9078";
        } else if (ele === "Medium") {
            return "#FCB017";
        } else {
            return "#C5283D";
        }
    }

    const moveToNextCard = (e) => {
        CarouselTrack = e.target.parentNode?.children[3]?.children[0]?.children[0]?.children[0];
        totalCards = Array.from(CarouselTrack?.children);
        let width = CarouselTrack.getBoundingClientRect().width;
        const cardWidth = totalCards[0].getBoundingClientRect().width;
        let gapBetweenCards = (window
            .getComputedStyle(CarouselTrack)
            .getPropertyValue('gap')
            .slice(0, -1) *
            width) /
            100;
        const skip = parseInt(width / (cardWidth + gapBetweenCards));
        let totalwidth = totalCards.length * (cardWidth + gapBetweenCards) - gapBetweenCards;
        const transformMatrix = window
            .getComputedStyle(CarouselTrack)
            .getPropertyValue('transform')
            .split(', ');
        const translateX = parseFloat(transformMatrix[4]);
        const newTranslateX = translateX - cardWidth * skip - skip * gapBetweenCards;
        if (Math.abs(newTranslateX) + width >= totalwidth) {
            CarouselTrack.style.transform = `translateX(${-(totalwidth - width)
                }px)`;
            document.querySelectorAll('.rightIcon')[ind].style.pointerEvents = "none";
            document.querySelectorAll('.rightIcon')[ind].style.fill = "grey";
        } else {
            CarouselTrack.style.transform = `translateX(${newTranslateX
                }px)`;
        }

        document.querySelectorAll('.leftIcon')[ind].style.pointerEvents = "all";
        document.querySelectorAll('.leftIcon')[ind].style.fill = "white";

    };

    const moveToPreviousCard = (e) => {
        CarouselTrack = e.target.parentNode?.children[3]?.children[0]?.children[0]?.children[0];
        totalCards = Array.from(CarouselTrack?.children);
        const cardWidth = totalCards[0].getBoundingClientRect().width;
        let width = CarouselTrack.getBoundingClientRect().width;
        let gapBetweenCards = (window
            .getComputedStyle(CarouselTrack)
            .getPropertyValue('gap')
            .slice(0, -1) *
            width) /
            100;
        const skip = parseInt(width / (cardWidth + gapBetweenCards));
        const transformMatrix = window
            .getComputedStyle(CarouselTrack)
            .getPropertyValue('transform')
            .split(', ');
        const translateX = parseFloat(transformMatrix[4]);
        const newTranslateX = translateX + cardWidth * skip + skip * gapBetweenCards;
        if (newTranslateX > -(0.1)) {
            CarouselTrack.style.transform = `translateX(${0
                }px)`;
            e.target.style.pointerEvents = "none";
            e.target.style.fill = "grey";
        } else {
            CarouselTrack.style.transform = `translateX(${translateX + cardWidth * skip + skip * gapBetweenCards
                }px)`;
        }

        document.querySelectorAll('.rightIcon')[ind].style.pointerEvents = "all";
        document.querySelectorAll('.rightIcon')[ind].style.fill = "white";

    };

    return (
        <div className={classes.carousel}>
            <div className={classes.carousel_heading}>
                <h4>{title}</h4>
                <a href="#">See all</a>
            </div>
            <FaChevronLeft className={`${classes.left} leftIcon`} size={20} onClick={moveToPreviousCard} />
            <FaChevronRight className={`${classes.right} rightIcon`} size={20} onClick={moveToNextCard} />
            <div className={`${classes.carouselContainer}`}>
                <div className="carouselContainer">
                    <div className={classes.visible}>
                        <div>
                            {
                                data.filter(groupCategories).map((ele) => (
                                    <QuantCard
                                        image={ele.imgUrl}
                                        title={ele.name}
                                        about={ele.description}
                                        difficulty={ele.risk}
                                        ROI={ele.price}
                                        color={findDifficultyColor(ele.risk)}
                                        quantId={ele._id}
                                        categoryId={id}
                                        data={data}
                                        categoryTitle={title}
                                        key={ele._id}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Carousel;
