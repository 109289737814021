// code very similar to signin page

import React, { useState, useEffect, useCallback, useRef } from 'react';
import SignImage from '../../assets/SignUp.svg';
import { useHistory } from 'react-router-dom';
import classes from './ForgotPassword.module.css';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import userPool from '../../components/Auth/UserPool';
import OtpInput from '../../components/OtpInput/OtpInput';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const PTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

function SignIn() {
    const history = useHistory();
    const [formValues, setFormValues] = useState({ phone: '', email: '' });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [formStatus, setFormStatus] = useState('initiate');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const getUser = () => {
        return new Promise((resolve, reject) => {
            if (formValues.phone) {
                fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/checkphone/${formValues.phone}`)
                    .then((res) => {
                        if (!res.ok) {
                            // get error message from body or default to response status
                            const error = 'User with the given email does not exist'
                            reject(error);
                        }
                        return res.json();
                    })
                    .then((res) => {
                        setFormValues({ email: res.user.email, phone: formValues.phone });
                        resolve({ email: res.user.email, phone: formValues.phone });
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            } else {
                fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/userphone/${formValues.email}`)
                    .then((res) => {
                        if (!res.ok) {
                            // get error message from body or default to response status
                            const error = 'User with the given email does not exist'
                            reject(error);
                        }
                        return res.json();
                    })
                    .then((res) => {
                        setFormValues({ email: formValues.email, phone: res.phone });
                        resolve({ email: formValues.email, phone: res.phone });
                    })
                    .catch((err) => {
                        console.log(err);
                    })

            }
        })

    }

    const sendCode = () => {
        getUser().then((res) => {
            fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/sendOTP`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(res)
            }).then((res) => {
                if (res.ok) { return res.json(); } else {
                    res.json().then((r) => { setFormErrors({ phone: r.msg }); })
                }
            }).then((res) => {
                setFormStatus("otp");
                setIsSubmit(false);
            });
        })
            .catch((err) => {
                setFormErrors({ email: err });
            })
    }

    const handlepassword = (e) => {
        setPassword(e.target.value);
    }

    const otpVerify = () => {
        let otpvalue = document.querySelector('#otp').value;
        if (otpvalue.length === 6) {
            let otp = parseInt(otpvalue);
            if (isNaN(otp)) {
                setFormErrors({ otp: 'It should contain only numbers' });
            } else {
                fetch(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/confirmOTP`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...formValues,
                        otp: otp.toString()
                    })
                }).then((res) => {
                    if (res.ok) { return res.json(); } else {
                        res.json().then((r) => { setFormErrors({ otp: r.msg }); })
                    }
                }).then((res) => {
                    const options = {
                        phone: formValues.phone,
                        password: password
                    }

                    const requestOptions = {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(options)
                    };

                    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/reset`, requestOptions)
                        .then((res) => {
                            if (!res.ok) {
                                // get error message from body or default to response status
                                const error = 'password not updated'
                                return Promise.reject(error);
                            }
                            return res.json();
                        })
                        .then((res) => {
                            setFormErrors({});
                            swal({
                                title: 'password changed successfully',
                                text: 'you will be redirected to Sign in page in 3 seconds',
                                icon: 'success',
                                timer: 3000
                            }).then(() => {
                                setTimeout(() => {
                                    history.push('/signin');
                                }, 750);
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                });
            }
        } else {
            setFormErrors({ otp: 'Enter 6 digit OTP' });
        }
    };

    const handleConfirmpassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const changePassword = () => {
        setFormErrors({});
        if (password !== confirmPassword) {
            let newerror = {}
            newerror['confirmPassword'] = "Confirm Password does not match with the given password"
            setFormErrors(newerror);
            return;
        } else if (password.length < 6) {
            let newerror = {};
            newerror['password'] = 'Password must be more than 6 characters';
            setFormErrors(newerror);
            return;
        }
        otpVerify();


    }

    const getInfo = (e) => {
        setIsSubmit(true);
        if (!Object.keys(validate(formValues)).length) {
            setFormErrors({});
            sendCode();

        } else {
            setFormErrors(validate(formValues));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // const handleOnClick = useCallback(() => history.push('/signin'), [history]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formStatus === 'initiate') {
            getInfo(e);
        }
        if (formStatus === 'otp') {
            changePassword();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const phoneregex =
            /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/;

        if (!values.email && !values.phone) {
            errors.phone = 'Enter Email or Phone number is required!';
            errors.email = 'Either Email or phone number is required!';
        } else if (values.email && !regex.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        } else if (values.phone && !phoneregex.test(values.phone)) {
            errors.phone = "Enter correct phone number";
        }

        return errors;
    };

    return (
        <div className={classes.forgotPassword}>
            <div>
                <img src={SignImage} alt="" />
            </div>
            <div>
                {formStatus === "initiate" && (<><div>
                    <h2>Forgot Password</h2>
                    <p>Please enter your email address or mobile number we’ll send OTP on email address or mobile number</p>
                    <div className={classes.form_container}>
                        <div>
                            <CssTextField
                                label="Email"
                                id="custom-css-outlined-input"
                                InputLabelProps={{
                                    style: { color: '#fff' }
                                }}
                                multiline={true}
                                className={classes.first_tf}
                                onChange={handleChange}
                                name="email"
                                value={formValues.email}
                            />
                            <p className={classes.errors}>{formErrors.email}</p>
                        </div>
                        <div className={classes.gap}>
                            Or
                        </div>
                        <div style={{ display: 'flex' }}>
                            <p style={{ alignSelf: 'center', marginBottom: '0px', paddingRight: '10px' }}>+91 </p>
                            <CssTextField
                                label="Mobile Number"
                                id="custom-css-outlined-input"
                                InputLabelProps={{
                                    style: { color: '#fff' }
                                }}
                                multiline={true}
                                className={classes.first_tf}
                                name="phone"
                                onChange={handleChange}
                                value={formValues.phone}
                            />
                            <p className={classes.errors}>{formErrors.phone}</p>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className={classes.bottom_buttons}>
                        <div>
                            <button onClick={handleSubmit}>Send OTP</button>
                        </div>
                        <div>
                            <p>Already have a password?<span className={classes.signin}><Link to="/signin">Sign In</Link></span></p>
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <p>Copyright © 2022 MoneyFactory. All rights reserved.</p>
                    </div>
                </div>
                </>
                )}
                {formStatus === 'otp' && (
                    <>
                        <h3>OTP Verification</h3>
                        <p className="mt-2">
                            Enter One Time Password we sent on your mobile number {formValues.phone}
                        </p>
                        <form method="post" onSubmit={handleSubmit}>
                            <CssTextField
                                label="otp"
                                id="otp"
                                InputLabelProps={{
                                    style: { color: '#fff' }
                                }}
                                className={`${classes.first_tf} ${classes.fullwidth}`}
                                name="otp"
                                placeholder="Enter 6 digit OTP"
                                pattern="[0-9]{6}"
                                required
                            />
                            <p className={`${classes.errors} ${classes.otperror}`} style={{ marginBottom: 0 }}>
                                {formErrors.otp}
                            </p>
                            {/* <span style={{color:"#FFFFFF"}}>
                Didn’t receive code? 
                <a
                  href="javascript:void(0)"
                  onClick={() => sendOTP()}
                  style={resend ? { pointerEvents: 'none' } : {}}
                  className={
                    resend ? `${classes.disabled}` : `${classes.enabled}`
                  }
                >
                Resend OTP {resend ? `in ${counter}s` : ''}
                </a>
                </span> */}
                            <CssTextField
                                id="custom-css-outlined-input"
                                label="New Password"
                                type="password"
                                autoComplete="current-password"
                                InputLabelProps={{
                                    style: { color: '#fff' }
                                }}
                                name="password"
                                onChange={handlepassword}
                                className={`${classes.first_tf} ${classes.password}`}
                                value={password}
                            />
                            <p className={classes.errors}>{formErrors.password}</p>
                            <CssTextField
                                label="Confirm Password"
                                id="custom-css-outlined-input"
                                InputLabelProps={{
                                    style: { color: '#fff' }
                                }}
                                multiline={true}
                                className={`${classes.first_tf} ${classes.password}`}
                                onChange={handleConfirmpassword}
                                name="confirmPassword"
                                value={confirmPassword}
                            />
                            <p className={classes.errors}>{formErrors.confirmPassword}</p>
                            <Button
                                type="submit"
                                className={`${classes.button} ${classes.width} mt-5 mb-3`}
                            >
                                {isSubmit ? (
                                    <Spinner animation="border" variant="light" />
                                ) : (
                                    'CONTINUE'
                                )}
                            </Button>
                        </form>
                    </>
                )}
            </div>
        </div>
    )
}

export default SignIn;
