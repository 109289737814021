import React from 'react';
import classes from './Post.module.css';
import zeus from '../../../../assets/Zeus.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import doticon from '../../../../assets/fi-rr-menu-dots-vertical.svg';
import share from '../../../../assets/fi-sr-share.svg';
import useradd from '../../../../assets/fi-sr-user-add.svg';
import addcomment from '../../../../assets/add_comment.svg';
import comment from '../../../../assets/comment.svg';
import emoji from '../../../../assets/emoji.svg';
import like from '../../../../assets/likepost.svg';
import send from '../../../../assets/send_post.svg';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <img src={doticon} alt="" className={classes.doticon} />
    {children}
  </div>
));

const MenuItem = ({ icon, title }) => {
  return (
    <div className={classes.menuItem}>
      <div className={classes.menuItemIcon}>
        <img src={icon} alt="" />
      </div>

      <div className={classes.menuItemTitle}>{title}</div>
    </div>
  );
};

const Post = () => {
  return (
    <div className={classes.post}>
      <div className={classes.post_header}>
        <img src={zeus} alt="" />
        <div className={classes.post_acc}> Zeus </div>
        <div className={classes.aq_popup}>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu size="sm" title="" className={classes.menu}>
              <Dropdown.Item className={classes.dropItem}>
                <MenuItem icon={useradd} title={'Follow'} />
              </Dropdown.Item>
              <Dropdown.Item className={classes.dropItem}>
                <MenuItem icon={share} title={'Share Post'} />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className={classes.post_img}>
        <img
          src="https://images.unsplash.com/photo-1624996379671-b4d0837e45cb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt=""
        />
      </div>
      <div className={classes.post_act_like}>
        <div className={classes.post_actions}>
          <img src={like} alt="" />
          <img src={comment} alt="" />
          <img src={send} alt="" />
        </div>
        <div className={classes.likes}>3,605 likes</div>
      </div>
      <div className={classes.post_caption}>
        wipe and tell me which character do you like best? And let me know what
        you think about these amazing illustrations created by
      </div>
      <div className={classes.add_comment}>
        <div className={classes.com_inp}>
          <img src={emoji} alt="" />
          <div className={classes.addcom}>Add a comment...</div>
        </div>
        <div className={classes.post_comment}>
          <img src={addcomment} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Post;
