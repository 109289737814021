import { Link } from 'react-router-dom';
import num1 from '../../assets/num1.svg';
import num2 from '../../assets/num2.svg';
import num3 from '../../assets/num3.svg';
import num4 from '../../assets/num4.svg';


function singleStep(stepNo,logo){
    return <div className='col-xl-3'>
    <div style={{height: '200px', width: '250px', backgroundColor: 'rgba(29, 29, 29, 1)'}}>
        <img style={{padding:'20px 13px'}} src={stepNo} alt='step 1'/>
        <img style={{textAlign:'center',transform:'translateY(50%)'}} src={logo} alt='choose Quants'/>
    </div>
    <p style={{paddingTop:'20px', color:'rgba(206, 253, 30, 1)'}}>Select your portfolio value</p>
    <p>How much would you like to invest based on your goals, risk appetite, and current capacity.</p>
</div>
}

function Step(props) {
    return (
        <>
            <div className='dashbord_main_wrapper' style={{ minHeight: 'calc(100vh - 70px)',textAlign:'start' }}>
                <h4 className='dashbord_name_title'>{props.title}</h4>
                <h6>{props.subtitle}</h6>
                <h5 style={{ color:'rgba(0, 211, 127, 1)', padding:'20px 0px' }}>How it works?</h5>
                <div className='row'>
                    {singleStep(num1, props.img[0])}
                    {singleStep(num2, props.img[1])}
                    {singleStep(num3, props.img[2])}
                    {singleStep(num4, props.img[3])}
                </div>
                <Link to={props.link} className="all_links_text"><button type='button' className='cm_next_btn' style={{ marginLeft: '23px', backgroundColor:'rgba(0, 211, 127, 1)' }}>Get Started</button></Link>
            </div>
        </>
    );
}

export default Step;
