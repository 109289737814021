import React from 'react';
import classes from './Cards.module.css';
import { Card } from 'react-bootstrap';
import RMT from '../../assets/RMT.svg';
import UTP from '../../assets/UTP.svg';
import IRS from '../../assets/IRS.svg';
import FAS from '../../assets/FAS.svg';
import BS from '../../assets/buyandsell.svg';

function Cards() {
  const info = [
    { image: RMT, title: 'Regular market tracking' },
    { image: UTP, title: 'Unwavering trading psychology' },
    {
      image: BS,
      title: 'Efficient Investment  Entries & Exits'
    },
    { image: IRS, title: 'Identifying right Stocks' },
    { image: FAS, title: 'Firm Analytical Skill' }
  ];

  function renderCard(card, i) {
    return (
      <Card key={i} className={classes.indiv}>
        <Card.Body>
          <Card.Title>{card.title}</Card.Title>
        </Card.Body>
        <Card.Img loading="lazy" variant="top" src={card.image} />
      </Card>
    );
  }
  // column section
  return <div className={classes.card_layout}>{info.map(renderCard)}</div>;
}

export default Cards;
