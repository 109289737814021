import React, { useCallback, useEffect, useRef, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';


export default function MaxWidthDialog(props) {
  
  const [fullWidth, setFullWidth] = React.useState(true);
  const videoRef = useRef(null);
  const [isPlay, setIsPlay] = React.useState(false);
  const [isPlayOnScroll, setIsPlayOnScroll] = React.useState(true);

  const playVideo = useCallback(() => {
    setIsPlay(true);
    videoRef.current.play();
  });
  
  const startVideo = useCallback(({isPlayOnScroll}) => {
    if (isPlayOnScroll === true) {
      videoRef.current.play();
      setIsPlayOnScroll(false);
    }
  },[setIsPlayOnScroll]);

  const pauseVideo = useCallback(() => {
    videoRef.current.pause();
    setIsPlay(false);
    setIsPlayOnScroll(false);
  });
  
  
    useEventListener('scroll',startVideo)
    




  const handleClose = () => {
    props.setOpen(false);
  };
  const handleVideoPress = useCallback(() => {
    if (isPlay) {
      pauseVideo();
    } else {
      playVideo();
    }
  });

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={"lg"}
        open={props.open}
        onClose={handleClose}
        
      >
       
       <DialogTitle>MoneyFactory</DialogTitle>
        <DialogContent>
        <video
          width="100%"
          onClick={handleVideoPress}
          ref={videoRef}
          height="100%"
          controls
          
        >
          <source src="./static/MoneyFactoryVideo.mp4" type="video/mp4" />
        </video>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function useEventListener(eventName, handler, element = window) {
    // Create a ref that stores handler
    const savedHandler = useRef();
  
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);
  
    useEffect(
      () => {
        // Make sure element supports addEventListener
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;
  
        // Create event listener that calls handler function stored in ref
        const eventListener = event => savedHandler.current(event);
  
        // Add event listener
        element.addEventListener(eventName, eventListener);
  
        // Remove event listener on cleanup
        return () => {
          element.removeEventListener(eventName, eventListener);
        };
      },
      [eventName, element] // Re-run if eventName or element changes
    );
  }
  