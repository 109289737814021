import React from 'react';
import classes from './ManageSubscriptions.module.css';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CssSelect = styled(Select)({
  color: 'white',
  '& svg': {
    fill: '#00D37F'
  },
  '&:hover fieldset': {
    borderColor: '#00D37F !important'
  },
  '& input': {
    color: 'white'
  },
  '& fieldset': {
    borderColor: '#333333'
  },
  '&.Mui-focused fieldset': {
    // color:'black',
    borderColor: '#00D37F !important'
  }
});

const AdminHeader = () => {
  return (
    <div className={classes.adm_head}>
      <div className={classes.head_btn_selected}>Manage Subscription</div>
      <div className={classes.head_btn}>Broadcast Message</div>
      <div className={classes.head_btn}>Invoices</div>
      <div className={classes.head_btn}>Adjustment Requests</div>
    </div>
  );
};

const AdminCard = ({ title, val }) => {
  return (
    <div className={classes.adm_card}>
      <div className={classes.adm_card_title}>{title}</div>
      <div className={classes.adm_card_val}>{val}</div>
    </div>
  );
};

const AdminCards = () => {
  return (
    <div className={classes.adm_cards}>
      <AdminCard title={'P & L Generated: '} val="0.0" />
      <AdminCard title={'Capital Deployed: '} val="233.60" />
      <AdminCard title={"Today's PNL: "} val="5,422.00" />
      <AdminCard title={'P & L Pending Fees: '} val="0.0" />
      <AdminCard title={'Unique Active Subscribers: '} val="0" />
      <AdminCard title={'Live Deployments: '} val="0" />
    </div>
  );
};

const ActiveSubscriptions = () => {
  return (
    <div className={classes.adm_sub}>
      <div className={classes.adm_sub_hd}>
        <div className={classes.adm_sub_hd_title}>Active Subscribers</div>
        <div className={classes.adm_sub_hd_menu}>
          <FormControl>
            <InputLabel id="demo-simple-select-label" style={{ color: '#fff' }}>
              Active Subscribers
            </InputLabel>
            <CssSelect
              label="State"
              id="custom-css-outlined-input"
              IconComponent={KeyboardArrowDownIcon}
              InputLabelProps={{
                style: { color: '#fff' }
              }}
              multiline={true}
              // onChange={handleChange}
              name="risk"
              // value={quantDetails.risk}
            >
              {/* <MenuItem value={'Low'}>Low</MenuItem>
              <MenuItem value={'Medium'}>Medium</MenuItem>
              <MenuItem value={'High'}>High</MenuItem> */}
            </CssSelect>
          </FormControl>
          {/* <p className={classes.errors}>{quantErrors.risk}</p> */}
        </div>
      </div>
      <div className={classes.adm_sub_bd}>
        <div className={classes.adm_sub_dpdwn}>
          <div className={classes.adm_sub_dp_item}>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: '#fff' }}
              >
                Filter
              </InputLabel>
              <CssSelect
                label="State"
                id="custom-css-outlined-input"
                IconComponent={KeyboardArrowDownIcon}
                InputLabelProps={{
                  style: { color: '#fff' }
                }}
                multiline={true}
                // onChange={handleChange}
                name="risk"
                value="All"
              >
                <MenuItem value={'All'}>All</MenuItem>
              </CssSelect>
            </FormControl>
          </div>
          <div className={classes.adm_sub_dp_item}>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: '#fff' }}
              >
                Group By
              </InputLabel>
              <CssSelect
                label="State"
                id="custom-css-outlined-input"
                IconComponent={KeyboardArrowDownIcon}
                InputLabelProps={{
                  style: { color: '#fff' }
                }}
                multiline={true}
                // onChange={handleChange}
                name="risk"
                value="Subscriber"
              >
                <MenuItem value={'Subscriber'}>Subscriber</MenuItem>
              </CssSelect>
            </FormControl>
          </div>
          <div className={classes.adm_sub_dp_item}>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: '#fff' }}
              >
                Update All Deployes
              </InputLabel>
              <CssSelect
                label="State"
                id="custom-css-outlined-input"
                IconComponent={KeyboardArrowDownIcon}
                InputLabelProps={{
                  style: { color: '#fff' }
                }}
                multiline={true}
                // onChange={handleChange}
                name="risk"
                value="Select Status"
              >
                <MenuItem value={'Select Status'}>Select Status</MenuItem>
              </CssSelect>
            </FormControl>
          </div>
          <div className={classes.updateStatus}>Update Status</div>
        </div>
        <div className={classes.adm_bd_foot}>
          Currently your strategies have no subscribers with deployments. You
          need to list on the marketplace and promote them to gain subscribers.
          You can then use this page to manage their subscriptions,invoicing and
          broadcast messages.
        </div>
      </div>
    </div>
  );
};
const AdminSubscriptions = () => {
  return (
    <div className={classes.adm_body}>
      <AdminHeader />
      <AdminCards />
      <ActiveSubscriptions />
    </div>
  );
};

export default AdminSubscriptions;
