/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classes from './DeployedBar.module.css';
import wifi from '../../../assets/wifi.svg';
import doc from '../../../assets/doc.svg';
import RA from '../../../assets/right_arrow.svg';
import box from '../../../assets/box.svg';
import V from '../../../assets/verified.svg';

function Actionoints() {
    return (
        <div className={`${classes.db_container} w-100`}>
            <div className='common_quants_title_wrap'>
                <h3>Action Points</h3>
            </div>
            <div className='action_points_list'>
                <ul>
                    <li>
                        <div className='active_point_info_wrap'>
                            <div className='active_point_img'>
                                <img src={wifi} alt="wifi" width="40" height="40" />
                            </div>
                            <p>Live Trades</p>
                        </div>
                    </li>
                    <li>
                        <div className='active_point_info_wrap'>
                            <div className='active_point_img'>
                                <img src={doc} alt="stimulated task" width="40" height="40" />
                            </div>
                            <p>Live Trades</p>
                        </div>
                    </li>
                    <li>
                        <div className='active_point_info_wrap'>
                            <div className='active_point_img'>
                                <img src={box} alt="box" width="40" height="40" />
                            </div>
                            <p>Live Trades</p>
                        </div>
                    </li>
                    <li>
                        <div className='active_point_info_wrap'>
                            <div className='active_point_img'>
                                <img src={V} alt="stimulated task" width="40" height="40" />
                            </div>
                            <p>Live Trades</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Actionoints;
