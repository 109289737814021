import React, { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import leftIconSmit from '../../assets/leftIconSmit.svg';
import rightIconSmit from '../../assets/rightIconSmit.svg';
import classes from './Siat.module.css';

// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import client1 from '../../assets/client1.svg';
import client2 from '../../assets/client1.svg';
import client3 from '../../assets/client1.svg';

const responsive = {
  // when window width is >= 464 px
  464: {
    slidesPerView: 2.5
  },
  1024: {
    slidesPerView: 3.2
  },
  3000: {
    slidesPerView: 4
  }
};

const investmentData = [
  {
    img: client1,
    alt: '',
    title: '',
    content: `I have recently joined Moneyfactory Affiliate and I am very impressed with the user-friendly interface and the ease of use. The application is intuitive and easy to understand, which makes it extremely easy to start earning commissions. I'm looking forward to what this program has to offer!`
  },
  {
    img: client2,
    alt: '',
    title: '',
    content: `I have to say that Moneyfactory has been an amazing resource for me. I have been able to make money while also learning a lot about personal finance and investing. The affiliate program is easy to understand and the commissions are great. I definitely recommend it!`
  },
  {
    img: client3,
    alt: '',
    title: '',
    content: `Moneyfactory's Affiliate Program has been a great way for me to make extra money. The 20% recurring commission and 1000 bonus on my first investment are amazing. I highly recommend Moneyfactory to anyone looking to start an affiliate program!`
  },
  {
    img: client2,
    alt: '',
    title: '',
    content: `I recently signed up for the Moneyfactory Affiliate Program and I am loving it! The setup was super easy, and the team is always available to answer my questions. I have already made great strides in earning extra income and am looking forward to seeing more success in the future.`
  }
];

function Siat() {
  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Autoplay]);
  return (
    <div className={classes.siat_container}>
      <div className={classes.investFactorDiv}>
        <div className={`position-relative ${classes.rightFluid}`}>
        <Container>
         
          <p>TRADERS TALK</p>
          <h1>
            What our <br /> affiliates say
          </h1>
        </Container>
          {swiper ? (
            <SwiperActionBar
              ItemsLength={investmentData.length}
              swiper={swiper}
            />
          ) : null}
          <Swiper
           slidesPerView={3}
            loop={true}
            autoplay={true}
            breakpoints={responsive}
            watchOverflow
            spaceBetween={30}
            onSwiper={(sw) => setSwiper(sw)}
            style={{ paddingTop: '150px' }}
          >
            {investmentData.map((x, k) => {
              return (
                <SwiperSlide className={classes.investFactorCard}>
                  <Card
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                  >
                    <Card.Body>
                      <Card.Text>
                        {/* <img
                          src={x.img}
                          alt={x.alt}
                          className={classes.investImg}
                        /> */}

                        <div className={classes.clientName}>
                          {/* <h4 className={classes.investTitle}>name</h4>
                          <div className={classes.investContent}>Position</div> */}
                        </div>

                        <h4 className={classes.investTitle}>{x.title}</h4>
                        <div className={classes.investContent}>{x.content}</div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

const SwiperActionBar = ({ ItemsLength, swiper }) => {
  const [disableNext, setDisableNext] = useState(
    swiper.activeIndex + Number(swiper.params.slidesPerView) >= ItemsLength
  );
  const [disablePrev, setDisablePrev] = useState(swiper.activeIndex <= 0);
  const nextSlide = () => {
    swiper.slideNext();
    setDisableNext(
      swiper.activeIndex + Number(swiper.params.slidesPerView) >= ItemsLength
    );
    setDisablePrev(swiper.activeIndex <= 0);
  };

  const prevSlide = () => {
    swiper.slidePrev();
    setDisableNext(
      swiper.activeIndex + Number(swiper.params.slidesPerView) - 1 >=
        ItemsLength
    );
    setDisablePrev(swiper.activeIndex - 1 <= 0);
  };

  return (
    <div className={classes.swiperActionBar}>
      <button onClick={() => prevSlide()} disabled={disablePrev}>
        {/* &lt; */}
        <img className={classes.rightIconSmit} src={rightIconSmit} alt="prev" />
      </button>
      <br />
      <br />
      <button onClick={() => nextSlide()} disabled={disablePrev}>
        {/* &gt; */}
        <img className={classes.leftIconSmit} src={leftIconSmit} alt="next" />
      </button>
    </div>
  );
};

export default Siat;
