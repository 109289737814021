import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import classes from './disclaimer.module.css';

function Disclaimer() {
  return (
    <div className={classes.desclaimerBg}>
      <Navbar />
      <Container>
        <div className={`${classes.disclaimerContent}`}>
        <h2>Disclaimer</h2>
          <p>
            The information contained in this website is for general information
            purposes only. Finagg Technologies Private Limited (“<b>Company</b>
            ”), via its website{' '}
            <a href="https://www.moneyfactory.ai" rel="noreferrer" target="_blank">
              https://www.moneyfactory.ai
            </a>
            . will not be liable for any, direct or indirect, consequential or
            incidental damages or loss arising out of the use of this
            information
          </p>
          <p>
            The Company make no warranties or representations about the accuracy
            or completeness of this website and contents hereof. Neither us nor
            any of our contractors, partner or employees shall be liable for any
            direct, incidental, consequential, indirect or punitive damages
            arising out of access to or use of any content of this website. Any
            reliance you place on such information is therefore strictly at your
            own risk. You solely assume all risk if you rely on this material in
            any way.
          </p>
          <p>
            Any loss or damage, including without limitation indirect or
            consequential loss or damage, as well as any loss or damage whatever
            resulting from lost data or profits, arising out of or related to
            the use of this website, will not be covered under any circumstances
            by us. Every effort is taken to maintain the website's availability
            and functionality. However, the Company takes no responsibility and
            liability for any temporary website inaccessibility brought on by
            technical problems outside of our control.
          </p>
          <p>
            All disputes regarding our Services would be subject to jurisdiction
            of Bengaluru, Karnataka Courts.
          </p>
        </div>
      </Container>
      <Footer joinWithUs={false} childComponent={false} />
    </div>
  );
}
export default Disclaimer;
