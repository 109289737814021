import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './Footer.module.css';
import Logo from '../../assets/Logo.svg';
import insta from '../../assets/instagram.svg';
import fb from '../../assets/fb.svg';
import twitter from '../../assets/twitter.svg';
import youtube from '../../assets/youtube.svg';
import linkdin from '../../assets/linkdin.svg';
import SaveTime from './../../components/Home/SaveTime';
import Disclaimer from './../../assets/home/Disclaimer.pdf';
import Privacy from './../../assets/home/PRIVACY POLICY.pdf'

function Footer(props) {
  return (
    <>
      {/* <div className={classes.footer_bg}></div> */}

      <div
        className={classes.footer}
        style={{
          backgroundImage:
            typeof props.joinWithUs != 'undefined' && props.joinWithUs
              ? "url('/static/images/Rectangle178.png')"
              : typeof props.childComponent != 'undefined' &&
                props.childComponent
              ? "url('/static/images/Rectangle179.png')"
              : 'none'
        }}
      >
        <Container className={classes.footerContainerFirst}>
          {typeof props.joinWithUs != 'undefined' && props.joinWithUs && (
            <SaveTime />
          )}

          {typeof props.childComponent != 'undefined' && props.childComponent}

          <Row className={`mt-4`}>
            <Col md={12} sm={12} xs={12} lg={4}>
              <div className={classes.firstDivPages}>
                <img src={Logo} loading="lazy" alt="Logo" />
                <p className={classes.about_brand}>
                  Grow your money safely with our investment expertise & AI
                  assisted solutions. Save your time with our simulated,
                  assisted and automatic investing strategies.
                </p>
                {/* <p className={classes.about_brand}>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p> */}
                <div className={classes.social_handles}>
                  <a
                    href="https://www.facebook.com/Moneyfactoryai/"
                    target="_blank"
                  >
                    <img src={fb} alt="fb" height="40%" />
                  </a>
                  <a
                    href="https://mobile.twitter.com/Moneyfactory_ai"
                    target="_blank"
                  >
                    <img src={twitter} alt="twitter" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/moneyfactory-ai/about/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkdin} alt="linkdin" />
                  </a>
                  <a
                    href="https://www.instagram.com/moneyfactory.ai/"
                    target="_blank"
                  >
                    <img src={insta} alt="insta" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCwQ83V4XG31MRakSEClo_JQ"
                    target="_blank"
                  >
                    <img src={youtube} alt="youtube" />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} xs={12} lg={8}>
              <Row>
                <Col md={12} sm={12} xs={12} lg={12}>
                  <p className={classes.secondDivTitle}>Quick Links</p>
                </Col>
                <Col md={6} sm={6} xs={12} lg={6}>
                  <div className={classes.secondDivPages}>
                    {/* <p onClick={()=>window.location.href="/#home"} className={classes.FooterMenu}>Home</p> */}
                    <p>
                      <a
                        href="/"
                        target="_blank"
                        rel="canonical"
                        className={classes.FooterMenu}
                      >
                        About Us
                      </a>
                    </p>
                    {/* <p className={classes.FooterMenu}>Services/Products</p>
                    <p className={classes.FooterMenu}>Investment</p>
                    <p className={classes.FooterMenu}>Pricing</p> */}
                    <p>
                      <a
                        href="/blog"
                        target="_blank"
                        rel="canonical"
                        className={classes.FooterMenu}
                      >
                        Blogs
                      </a>
                    </p>
                    <p>
                      <a
                        href="/affilate"
                        target="_blank"
                        rel="canonical"
                        className={classes.FooterMenu}
                      >
                        Affilate Page
                      </a>
                    </p>
                  </div>
                </Col>
                <Col md={6} sm={6} xs={12} lg={6}>
                  <div className={classes.thirdDivPages}>
                    <p>
                      <a
                        href="/#faq"
                        rel="canonical"
                        className={classes.FooterMenu}
                      >
                        FAQs
                      </a>
                    </p>
                    <p>
                      <a
                        href="mailto:Support@moneyfactory.ai"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.FooterMenu}
                      >
                        Contact Us
                      </a>
                    </p>
                    <p>
                      <a
                        href={Disclaimer}
                        download="Disclaimer.pdf"
                      >
                        Disclaimer
                      </a>
                    </p>
                    <p>
                      <a
                        href={Privacy}
                        download="Privacy Policy.pdf"
                      >
                        Privacy Policy
                      </a>
                    </p>
                    {/* <p className={classes.FooterMenu}>Legal notice</p> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <hr />
        <div className={classes.copyRights}>
          @ 2022 Moneyfactory All Rights Reserved
          <Container>
            <div className={classes.belowFooterContent}>
              Past performance is not indicative of future returns. Please
              consider your specific investment requirements, risk tolerance,
              goal, time frame, risk and reward balance and the cost associated
              with the investment before choosing a quant or stock. Performance
              and returns of any investment can neither be predicted nor
              guaranteed. <br />
              <b>All Investments are subject to market risks.</b> <br />
              Terms and Conditions, Disclaimer and Privacy Policy of the Company
              are applicable.
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Footer;
