import React, { useCallback, useEffect, useRef, useState } from 'react';
import Macbook from '../../assets/home/mainBanner.svg';
import PlayButtonImage from '../../assets/home/play.svg';
import classes from './MBgrad.module.css';
import IconButton from '@mui/material/IconButton';
import { Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { debounce } from 'lodash';
import PlayCircleOutlineTwoToneIcon from '@mui/icons-material/PlayCircleOutlineTwoTone';
import Videomodel from './videomodel';

function MBgrad() {
  const [isPlay, setIsPlay] = React.useState(false);
  const [isPlayOnScroll, setIsPlayOnScroll] = React.useState(true);
  const videoRef = useRef(null);

  // useEffect(() => {
  //   // window.addEventListener('scroll',  debounce(pauseVideo, 500))
  //   if (isPlayOnScroll) {
  //     window.addEventListener('scroll', startVideo);
  //   } else {
  //     window.removeEventListener('scroll', startVideo);
  //   }

  //   return () => {
  //     window.removeEventListener('scroll', startVideo);
  //   };
  // }, [isPlayOnScroll]);
  
  const playVideo = useCallback(() => {
    setIsPlay(true);
    videoRef.current.play();
  });
  
  const startVideo = useCallback(({isPlayOnScroll}) => {
    if (isPlayOnScroll === true) {
      videoRef.current.play();
      setIsPlayOnScroll(false);
    }
  },[setIsPlayOnScroll]);

  const pauseVideo = useCallback(() => {
    videoRef.current.pause();
    setIsPlay(false);
    setIsPlayOnScroll(false);
  });
  
  
    useEventListener('scroll',startVideo)
    

  const handleVideoPress = useCallback(() => {
    if (isPlay) {
      pauseVideo();
    } else {
      playVideo();
    }
  });
const [open,setOpen]=useState(false)
  return (
    <Container className="">
      <div className={`${classes.imgM} `} style={{position: 'relative'}}>
        <h2>
          Invest with <span className={classes.subHeading}>MoneyFactory</span>
        </h2>
        {/* {!isPlay && (
          <>
              <img
                src={Macbook}
                alt="macbook svg"
                width="100%"
                onClick={playVideo}
                />
          </>
        )} */}

        {/* {isPlay && ( */}
        <div  style={{ position: 'relative',borderRadius:'2%',overflow: 'hidden',display: 'flex',
        justifyContent: 'center',alignItems: 'center'}}>
          
        
        <video
          width="100%"
          onClick={handleVideoPress}
          ref={videoRef}
          autoPlay
          
          loop
          muted
        >
          <source src="./static/MoneyFactoryVideo.mp4" type="video/mp4" />
        </video>
        {/* )} */}
        <div onClick={()=>setOpen(true)}
      style={{ position: "absolute",top:"50%",left:"50%",transform: "translate(-50%,-50%)"}}
      >
      <PlayCircleOutlineTwoToneIcon style={{fontSize:100,color:"gray",cursor: "pointer"}}
      
      />
      </div>
      </div>
      </div>
     
     <Videomodel open={open} setOpen={setOpen}/>
    </Container>
  );
}

export default MBgrad;



function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
