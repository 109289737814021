export const pageAnimation = {
  hidden: {
    x: 300,
    opacity: 0
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.4
    }
  },
  exit: {
    x: -1500,
    opacity: 0,
    transition: {
      duration: 0.4
    }
  }
};

export const slide = {
  hidden: {
    x: 300,
    opacity: 0
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 1
    }
  },
  exit: {
    x: -1500,
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
};
