import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './MyQuantFilter.module.css';
import Close from '../../../assets/close.svg';
import swal from 'sweetalert';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CssSelect = styled(Select)({
  color: "white",
  background: "#1D1D1D",
  '& svg': {
    fill: "#00D37F"
  },
  '&:hover fieldset': {
    borderColor: '#00D37F !important'
  },
  '& input': {
    color: 'white'
  },
  '& fieldset': {
    borderColor: "#333333",
  },
  '&.Mui-focused fieldset': {
    // color:'black',
    borderColor: '#00D37F !important'
  }
});

function Filter(props) {
  const [categories, setCategories] = useState([]);
  var options={
    category:'all',
    mode:'all',
    segment:'all',
    status:'all',
    broker:'all'
  };

  // const [selectedMapFilters, setSelectedMapFilters] = useState([]);
  // const [tempSelectedFilters,setTempSelectedFilters]=useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/categories`, {
      mode: 'cors',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setCategories(res);
      })

  }, [])

  const close_filter = () => {
    const filt = document.querySelector('.filter');
    document.body.style.height = "initial";
    document.body.style.overflow = "auto";
    filt.style.transform = "translateX(320px)";
  }

  const applyFunction = () => {
    props.setFil(options);
    close_filter();
  }
  const handleChange = (value, name) => {
    options[name]=value;
  }

  const reset = () => {
    options={
      category:'all',
      mode:'all',
      segment:'all',
      status:'all',
      broker:'all'
    };
    applyFunction();
  }

  return (
    <div className={`${classes.filter} filter`}>
      <div className={classes.heading}>
        <h3>Filter Strategies</h3>
        <img src={Close} onClick={() => { close_filter() }} alt="" />
      </div>
      <div className={classes.select}>
        <FormControl>
          <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Categories</InputLabel>
          <CssSelect
            label="Categories"
            id="custom-css-outlined-input"
            IconComponent={KeyboardArrowDownIcon}
            InputLabelProps={{
              style: { color: '#BFC0C0' }
            }}
            multiline={true}
            className={classes.first_tf}
            onChange={(e)=>{handleChange(e.target.value,'category');}}
            name="categories"
            defaultValue={options.category}
            fullWidth={true}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {categories && categories.map(obj => <MenuItem value={obj._id}>{obj.name}</MenuItem>)}
          </CssSelect>
        </FormControl>
      </div>
      <div className={classes.select}>
        <FormControl>
          <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Mode</InputLabel>
          <CssSelect
            label="Mode"
            id="custom-css-outlined-input"
            IconComponent={KeyboardArrowDownIcon}
            InputLabelProps={{
              style: { color: '#BFC0C0' }
            }}
            multiline={true}
            className={classes.first_tf}
            onChange={(e)=>{handleChange(e.target.value,'mode');}}
            name="mode"
            defaultValue={options.mode}
            fullWidth={true}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"Assisted"}>Assisted</MenuItem>
            <MenuItem value={"Automatic"}>Automatic</MenuItem>
            <MenuItem value={"Stimulated"}>Stimulated</MenuItem>
          </CssSelect>
        </FormControl>
      </div>
      <div className={classes.select}>
        <FormControl>
          <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Segments</InputLabel>
          <CssSelect
            label="Segments"
            id="custom-css-outlined-input"
            IconComponent={KeyboardArrowDownIcon}
            InputLabelProps={{
              style: { color: '#BFC0C0' }
            }}
            multiline={true}
            className={classes.first_tf}
            onChange={(e)=>{handleChange(e.target.value,'segment');}}
            name="segments"
            defaultValue={options.segment}
            fullWidth={true}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"NSE"}>NSE</MenuItem>
            <MenuItem value={"FNO"}>FNO</MenuItem>
            <MenuItem value={"MCX"}>MCX</MenuItem>
          </CssSelect>
        </FormControl>
      </div>
      <div className={classes.select}>
        <FormControl>
          <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Status</InputLabel>
          <CssSelect
            label="Status"
            id="custom-css-outlined-input"
            IconComponent={KeyboardArrowDownIcon}
            InputLabelProps={{
              style: { color: '#BFC0C0' }
            }}
            multiline={true}
            className={classes.first_tf}
            onChange={(e)=>{handleChange(e.target.value,'status');}}
            name="status"
            defaultValue={options.status}
            fullWidth={true}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Inactive</MenuItem>
          </CssSelect>
        </FormControl>
      </div>
      <div className={classes.select}>
        <FormControl>
          <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Broker</InputLabel>
          <CssSelect
            label="Broker"
            id="custom-css-outlined-input"
            IconComponent={KeyboardArrowDownIcon}
            InputLabelProps={{
              style: { color: '#BFC0C0' }
            }}
            multiline={true}
            className={classes.first_tf}
            onChange={(e)=>{handleChange(e.target.value,'broker');}}
            name="broker"
            defaultValue={options.broker}
            fullWidth={true}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {props.brokerList && props.brokerList.map(obj => <MenuItem value={obj._id}>{obj.name}</MenuItem>)}
          </CssSelect>
        </FormControl>
      </div>
      <div className={classes.button}>
        <button onClick={reset}>Reset</button>
        <button onClick={applyFunction}>Apply</button>
      </div>
    </div>
  )
}

export default Filter;