import React from 'react';
import classes from './Cashback.module.css';
import Gift from '../../../assets/Gift.svg';

function Cashback() {
  return (
    <>
      <div className={classes.cashback_container}>
        <div className={classes.cb_title}>
          <h5>
            Get <span>₹500</span> Cashback on your <br />
            <span>FIRST</span> Referral
          </h5>
          <div className={classes.cb_button}>
            <button>REFER</button>
          </div>
        </div>
        <div className={classes.cb_image}>
          <img src={Gift} alt="loading" width="125" height="125" />
        </div>
      </div>
    </>
  );
}

export default Cashback;
