import React, { useState } from 'react';
import classes from './ActiveQuantsCard.module.css';
import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';
import doticon from '../../../../assets/fi-rr-menu-dots-vertical.svg';
import pause from '../../../../assets/fi-sr-pause.svg';
import start from '../../../../assets/fi-sr-play.svg';
import exit from '../../../../assets/fi-sr-sign-out-alt.svg';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <img src={doticon} alt="" className={classes.doticon} />
    {children}
  </div>
));

const MenuItem = ({ icon, title }) => {
  return (
    <div className={classes.menuItem}>
      <div className={classes.menuItemIcon}>
        <img src={icon} alt="" />
      </div>

      <div className={classes.menuItemTitle}>{title}</div>
    </div>
  );
};

const ActiveQuantsCard = ({ quants }) => {
  const [selected, setSelected] = useState([]);

  const checkele = (ele) => {
    if (selected.find((e) => ele == e)) {
      return true;
    } else {
      return false;
    }
  };
  // const calc = (subs, ltp) => {
  //   return Math.round(
  //     ((Math.round(ltp * Math.round(subs / ltp)) * 100) / 100 - subs) * 100
  //   );
  // };

  const calcTotalPnl = () => {
    let total = 0;
    quants.stocksignals.map((ele) => {
      total += ele?.pldata?.pl;
    });

    return isNaN(total) ? 0 : Number(total).toFixed(2);
  };

  const updateQuantStatus = (quantId, status) => {
    if (!selected.length || selected.length === quants.stocksignals.length) {
      const options = {
        quantId,
        status,
        userId: JSON.parse(localStorage.getItem('MF_user')).id
      };

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(options)
      };

      fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/update/quantstatus`,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            // get error message from body or default to response status
            const error = 'not subscribed';
            return Promise.reject(error);
          }
          return res.json();
        })
        .then((res) => {
          swal({
            title: 'Successfully updated',
            text: 'stocks updated successfully',
            icon: 'success',
            timer: 3000
          });
        });
    } else {
      const options = {
        quantId,
        symbol: selected,
        status,
        userId: JSON.parse(localStorage.getItem('MF_user')).id
      };

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(options)
      };

      fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/update/signalstockstatus`,
        requestOptions
      )
        .then((res) => {
          if (!res.ok) {
            // get error message from body or default to response status
            const error = 'not subscribed';
            return Promise.reject(error);
          }
          return res.json();
        })
        .then((res) => {
          swal({
            title: 'Successfully updated',
            text: 'stocks updated successfully',
            icon: 'success',
            timer: 3000
          });
        });
    }
  };

  const exitStock = (signalId) => {
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ signalId })
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/broker/exit/signals`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        swal({
          title: 'Successfully Exited',
          text: 'stocks exited successfully',
          icon: 'success',
          timer: 3000
        });
      });
  };

  const calc = (qty, ltp, price) => {
    return Math.round(
      ((Math.round(ltp * Math.round(qty)) * 100) / 100 - price * qty) * 100
    );
  };

  //   const calcTotalPnl = () => {
  //     let total = 0;
  //     quants.stocksignals.map((ele) => {
  //       var price = ele?.signal === 1 ? ele?.transactions[0].entry_price : 0;

  //       if (ele.ltp) {
  //         total += calc(ele.qty, ele.ltp, price);
  //       }
  //     });
  //     return isNaN(total) ? 0 : total / 100;
  //   };

  return (
    <div className={`${classes.aq_card} active_card_info`}>
      <img
        src={quants ? quants.quant.quant.imgUrl : ''}
        alt=""
        className="ac_quants_wrap"
      />
      <div className={classes.aq_popup}>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu size="sm" title="" className={classes.menu}>
            <Dropdown.Item
              className={classes.dropItem}
              onClick={() => {
                updateQuantStatus(quants.quant.quant._id, 'start');
              }}
            >
              <MenuItem icon={start} title={'Start'} />
            </Dropdown.Item>
            <Dropdown.Item
              className={classes.dropItem}
              onClick={() => {
                updateQuantStatus(quants.quant.quant._id, 'pause');
              }}
            >
              <MenuItem icon={pause} title={'Pause'} />
            </Dropdown.Item>
            <Dropdown.Item
              className={
                `${classes.dropItem}` +
                ' ' +
                `${selected.length ? 'disable' : 'enable'}`
              }
              onClick={() => {
                updateQuantStatus(quants.quant.quant._id, 'exit');
              }}
            >
              <MenuItem icon={exit} title={'Exit ALL'} />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={`${classes.aq_cardtitle} ac_quants_name`}>
        {quants.quant.quant.name}
      </div>
      <div className={`${classes.aq_cardbottom} w-100 mt-2`}>
        <div className={classes.pnl}>{'P&L'}</div>
        <div
          className={`${classes.price} ${
            calcTotalPnl() < 0 ? classes.red : classes.green
          }`}
        >
          {calcTotalPnl() < 0 ? '-' : '+'} ₹ {Math.abs(calcTotalPnl())}
          {/* {quants.quant.quant.price} */}
        </div>
      </div>
    </div>
  );
};

export default ActiveQuantsCard;
