import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Select, styled, MenuItem, TextField, FormControl, InputLabel, Autocomplete, Paper } from "@mui/material";
import classes from './ListBuilder.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Modal } from 'react-bootstrap';

const CssAutoComplete = styled(Autocomplete)({
  color: "white",
  '& svg': {
    fill: "#00D37F"
  },
  '&:hover fieldset': {
    borderColor: '#00D37F !important'
  },
  '& input': {
    color: 'white'
  },
  '& fieldset': {
    borderColor: "#333333",
  },
  '&.Mui-focused fieldset': {
    // color:'black',
    borderColor: '#00D37F !important'
  }
});

const CssSelect = styled(Select)({
  color: "white",
  '& svg': {
    fill: "#00D37F"
  },
  '&:hover fieldset': {
    borderColor: '#00D37F !important'
  },
  '& input': {
    color: 'white'
  },
  '& fieldset': {
    borderColor: "#333333",
  },
  '&.Mui-focused fieldset': {
    // color:'black',
    borderColor: '#00D37F !important'
  }
});

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#00D37F',
  },
  '&:hover .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#00D37F'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333333'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& label.Mui-root': {
    color: 'white !important'
  },
  '& .MuiInputBase-root': {
    color: 'white !important'
  }
});

function ListBuilder({ handleShow, handleClose, show, curstock, handleStocks }) {
  const [stockname, setStockname] = useState([])
  const [id, setId] = useState(1)
  const [stocks, setStocks] = useState(curstock);

  const close = () => {
    handleClose();
    setStocks(curstock);
    setId(1);
  };

  const handleAdd = () => {
    try {
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/stock/stockDetails/${id}`)
        .then((res) => {
          return res.json();
        }).then((res) => {
          let stockdetails = res.StockDetails[0];
          let flag = 1;
          stocks.forEach(ele => {
            if (ele.name == stockdetails.company) {
              flag = 0;
            }
          });

          if (flag) {
            let newObj = { name: stockdetails.company, series: stockdetails.Series, symbol: stockdetails.Symbol, ISIN: stockdetails.ISIN, industry: stockdetails.industry }
            setStocks([...stocks, newObj]);
          }
        })
    } catch (err) {
      console.log(err);
    }


    setId(1)
  }

  const save = () => {
    handleStocks(stocks);
    handleClose();
    setId(1);
  }

  const handlename = (e,value) => { setId(value.stockId) }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/all/stockname`, {
      mode: 'cors',
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setStockname(res);
      })
  }, [])

  useEffect(() => {
    setStocks(curstock);
  }, [curstock])

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        centered
        dialogClassName={classes.modalWidth}
      >
        <Modal.Header closeButton className={classes.header} closeVariant="white">
          <Modal.Title>
            <h3>List Builder</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          <div className={classes.list}>
            <div className={classes.listbuilder}>
              <div>
                {/* <FormControl>
                  <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Name</InputLabel>
                  <CssSelect
                    label={"Name"}
                    id="custom-css-outlined-input"
                    IconComponent={KeyboardArrowDownIcon}
                    InputLabelProps={{
                      style: { color: '#fff' },
                      shrink: false
                    }}
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          background: "#1D1D1D",
                          color: 'white',
                          border: "1px solid #333333",
                          "& li": {
                            padding: "0.7rem 0.5rem",
                          },
                          "& li:hover": {
                            background: "#00D37F1A"
                          },
                          "& .Mui-selected": {
                            background: "none",
                          }
                        }
                      }
                    }}
                    multiline={true}
                    className={classes.first_tf}
                    onChange={handlename}
                    name="name"
                    margin='normal'
                    variant="outlined"
                    value={id}
                  >
                    {stockname.map((ele) => (<MenuItem value={ele.stockId}>{`${ele.company}`}</MenuItem>))}
                  </CssSelect>
                </FormControl> */}
                <FormControl>
                  <CssAutoComplete
                    disablePortal
                    id="custom-css-outlined-input"
                    onChange={handlename}
                    options={stockname}
                    value={stockname.filter(i=>i.stockId===id).company}
                    PaperComponent={({children})=><Paper style={{background:'black', color: "white"}}>{children}</Paper>}
                    getOptionLabel={option => option.company}
                    sx={{ width: 300 }}
                    renderInput={(params) => <CssTextField {...params} label="Name"/>} />
                </FormControl>
                <FormControl>
                  <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Select List</InputLabel>
                  <CssSelect
                    label={"Select List"}
                    id="custom-css-outlined-input"
                    IconComponent={KeyboardArrowDownIcon}
                    InputLabelProps={{
                      style: { color: '#fff' },
                      shrink: false
                    }}
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          background: "#1D1D1D",
                          color: 'white',
                          border: "1px solid #333333",
                          "& li": {
                            padding: "0.7rem 0.5rem",
                          },
                          "& li:hover": {
                            background: "#00D37F1A"
                          },
                          "& .Mui-selected": {
                            background: "none",
                          }
                        }
                      }
                    }}
                    multiline={true}
                    className={classes.first_tf}
                    // onChange={handleBroker}
                    name="broker"
                    margin='normal'
                    variant="outlined"
                  >
                    <MenuItem value={"5Paisa"}>5paisa</MenuItem>
                  </CssSelect>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Exchange</InputLabel>
                  <CssSelect
                    label={"Exchange"}
                    id="custom-css-outlined-input"
                    IconComponent={KeyboardArrowDownIcon}
                    InputLabelProps={{
                      style: { color: '#fff' },
                      shrink: false
                    }}
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          background: "#1D1D1D",
                          color: 'white',
                          border: "1px solid #333333",
                          "& li": {
                            padding: "0.7rem 0.5rem",
                          },
                          "& li:hover": {
                            background: "#00D37F1A"
                          },
                          "& .Mui-selected": {
                            background: "none",
                          }
                        }
                      }
                    }}
                    multiline={true}
                    className={classes.first_tf}
                    // onChange={handleBroker}
                    name="broker"
                    margin='normal'
                    variant="outlined"
                  >
                    <MenuItem value={"5Paisa"}>5paisa</MenuItem>
                  </CssSelect>
                </FormControl>
                <div>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label" style={{ color: "#BFC0C0" }}>Underlying</InputLabel>
                    <CssSelect
                      label={"UnderLying"}
                      id="custom-css-outlined-input"
                      IconComponent={KeyboardArrowDownIcon}
                      InputLabelProps={{
                        style: { color: '#fff' },
                        shrink: false
                      }}
                      MenuProps={{
                        sx: {
                          "& .MuiPaper-root": {
                            background: "#1D1D1D",
                            color: 'white',
                            border: "1px solid #333333",
                            "& li": {
                              padding: "0.7rem 0.5rem",
                            },
                            "& li:hover": {
                              background: "#00D37F1A"
                            },
                            "& .Mui-selected": {
                              background: "none",
                            }
                          }
                        }
                      }}
                      multiline={true}
                      className={classes.first_tf}
                      // onChange={handleBroker}
                      name="broker"
                      margin='normal'
                      variant="outlined"
                    >
                      <MenuItem value={"5Paisa"}>5paisa</MenuItem>
                    </CssSelect>
                  </FormControl>
                  <button onClick={handleAdd}>Add</button>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.table}>
                <Table sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow sx={{ background: "#333333", textAlign: "center" }}>
                      <TableCell sx={{ color: "white" }}>#</TableCell>
                      <TableCell sx={{ color: "white" }}>Symbol</TableCell>
                      <TableCell sx={{ color: "white" }}>Underlying</TableCell>
                      {/* <TableCell sx={{color:"white"}}>Price</TableCell> */}
                      <TableCell sx={{ color: "white" }}>Change%</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stocks.map((ele) => (
                      <TableRow sx={{ border: "none", textAlign: "center" }}>
                        <TableCell sx={{ color: "white", border: "none" }}>{ele.name}</TableCell>
                        <TableCell sx={{ color: "white", border: "none" }}>{ele.symbol}</TableCell>
                        <TableCell sx={{ color: "white", border: "none" }}>dummy</TableCell>
                        <TableCell sx={{ color: "white", border: "none" }}>dummy</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <button className={classes.save} onClick={save}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ListBuilder