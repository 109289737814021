import ScrollAnimation from 'react-animate-on-scroll';
import { Col, Row } from 'react-bootstrap';
import Seo from '../../components/SEO'
import Button from '../../components/Ui/Button';
import insta from '../../assets/instagram.svg';
import fb from '../../assets/fb.svg';
import twitter from '../../assets/twitter.svg';
import youtube from '../../assets/youtube.svg';
import linkdin from '../../assets/linkdin.svg';
import Header from './components/header'
import classes from './getaccess.module.css';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import leftLayout from './assets/leftlayout.svg'
import rightLayout from './assets/rightlayout.svg'
import axios from 'axios';
import { useState } from 'react';
import Tick from './assets/tick.png'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"



const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#00D37F'
  },
  '&:hover .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#00D37F'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333333'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& label.Mui-root': {
    color: 'white'
  },
  '& .MuiInputBase-root': {
    color: 'white'
  }
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomForm = ({ status, message, onValidated, setstatus2 }) => {
  const [email, setemail] = useState("")
  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [open, setOpen] = useState(false)
  const submitname = async (e) => {
    e.preventDefault();
    if (email !== "" && firstName !== "" && lastName !== "") {
      // if (email !== "")
      //   await axios({
      //     method: 'POST',
      //     url: 'https://moneyfactory.ai/blog/api/mailchimp',
      //     data: {
      //       email_address: email, status: 'subscribed', merge_fields: {
      //         FNAME: firstName,
      //         LNAME: lastName
      //       }
      //     }
      //   }).then((res) => {
      //     if (res.status === 200) {
      //       setstatus("successfull")
      //     }
      //     else {
      //       setstatus("error")
      //       setTimeout(() => {
      //         setstatus("")
      //       }, 2000);
      //     }
      //   })
      // else {
      //   console.log('inside else');
      //   handleClick();
      // }

      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
      setstatus2(status)
    }
    else {

      setOpen(true);
      setTimeout(() => {
        setOpen(false)
      }, 2000);

    }
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} >
        <Alert severity="warning" sx={{ width: '100%' }}>
          Please Fill All The Fields
        </Alert>
      </Snackbar>
      {status !== "success" && status !== "error" && status !== "sending" && <form className="mc__form" onSubmit={submitname}>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ margin: '10px' }}>

            <CssTextField
              label="First Name"
              type="text"
              InputLabelProps={{
                style: { color: '#fff' }
              }}
              sx={{ width: { md: 350, xs: 200 }, marginRight: '20px' }}
              onChange={(e) => { setfirstName(e.target.value) }}
              name="firstName"
              value={firstName}
            />
            <CssTextField
              label="Last Name"
              type="text"
              InputLabelProps={{
                style: { color: '#fff' }
              }}
              sx={{ width: { md: 350, xs: 200 }, marginRight: '20px' }}
              onChange={(e) => { setlastName(e.target.value) }}
              name="lastName"
              value={lastName}
            />
          </div>

          <div>
            <CssTextField
              label="Email"
              type="email"
              InputLabelProps={{
                style: { color: '#fff' }
              }}
              sx={{ width: { md: 720, xs: 200 }, marginRight: '20px' }}
              onChange={(e) => { setemail(e.target.value) }}
              name="email"
              value={email}
            />
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ margin: '20px' }}
            onClick={submitname}
          >
            <Button
              wrapperClass={classes.homeBtnWrapper}
              btnClass={classes.homeBtn}

            >
              Sign Up
            </Button>
          </div>
        </div>
      </form>}
      {status === "sending" &&
        <div>

          <h2 style={{ color: 'white' }}>submitting..</h2>
        </div>
      }
      {status === "success" &&

        <div>
          <img style={{ width: 50, height: 50 }} src={Tick}></img>
          <h2 style={{ color: 'white' }}>Thank you for signing up with us</h2>
        </div>
      }
      {status === "error" &&
        <div>


          <h2>{message}</h2>
        </div>
      }
    </>

  );
};

const Getaccess = () => {



  const [status2, setstatus2] = useState("")
  const postUrl = `https://moneyfactory.us17.list-manage.com/subscribe/post?u=066e706b77fca944a121b5f51&id=d45f64224f`;





  return <div style={{
    position: 'relative',
    minHeight: '100vh', background: '#051213', overflowX: 'hidden',
  }}>   <Seo
      title="Join us -  Grow your money with MoneyFactory"
      canonical={window.location.pathname}
      description="We provide smartest AI assisted investment solution. Join us today and get our experts advice to invest and grow your money.   Sign up now."
    />
    <Header />
    <div className={classes.invest_container}>
      <Row>

        <div className={classes.investText}>

          <ScrollAnimation duration={2} animateIn="fadeIn">
            <h1>
              A simpler way to {' '}
              <span>
                grow your money
              </span>
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInLeft">
            <p>
              Cut through the noise & use AI to make smarter investment decisions with MoneyFactory
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="bounceInLeft">
            <h6 className={classes.supersub}>
              GET EARLY BIRD ACCESS + SPECIAL BONUSES IF YOU JOIN BEFORE NOV 30th
            </h6>
          </ScrollAnimation>

          <div style={{
            display: 'flex', justifyContent: 'center',
            alignItems: 'center', flexWrap: 'wrap', marginTop: 70
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

              <div style={{ margin: '10px' }}>
                <MailchimpSubscribe
                  url={postUrl}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      setstatus={setstatus2}
                      status2={status2}
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />



              </div>
            </div>
          </div>




        </div>




      </Row>

    </div>


    <div className={classes.social_handles} style={{ marginBottom: 30 }}>
      

        <a
          href="https://www.facebook.com/Moneyfactoryai/"
          target="_blank"
        >
          <img src={fb} alt="fb" height="40%" />
        </a>
        <a
          href="https://mobile.twitter.com/Moneyfactory_ai"
          target="_blank"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a
          href="https://www.linkedin.com/company/moneyfactory-ai/about/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkdin} alt="linkdin" />
        </a>
        <a
          href="https://www.instagram.com/moneyfactory.ai/"
          target="_blank"
        >
          <img src={insta} alt="insta" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCwQ83V4XG31MRakSEClo_JQ"
          target="_blank"
        >
          <img src={youtube} alt="youtube" />
        </a>

      </div>
      <div style={{ position: 'absolute',bottom: 0, left: 0 }} className={classes.rightLayout}>
        <img src={leftLayout} style={{ maxHeight: "100vh" }} />
      </div>
      <div style={{ position: 'absolute', bottom: 0, right: 0 }} className={classes.leftLayout}>
        <img src={rightLayout} />
      </div>

      <div style={{
        width: "100%", width: "100%", minHeight: '68px', marginTop: '30px',
         display: 'flex', justifyContent: 'center', marginTop: 'auto',
        alignItems: 'center', borderTop: '1px solid #FFFFFF1A', color: 'gray'
      }}>
        <div>© 2022 Moneyfactory All Rights Reserved</div>
      </div>


    
  </div>

}

export default Getaccess;