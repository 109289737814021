import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar';
import AddBroker from '../../components/ProfileSettings/AddBroker/AddBroker';
import BillingDetails from '../../components/ProfileSettings/BillingDetails/BillingDetails';
import EditProfile from '../../components/ProfileSettings/EditProfile/EditProfile';
import Feedback from '../../components/ProfileSettings/Feedback/Feedback';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import classes from './ProfilePage.module.css';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import MyPayments from '../../components/ProfileSettings/MyPayments/MyPayments';
import OrderBook from '../../components/ProfileSettings/OrderBook/OrderBook';
import SubscribedQuants from '../../components/ProfileSettings/SubscribedQuants/SubscribedQuants';
import Report from '../../components/ProfileSettings/Report/Report';
import WelcomePage from '../Welcome';
import Refer from '../../components/ProfileSettings/Refer/Refer';

const Profile = (props) => {
    /*
     0 - personal info 
     1 - billing deets
     2- order
     3- my report
     4 - add broker acc
     5 - feedback
     
    */

    const history = useHistory();
    const [user, setUser] = useState('');
    useEffect(() => {
        if (localStorage.getItem('MF_user')) {
            setUser(JSON.parse(localStorage.getItem('MF_user')));
        } else {
            swal({
                title: 'Please login to your account to continue!',
                text: 'you will be redirected to Sign in page in 3 seconds',
                icon: 'warning',
                timer: 3000
            }).then(() => {
                setTimeout(() => {
                    history.push('/signin');
                }, 750);
            });
        }
    }, []);



    const [selected, setSelected] = useState(props.location.selected || new URLSearchParams(props.location.search).get("selected") || 0);
    const [innerSidebar, setInnerSidebar] = useState(false);

    const oncloses = (data) => {
        setInnerSidebar(data)
    }
    return (
        <>
            {user ? (
                <div className={innerSidebar === true ? "sidebarOpen" : ""} >
                    <Sidebar>
                        <div className={classes.profile_container}>
                            <div className={classes.setting_wrap}>
                                <h4 className="header">Settings</h4>
                                <div className={classes.right_angles} onClick={() => setInnerSidebar(!innerSidebar)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32"><path fill="none" d="M0 0h24v24H0z" /><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(255,255,255,1)" /></svg>
                                </div>
                            </div>
                            <div className={classes.settings_container}>
                                <ProfileSettings
                                    selected={selected}
                                    setSelected={setSelected}
                                    setUser={setUser}
                                    onCloses={oncloses}
                                />
                                {selected == 0 ? (
                                    <EditProfile user={user} setUser={setUser} />
                                ) :
                                    // selected == 1 ? (
                                    //   <BillingDetails user={user} setUser={setUser} />
                                    // ) :
                                    selected == 1 ? (
                                        <SubscribedQuants />
                                    ) :
                                        selected == 2 ? (
                                            <OrderBook />
                                        ) :
                                            selected == 3 ? (
                                                <Report />
                                            ) :
                                                selected == 4 ? (
                                                    <AddBroker user={user} setUser={setUser} />
                                                ) :
                                                    selected == 6 ? (
                                                        <Feedback user={user} setUser={setUser} />
                                                    ) :
                                                        selected == 7 ? (
                                                            <MyPayments />
                                                        )
                                                            :
                                                            selected == 9 ? (
                                                                <Refer />
                                                            )
                                                                : (
                                                                    <></>
                                                                )}
                            </div>
                        </div>
                    </Sidebar>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Profile;
