import React, { useState, useEffect } from 'react';
import ProfileSettingsCard from './ProfileSettingsCard/ProfileSettingsCard';
import {
    Link
  } from "react-router-dom";
import classes from './Settings.module.css';
import user from '../../assets/fi-rr-user.svg';
import billing from '../../assets/fi-rr-box-alt.svg';
import feedback from '../../assets/fi-rr-comment.svg';
import userAdd from '../../assets/fi-rr-user-add.svg';
import logout from '../../assets/fi-rr-power.svg';
import report from '../../assets/fi-rr-stats.svg';
import order from '../../assets/fi-rr-book.svg';
import brokers from '../../assets/brokers.svg';
import refer from '../../assets/fi-rr-refer.svg'
// import brokers from '../../assets/brokers.svg';
import Subscription from '../../assets/subscription.svg'
import './SubscribedQuants/subscribedquants.css'


import { useHistory } from 'react-router-dom';

import swal from 'sweetalert';

const ProfileSettings = ({ selected, setSelected, setUser}, props) => {
    const history = useHistory();

    const handleLogout = () => {
        localStorage.removeItem("MF_user");
        swal({
            title: 'Successfully logged out from your account!',
            text: 'you will be redirected to Sign in page in 3 seconds',
            icon: 'success',
            timer: 3000
        }).then(() => {
            setTimeout(() => {
                setUser("");
                history.push('/signin');
            }, 750);
        });
    }

    const sidebarChangeHandler = () => {
        props.onCloses(false)
    }

    return (
        <div className={`${classes.setting} setting_wrap`}>
            <div className={classes.close_icon} onClick={sidebarChangeHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="rgba(255,255,255,1)"/></svg>
            </div>
            <div onClick={() => setSelected(0)}>
                <ProfileSettingsCard
                    icon={user}
                    title="Personal Information"
                    subtitle="Profile Details"
                    isSelected={selected == 0}
                />
            </div>
            <div onClick={() => window.location.href = '/welcome'}>
                <ProfileSettingsCard
                    icon={user}
                    title="Welcome"
                    subtitle="Welcome"
                    isSelected={selected == 8}
                />
            </div>
            <div onClick={() => setSelected(1)}>
                <ProfileSettingsCard
                    icon={user}
                    title="Subscribed Quants"
                    subtitle="My Subscribed Quants"
                    isSelected={selected == 1}
                />
            </div>
            <div onClick={() => setSelected(2)}>
                <ProfileSettingsCard
                    icon={order}
                    title="My Order Book"
                    subtitle="Order History"
                    isSelected={selected == 2}
                />
            </div>
            <div onClick={() => setSelected(3)}>
                <ProfileSettingsCard
                    icon={report}
                    title="My Reports"
                    subtitle="Order History"
                    isSelected={selected == 3}
                />
            </div>
            <div onClick={() => setSelected(4)}>
                <ProfileSettingsCard
                    icon={brokers}
                    title="My Brokers"
                    subtitle="Add Broker Account"
                    isSelected={selected == 4}
                />
            </div>
            <div onClick={() => window.location.href = '/plan'}>
                <ProfileSettingsCard
                    icon={Subscription}
                    title="Subscription Plan"
                    subtitle="Choose your subscription"
                    isSelected={selected == 5}
                />
            </div>
            <div onClick={() => setSelected(6)}>
                <ProfileSettingsCard
                    icon={feedback}
                    title="Feedback"
                    subtitle="Have suggestion? Submit here"
                    isSelected={selected == 6}
                />
            </div>
            <div onClick={() => setSelected(7)}>
                <ProfileSettingsCard
                    icon={billing}
                    title="My Payments"
                    subtitle="Payment Settings"
                    isSelected={selected == 7}
                />
            </div>
            <div onClick={() => setSelected(8)}>
                <Link to="/resetpassword" className='p-0 change_password'>
                    <ProfileSettingsCard
                        icon={userAdd}
                        title="Change Password"
                        subtitle="Change Password"
                        isSelected={selected == 8}
                    />
                </Link>
            </div>
            <div onClick={() => setSelected(9)}>
                <ProfileSettingsCard
                    icon={refer}
                    title="Refer Friends"
                    subtitle="Refer Friends"
                    isSelected={selected == 9}
                />
            </div>
            {/* <div onClick={() => {handleLogout();setSelected(4)}}>
      <ProfileSettingsCard
        icon={logout}
        title="Logout"
        subtitle="Logout of current session"
        isSelected={selected == 4}
      />
    </div> */}
        </div>
    );
};

export default ProfileSettings;
