import React from 'react';

import classes from './SettingsCard.module.css';
import right from '../../../assets/fi-rr-angle-small-right.svg';

const ProfileSettingsCard = ({ icon, title, subtitle, isSelected }) => {
  return (
    <div
      className={`${classes.settings_card} ${
        isSelected ? `${classes.selected}` : ''
      }`}
    >
      <div className={classes.icon}>
        <img src={icon} alt="" srcSet="" />
      </div>
      <div className={classes.details}>
        <h5 className={classes.card_heading}>{title}</h5>
        <h6 className={classes.card_subheading}>{subtitle}</h6>
      </div>
      <div className={classes.arrow_icon}>
        <img src={right} alt="" srcSet="" />
      </div>
    </div>
  );
};

export default ProfileSettingsCard;
