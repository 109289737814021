import React, { useEffect, useState } from 'react';
import BlogSearch from '../../components/Blog/BlogSearch';
import BlogBanner from '../../components/Blog/BlogBanner';
import BlogCard from '../../components/Blog/BlogCard';
import Subscribe from '../../components/Subscribe';
import Navbar from '../../components/Navbar/Navbar';
import classes from './blogPopular.module.css';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';
const BlogURL = `https://www.moneyfactory.ai/st`;
// const BlogURL = 'http://3.111.117.212:1337';
const BlogBaseURL = 'https://www.moneyfactory.ai';
// const BlogBaseURL = 'http://3.111.117.212:1337';
function PopularBlog() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategory] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
    fetchBlogs();
    fetchCategory();
  }, [page, search]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const filterBlogs = (query) => {
    setSearch(query.trim());
  };

  const fetchCategory = () => {
    let categoryAPI = `${BlogURL}/api/categories`;
    axios
      .get(categoryAPI)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          let result = res.data.data;
          setCategory(result);
        } else {
          setCategory([]);
        }
      })
      .catch((err) => {
        setBlogs([]);
      });
  };

  const fetchBlogs = () => {
    let blogAPI = `${BlogURL}/api/blogs?populate=*`;
    if (search && search.length > 0) {
      blogAPI = blogAPI + `&/[$contains]=${search}`;
    }
    axios
      .get(blogAPI)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          let filterResult = res.data.data
            .sort(
              (blogA, blogB) => (blogA, blogB) =>
                Number(blogA.attributes.publishedAt) -
                Number(blogB.attributes.publishedAt)
            )
            .reverse();
          setBlogs(filterResult);
        } else {
          setBlogs([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setBlogs([]);
      });
  };

  return (
    <>
      <Row>
        {categories.map((x, k) => {
          return (
            <Col key={k} md={2} lg={2} xs={4} sm={4}>
              <button className={classes.popularSuggestionBtn}>
                {x?.attributes?.Name}
              </button>
            </Col>
          );
        })}
      </Row>
      <div className={classes.popularBlogCard}>
        <BlogCard
          BlogURL={BlogURL}
          BlogBaseURL={BlogBaseURL}
          blogs={blogs}
          perPage={perPage}
          page={page}
          loadMore={loadMore}
        />
      </div>
    </>
  );
}

export default PopularBlog;
