import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import classes from '../AngelOne/AngelBroker.module.css';
import swal from 'sweetalert';

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import './broker_common.css';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const PTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const FyersBrokerDetails = () => {
    const initialValues = {
        clientCode: '',
        password: ''
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (formErrors.trim.length === 0 && isSubmit) {

            var id = JSON.parse(localStorage.getItem('MF_user')).id;
            fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/angelonebroker/addbroker/angelone`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        loggedInUserId: id,
                        clientcode: formValues.clientCode,
                        password: formValues.password
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = 'Error in creating';
                        swal({
                            title: 'Error',
                            text: error,
                            icon: 'error',
                            timer: 3000
                        }).then(() => { });
                        return Promise.reject(error);
                    }
                    return response.json();
                })
                .then((json) => {
                    swal({
                        title: 'Broker added!',
                        text: 'broker has been added.',
                        icon: 'success',
                        timer: 3000
                    }).then(() => { });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [formErrors, formValues, isSubmit]);

    const contentStyle = {
        background: '#1d1d1d',
        maxWidth: '60vw',
        width: '100%'
    };
    const overlayStyle = {
        background: 'rgba(0,0,0,0.5)',

        overflowY: 'auto'
    };
    //   const arrowStyle = { color: '#fff' }; // style for an svg element

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validate(formValues);
        setIsSubmit(true);
    };

    const validate = (values) => {
        if (!values.clientCode || !values.password) {
            setFormErrors('Field cannot be empty');
        }
        // return error;
    };

    return (
        <div>
            {isSubmit ? (
                <div className={classes.btn}>Add</div>
            ) : (
                <Popup
                    trigger={<div className='d-flex'><div className={classes.btn}>Add</div><div className={`${classes.btn} ml-3`}>Edit</div></div>}
                    modal
                    {...{
                        contentStyle,
                        overlayStyle
                    }}
                >
                    {(close) => (
                        <div className={classes.modal}>
                            <div className={classes.header}>
                                <div className={classes.header_text}>Fyers Broker</div>
                                <a className={classes.close} onClick={close}>
                                    <span> &times; </span>
                                </a>
                            </div>
                            <div className={classes.content}>
                                <div className={classes.inputs}>
                                    <div>
                                        <CssTextField
                                            label="Client Code"
                                            id="custom-css-outlined-input"
                                            InputLabelProps={{
                                                style: { color: '#fff' }
                                            }}
                                            className={classes.first_tf}
                                            onChange={handleChange}
                                            name="clientCode"
                                            value={formValues.clientCode}
                                        />
                                        <p className={classes.errors}>{formErrors}</p>
                                    </div>
                                    <div>
                                        <CssTextField
                                            label="Token"
                                            id="custom-css-outlined-input"
                                            InputLabelProps={{
                                                style: { color: '#fff' }
                                            }}
                                            // multiline={true}
                                            className={classes.first_tf}
                                            onChange={handleChange}
                                            name="Token"
                                            value=""
                                        />
                                        <p className={classes.errors}>{formErrors}</p>
                                        <div className='last_token'>
                                            <p>last token update: 6 July 2022  |  10:00 AM</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.inputs}>
                                    <div className='auto_title w-100'>
                                        <h4>Auto Login</h4>
                                    </div>
                                    <div>
                                        <CssTextField
                                            label="Fyers User ID"
                                            id="custom-css-outlined-input"
                                            InputLabelProps={{
                                                style: { color: '#fff' }
                                            }}
                                            className={classes.first_tf}
                                            onChange={handleChange}
                                            name="fyersuserid"
                                            value=""
                                        />
                                        <p className={classes.errors}>{formErrors}</p>
                                    </div>
                                    <div>
                                        <CssTextField
                                            label="Fyers Password"
                                            id="custom-css-outlined-input"
                                            InputLabelProps={{
                                                style: { color: '#fff' }
                                            }}
                                            // multiline={true}
                                            className={classes.first_tf}
                                            onChange={handleChange}
                                            name="fyerspassword"
                                            value=""
                                        />
                                        <p className={classes.errors}>{formErrors}</p>
                                    </div>
                                    <div>
                                        <CssTextField
                                            label="Fyer DOB"
                                            id="custom-css-outlined-input"
                                            InputLabelProps={{
                                                style: { color: '#fff' }
                                            }}
                                            className={classes.first_tf}
                                            onChange={handleChange}
                                            name="fyerdob"
                                            value=""
                                        />
                                        <p className={classes.errors}>{formErrors}</p>
                                    </div>
                                    <div>
                                        <div className='position-relative'>
                                            <CssTextField
                                                label="Setup Link"
                                                id="custom-css-outlined-input"
                                                InputLabelProps={{
                                                    style: { color: '#fff' }
                                                }}
                                                // multiline={true}
                                                className={classes.first_tf}
                                                onChange={handleChange}
                                                name="fyerspassword"
                                                value=""
                                            />
                                            <span className='copy_icon'>
                                                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11 20C12.3256 19.9984 13.5964 19.4711 14.5338 18.5338C15.4711 17.5965 15.9984 16.3256 16 15V6.24302C16.0016 5.71738 15.8988 5.19665 15.6976 4.71104C15.4964 4.22542 15.2008 3.78456 14.828 3.41402L12.586 1.17202C12.2155 0.799191 11.7746 0.50362 11.289 0.302438C10.8034 0.101255 10.2826 -0.00153795 9.757 1.73896e-05H5C3.67441 0.00160525 2.40356 0.528899 1.46622 1.46624C0.528882 2.40358 0.00158786 3.67442 0 5.00002V15C0.00158786 16.3256 0.528882 17.5965 1.46622 18.5338C2.40356 19.4711 3.67441 19.9984 5 20H11ZM2 15V5.00002C2 4.20437 2.31607 3.44131 2.87868 2.8787C3.44129 2.31609 4.20435 2.00002 5 2.00002C5 2.00002 9.919 2.01402 10 2.02402V4.00002C10 4.53045 10.2107 5.03916 10.5858 5.41423C10.9609 5.7893 11.4696 6.00002 12 6.00002H13.976C13.986 6.08102 14 15 14 15C14 15.7957 13.6839 16.5587 13.1213 17.1213C12.5587 17.6839 11.7956 18 11 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7957 2 15ZM20 8.00002V19C19.9984 20.3256 19.4711 21.5965 18.5338 22.5338C17.5964 23.4711 16.3256 23.9984 15 24H6C5.73478 24 5.48043 23.8947 5.29289 23.7071C5.10536 23.5196 5 23.2652 5 23C5 22.7348 5.10536 22.4804 5.29289 22.2929C5.48043 22.1054 5.73478 22 6 22H15C15.7956 22 16.5587 21.6839 17.1213 21.1213C17.6839 20.5587 18 19.7957 18 19V8.00002C18 7.7348 18.1054 7.48045 18.2929 7.29291C18.4804 7.10537 18.7348 7.00002 19 7.00002C19.2652 7.00002 19.5196 7.10537 19.7071 7.29291C19.8946 7.48045 20 7.7348 20 8.00002Z" fill="white" />
                                                </svg>
                                            </span>
                                        </div>
                                        <p className={classes.errors}>{formErrors}</p>
                                    </div>
                                </div>
                                <div className='fyers_dec'>
                                    <p>Fyers expects the user to login here everyday to renew their API token. If not done, the trades will get rejected.</p>
                                    <p><span>https://www.moneyfactory.ai/api/fyersbroker/redirectUrl </span> Click here for Fyers configuration instructions</p>
                                </div>
                                <div className={classes.buttons}>
                                    <button className={classes.cancel} onClick={close}> Cancel </button>
                                    <button className={classes.save} onClick={handleSubmit}>
                                        {' '}
                                        Save{' '}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </div>
    );
};

export default FyersBrokerDetails;
