import React, { useEffect, useState } from 'react';
import BlogContent from '../../components/BlogDetails/BlogContent';
import PopularBlogs from '../../components/BlogDetails/PopularBlogs';
import Navbar from '../../components/Navbar/Navbar';
import classes from './blogDetail.module.css';
import { Container } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';
import Subscribe from '../../components/Subscribe';
const BlogURL = `https://www.moneyfactory.ai/st`;
// const BlogURL = 'http://3.111.117.212:1337';
const BlogBaseURL = 'https://www.moneyfactory.ai';
// const BlogBaseURL = 'http://3.111.117.212:1337';
function BlogDetailsPage(props) {

  const [blog, setBlog] = useState({});

  useEffect(() => {
    fetchBlogs()
  },[])

  const fetchBlogs = () => {
    if(props.match.params && props.match.params?.id) {
      axios.get(`${BlogURL}/api/blogs/${props.match.params.id}?populate=*`).then(res => {
        if(res.data && res.data.data && res.data.data) {
          setBlog(res.data.data);
        }else{
          setBlog({})
        }
      }).catch(err => {
        console.log(err);
        setBlog({})
      })
    }
  }

  return (
    <div className={classes.blogContainer}>
      <Navbar />
      <Container>
        <BlogContent BlogBaseURL={BlogBaseURL} BlogURL={BlogURL} blog={blog} />
        {/* <PopularBlogs BlogBaseURL={BlogBaseURL} BlogURL={BlogURL} blog={blog} /> */}
      </Container>
      <Footer joinWithUs={false} childComponent={<Subscribe />}/>
    </div>
  );
}

export default BlogDetailsPage;
