import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import classes from './Process.module.css';
import signUp from '../../assets/signUp.svg';
import noHiddenCharges from '../../assets/noHiddenCharges.png';
import investingAssistance from '../../assets/investingAssistance.png';
const investingMode = [
  {
    img: signUp,
    alt: 'Sign up to join',
    title: 'Sign up to join',
    bgColor: '#104F55',
    content: `Your network can use your affiliate link to sign up & subscribe. It's that simple`
  },
  {
    img: noHiddenCharges,
    alt: 'No hidden charges',
    title: 'No hidden charges',
    bgColor: '#263238',
    content: `Signing up is completely free and you can unsubscribe from plans any time, no questions asked.`
  },
  {
    img: investingAssistance,
    alt: 'Complete investing assistance',
    title: 'Complete investing assistance',
    bgColor: '#263238',
    content:
      'No need to fumble around in the dark, learn how to invest and then grow your money.'
  }
];

function Process() {
  return (
    <div className={classes.process}>
      <Container>
        <h2>
          We’ve Made It
          <span className={classes.subHeading}> Easy for Your </span>
          Network as Well
        </h2>
        <p className={classes.subHeading2}>No lengthy procedures, no fees.</p>
        <Row className={classes.cardRow}>
          {investingMode.map((x, k) => {
            return (
              <Col key={k + 1} md={4} lg={4} xs={12} sm={12}>
                <Card className={classes.investCard}>
                  <Card.Body>
                    <ScrollAnimation animateIn="flipInX">
                      <div
                        style={{ backgroundColor: x.bgColor }}
                        className={classes.cardImgContainer}
                      >
                        <img
                          src={x.img}
                          style={{
                            maxWidth: '100%'
                          }}
                          alt={x.alt}
                          className={classes.investCardImgs}
                        />
                      </div>
                    </ScrollAnimation>
                    <Card.Text>
                      <h4 className={classes.investTitle}>{x.title}</h4>
                      <div className={classes.investContent}>{x.content}</div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Process;
