import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import classes from './BrokerDetails.module.css';
import swal from 'sweetalert';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const _5PaisaBrokerDetails = ({ details }) => {
    const [formValues, setFormValues] = useState({
        userId: details.userId || "",
        ukey: details.userKey || "",
        ekey: details.encryptionKey || "",
        clientCode: details.clientCode || ""
    });
    const [formErrors, setFormErrors] = useState('');
    var id = JSON.parse(localStorage.getItem('MF_user')).id;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const addcreds = async () => {
        fetch(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/fivepaisabroker/addbroker`,
            {
                method: 'POST',
                body: JSON.stringify({
                    loggedInUserId: id,
                    brokerUserId: formValues.userId,
                    userKey: formValues.ukey,
                    encryptionKey: formValues.ekey,
                    clientCode: formValues.clientCode
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        )
            .then((response) => {
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = 'Error in creating';
                    swal({
                        title: 'Error',
                        text: error,
                        icon: 'error',
                        timer: 3000
                    }).then(() => { });
                    return Promise.reject(response);
                }
                return response.json();
            })
            .then((json) => {
                swal({
                    title: 'Broker added!',
                    text: 'broker has been added.',
                    icon: 'success',
                    timer: 3000
                })
                window.location.href = `/profile?selected=4`;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate(formValues)) {
            addcreds();
        }
        else {
            setFormErrors('Field cannot be empty');
        }
    };

    const validate = (values) => {
        if (
            !values.userId ||
            !values.ukey ||
            !values.clientCode ||
            !values.ekey
        ) {
            return false;
        }
        return true;
    };

    const contentStyle = {
        background: '#1d1d1d',
        maxWidth: '60vw',
        width: '100%'
    };
    const overlayStyle = {
        background: 'rgba(0,0,0,0.5)',
        overflowY: 'auto'
    };
    //   const arrowStyle = { color: '#fff' }; // style for an svg element

    return (
        <div className='d-flex'>
            <a
                    href={`https://dev-openapi.5paisa.com/WebVendorLogin/VLogin/Index?VendorKey=3CZ1sorg0Bw7QYMAbXu0IK1Nbpfj0YUl&ResponseURL=${process.env.REACT_APP_BACKEND_BASE_URL}/api/fivepaisabroker/refresh&State=${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                ><div className={classes.btn}>
                Authorize</div></a>
            {/* <Popup
                trigger={<div className={classes.btn}>{details.userId ? "Edit" : "Add"}</div>}
                modal
                {...{
                    contentStyle,
                    overlayStyle
                }}
            >
                {(close) => (
                    <div className={classes.modal}>
                        <div className={classes.header}>
                            <div className={classes.header_text}>Connect to Broker</div>
                            <div className={classes.close} onClick={close}>
                                <span> &times; </span>
                            </div>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.inputs}>
                                <div>
                                    <CssTextField
                                        label="User Id"
                                        id="custom-css-outlined-input"
                                        InputLabelProps={{
                                            style: { color: '#fff' }
                                        }}
                                        // multiline={true}
                                        className={classes.first_tf}
                                        onChange={handleChange}
                                        name="userId"
                                        value={formValues.userId}
                                        defaultValue={formValues.userId}
                                    />
                                    <p className={classes.errors}>{formErrors}</p>
                                </div>
                                <div>
                                    <CssTextField
                                        label="User Key"
                                        id="custom-css-outlined-input"
                                        InputLabelProps={{
                                            style: { color: '#fff' }
                                        }}
                                        // multiline={true}
                                        className={classes.first_tf}
                                        onChange={handleChange}
                                        name="ukey"
                                        value={formValues.ukey}
                                    />
                                    <p className={classes.errors}>{formErrors}</p>
                                </div>
                            </div>
                            <div className={classes.inputs}>
                                <div>
                                    <CssTextField
                                        label="Encryption Key"
                                        id="custom-css-outlined-input"
                                        InputLabelProps={{
                                            style: { color: '#fff' }
                                        }}
                                        // multiline={true}
                                        className={classes.first_tf}
                                        onChange={handleChange}
                                        name="ekey"
                                        value={formValues.ekey}
                                    />
                                    <p className={classes.errors}>{formErrors}</p>
                                </div>
                                <div>
                                    <CssTextField
                                        label="Client Code"
                                        id="custom-css-outlined-input"
                                        InputLabelProps={{
                                            style: { color: '#fff' }
                                        }}
                                        // multiline={true}
                                        className={classes.first_tf}
                                        onChange={handleChange}
                                        name="clientCode"
                                        value={formValues.clientCode}
                                    />
                                    <p className={classes.errors}>{formErrors}</p>
                                </div>
                            </div>
                            <div className={classes.buttons}>
                                <button className={classes.cancel} onClick={close}> Cancel </button>
                                <button className={classes.save} onClick={handleSubmit}>{' Save '}</button>
                            </div>
                        </div>
                    </div>
                )}
            </Popup> */}
        </div>
    );
};

export default _5PaisaBrokerDetails;
