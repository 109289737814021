import React, { useEffect, useRef } from 'react';
import classes from './QuantCard.module.css';
import Zeus from '../../../assets/Zeus.svg';
import Like from '../../../assets/like.svg';
import Dislike from '../../../assets/dislike.svg';
import Add from '../../../assets/Add.svg';
import Play from '../../../assets/play.svg';
import Arrow from '../../../assets/arrow.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import VanillaTilt from 'vanilla-tilt';

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

function QuantCard({
  categoryTitle,
  quantId,
  title,
  image,
  about,
  difficulty,
  ROI,
  color,
  categoryId
}) {
  const options = {
    scale: 1.1,
    speed: 1500,
    max: 30
  };

  return (
    <div className={`${classes.quantCard}`}>
      <div className={classes.image_container}>
        <Tilt className="box" options={options}>
          <Link
            to={{
              pathname: `/description/${quantId}`,
              state: { categoryId, categoryTitle }
            }}
          >
            <img src={image ? image : Zeus} alt="" />
          </Link>
        </Tilt>
      </div>
      <h4>{title}</h4>
      <p>{about}</p>
      <h5>₹ {ROI}</h5>
      <div className={classes.hidden}>
        <div>
          <img src={Play} alt="" />
        </div>
        <div>
          <img src={Add} alt="Add Quant" />
        </div>
        <div>
          <img src={Like} alt="Like" />
        </div>
        <div>
          <img src={Dislike} alt="Dislike" />
        </div>
        <div>
          <Link
            to={{
              pathname: `/description/${quantId}`,
              state: { categoryId, categoryTitle }
            }}
          >
            <img src={Arrow} alt="Arrow" />
          </Link>
        </div>
      </div>
      <div className={classes.difficulty} style={{ backgroundColor: color }}>
        {difficulty}
      </div>
    </div>
  );
}

export default QuantCard;
