export const HomeStyle = {
  tabWrapper: {
    borderBottom: 1,
    borderColor: 'divider',
    '.MuiTabs-indicator': {
      backgroundColor: '#00D37F !important',
      width: '34px !important',
      height: '4px !important',
      borderRadius: '10px 10px 0px 0px !important'
    },
    '.MuiButtonBase-root': {
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '27px',
      color: '#FFFFFF !important',
      paddingLeft: 0
    },
    '.Mui-selected': {
      color: '#00D37F !important',
      paddingLeft: 0
    },
    '.MuiButtonBase-root:hover': {
      boxShadow: 'none !important',
    },
    '.Mui-selected:hover': {
      boxShadow: 'none !important'
    }
  },

  sliderWrapper: {
    '.MuiSlider-track': {
      border:'6px solid #00D37F !important',
      backgroundColor: '#00D37F'
    },
    '.MuiSlider-rail': {
      border: '6px solid #123C41 !important',
      backgroundColor: '#123C41 !important'
    },
    '.MuiSlider-thumb': {
      backgroundColor: '#FFFFFF',
      // border: '2px solid #00D37F'
    }
  },

  rating:{
    '.MuiRating-iconEmpty': {
      color: 'white'
    }
  }
};
