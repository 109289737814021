import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar';
import AddBroker from '../../components/ProfileSettings/AddBroker/AddBroker';
import BillingDetails from '../../components/ProfileSettings/BillingDetails/BillingDetails';
import EditProfile from '../../components/ProfileSettings/EditProfile/EditProfile';
import Feedback from '../../components/ProfileSettings/Feedback/Feedback';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import classes from './SubscriptionPlans.module.css';
import swal from 'sweetalert';
import MyPayments from '../../components/ProfileSettings/MyPayments/MyPayments';
import OrderBook from '../../components/ProfileSettings/OrderBook/OrderBook';
import { Report } from '@mui/icons-material';
import Plans from '../../components/MarketPlace/NewBillingCard/Plans';

const SubscriptionPlan = () => {
  const history = useHistory();
  const [user, setUser] = useState('');
  useEffect(() => {
    if (localStorage.getItem('MF_user')) {
      setUser(JSON.parse(localStorage.getItem('MF_user')));
    } else {
      swal({
        title: 'Please login to your account to continue!',
        text: 'you will be redirected to Sign in page in 3 seconds',
        icon: 'warning',
        timer: 3000
      }).then(() => {
        setTimeout(() => {
          history.push('/signin');
        }, 750);
      });
    }
  }, []);

  const [selected, setSelected] = useState(0);
  return (
    <>
      {user ? (
        <div>
          <Sidebar>
            {/* <BillingDetails /> */}
            <Plans />
          </Sidebar>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SubscriptionPlan;
