/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classes from './NewDeployedBar.module.css';
import wifi from '../../../assets/wifi.svg';
import wifiS from '../../../assets/wifi_selected.svg';
import doc from '../../../assets/doc.svg';
import RA from '../../../assets/right_arrow.svg';
import box from '../../../assets/box.svg';
import V from '../../../assets/verified.svg';
import sm from '../../../assets/stock_market.png';

function NewDeployedBar({ myQuants }) {
  return (
    <div className={classes.db_container}>
      <div className={classes.db_subcontent}>
        <div className={classes.db_title}>
          <h4>Deployed</h4>
        </div>
        <div className={classes.db_subcontent1}>
          <div className={classes.db_tile}>
            <img
              src={myQuants.length == 0 ? wifi : wifiS}
              alt="wifi"
              width="40"
              height="40"
            />
            <div className={classes.sub_text}>
              <p>Live Trades</p>
            </div>
          </div>
          <div className={classes.db_tile}>
            <img src={doc} alt="stimulated task" width="40" height="40" />
            <div className={classes.sub_text}>
              <p>Stimilated Trades</p>
            </div>
          </div>

          <div className={classes.db_tile}>
            <img src={box} alt="box" width="40" height="40" />
            <div className={classes.sub_text}>
              <p> Open Positions </p>
            </div>
          </div>
          <div className={classes.db_tile}>
            <img src={V} alt="stimulated task" width="40" height="40" />
            <div className={classes.sub_text}>
              <p>Closed Positions</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.db_subcontent1}>
        <img src={sm} alt="" />
      </div>
      <div className={classes.notifications}>
        <div className={classes.initial}>
          <h4>Notifications</h4>
          <a href="#" target="_blank" rel="noopener noreferrer">
            view all
          </a>
        </div>
        <div className={classes.list}>
          <li>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </li>
          <hr className={classes.line} />
          <li>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </li>
        </div>
      </div>
    </div>
  );
}

export default NewDeployedBar;
