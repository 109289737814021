import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar';
import Card from './card';
import pro from '../../assets/pro.png';
import virtual from '../../assets/virtual.png';
import growth from '../../assets/growth.png';
import starter from '../../assets/starter.png'
import Step from './steps';
import chooseQuant from '../../assets/chooseQuant.svg';
import selectPortfolio from '../../assets/selectPortfolio.svg';
import selectStock from '../../assets/selectStock.svg';
import getAlerts from '../../assets/getAlerts.svg';
import linkBroker from  '../../assets/linkBroker.svg';
import subscribe from '../../assets/subscribe.svg';
import growIcon from '../../assets/growIcon.svg';

function SetUp() {
    let cancel = false;
    const [name, setName] = useState('Test');
    const [plan, setPlan] = useState({});

    useEffect(() => {
        if (cancel) return;
        setName(JSON.parse(localStorage.getItem('MF_user')).name);
        return () => {
            cancel = true;
        };
    }, []);

    // const chart = useRef();


    return (
        <>
            <Sidebar>
                {Object.keys(plan).length === 0 && <div className='dashbord_main_wrapper' style={{ minHeight: 'calc(100vh - 70px)' }}>
                    <h4 className='dashbord_name_title'>Hello, {name}</h4>
                    <h6 style={{ textAlign: 'start' }}>Let's start building your portfolio</h6>
                    <div className='row'>
                        <div className='col-xl-6'>
                            <Card
                                img={virtual}
                                title='Virtual Investor'
                                subtitle='Understand how to start making profits with MoneyFactory using this simulated mode (no actual money involved).'
                                link={()=>{setPlan({link:'/welcome', 
                                img:[selectPortfolio,chooseQuant,selectStock,getAlerts],
                                title:'Virtual Investor', 
                                subtitle:'Become an investing pro before you even put in a single rupee with our Virtual Investor mode.'});}}
                            />
                            <Card
                                img={growth}
                                title='Growth Plan'
                                subtitle='Serious about growing your portfolio? Invest in high-performing Quants for higher returns.'
                                link={()=>{setPlan({link:'/discover', 
                                img:[linkBroker,subscribe,selectStock,growIcon],
                                title:'Growth Plan', 
                                subtitle:'Investing isn\'t just for big budgets. With our Starter Plan you can start with as little as Rs 100 per day.\nPut your money in high-performing quants and safely switch from one to the other with the least possible risk.'});}}
                            />
                        </div>
                        <div className='col-xl-6'>
                            <Card
                                img={starter}
                                title='Starter Plan'
                                subtitle='Start investing with as little as Rs 100 per day and see your money grow.'
                                link={()=>{setPlan({link:'/discover', 
                                img:[linkBroker,subscribe,selectStock,growIcon],
                                title:'Starter Plan', 
                                subtitle:'Investing isn\'t just for big budgets. With our Starter Plan you can start with as little as Rs 100 per day.\nPut your money in high-performing quants and safely switch from one to the other with the least possible risk.'});}}
                            />
                            <Card
                                img={pro}
                                title='Pro Plan'
                                subtitle='Not afraid to aim for higher rewards? Leverage the power of Quants for smarter investing'
                                link={()=>{setPlan({link:'/discover', 
                                img:[linkBroker,subscribe,selectStock,growIcon],
                                title:'Pro Plan', 
                                subtitle:'Investing isn\'t just for big budgets. With our Starter Plan you can start with as little as Rs 100 per day.\nPut your money in high-performing quants and safely switch from one to the other with the least possible risk.'});}}
                            />
                        </div>
                    </div>
                </div>}
                {Object.keys(plan).length !== 0 && <Step {...plan}/>}
            </Sidebar>
        </>
    );
}

export default SetUp;





