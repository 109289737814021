import React, { useEffect, useState } from 'react';
import classes from '../MyQuants/MyQuants.module.css';
import Sidebar from '../../components/MarketPlace/Sidebar/Sidebar.jsx';
import my_order_men from '../../assets/my_order_men.png';
import moneyFactory from '../../assets/logo.ico';
import { Divider } from '@mui/material';

function OrderApprove() {
    const [transactionList, setTransactionList] = useState([]);
    const [BrokerList, setBrokerList] = useState({});

    const failorder = async (data, message) => {
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/smallcase/transaction`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                status: 'error',
                user: data.user,
                signal_id: data.signal_id,
                quantId: data.quantId,
                signal: data.transaction,
                mode: data.mode,
                symbol: data.symbol,
                message: message
            })
        }).then((res) => {
            return res.json();
        }).then((res) => {
            console.log('order details updated successful');
        })
            .catch(err => {
                console.log(err);
            })
    }

    const order = async (data) => {
        let result = {};
        const brokerID = BrokerList[data.brokerName] ? BrokerList[data.brokerName] : ''
        await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/smallcase/createTransaction`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                savedBrokerID: brokerID,
                symbol: data.symbol,
                qty: data.quantity,
                signal: data.transaction.signal ? 'BUY' : 'SELL'
            })
        }).then((res) => {
            return res.json();
        })
            .then((res) => {
                result = res;
            })
            .catch(err => {
                console.log(err);
            })
        console.log(result)
        const gatewayInstance = new window.scDK({
            gateway: "moneyfactory",
            smallcaseAuthToken: result.authToken,
            config: {
                amo: true
            }
        });
        gatewayInstance.triggerTransaction({
            // ! Use valid transactionId here
            transactionId: result.transaction_id
        })
            .then(txnResponse => {
                fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/smallcase/transaction`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...txnResponse,
                        user: data.user,
                        signal_id: data.signal_id,
                        quantId: data.quantId,
                        signal: data.transaction,
                        mode: data.mode
                    })
                }).then((res) => {
                    return res.json();
                }).then((res) => {
                    console.log('order details updated successful')
                })
                    .catch(err => {
                        console.log(err);
                    })
                if (brokerID == '') {
                    const authid = JSON.parse(Buffer.from(txnResponse.smallcaseAuthToken.split('.')[1], 'base64').toString());
                    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/smallcase/addbroker`, {
                        method: 'POST',
                        mode: 'cors',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            user: data.user,
                            smallcaseAuthId: authid.smallcaseAuthId,
                            name: txnResponse.broker,
                            quantId: data.quantId
                        })
                    }).then((res) => {
                        return res.json();
                    }).then((res) => {
                        const gateway = new window.scDK({
                            gateway: "moneyfactory",
                            smallcaseAuthToken: res.authToken,
                            config: {
                                amo: true
                            }
                        });
                        gateway.triggerTransaction({
                            // ! Use valid transactionId here
                            transactionId: res.transaction_id
                        })
                            .then(txnResponse => {
                                console.log('received response:', txnResponse);
                            });
                    })
                        .catch(err => {
                            console.log(err);
                        })
                }
            })
            .catch(err => {
                failorder(data, err.message);
            });

    }
    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem("MF_user")).id;
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/stock/stockOrders/${userId}`)
            .then((res) => {
                if (!res.ok) {
                    // get error message from body or default to response status
                    const error = 'Server error';
                    return Promise.reject(error);
                }
                return res.json();
            })
            .then((res) => {
                setTransactionList(res.data);
            }).catch((err) => {
                console.log(err);
            })
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/smallcase/savedbroker/${userId}`)
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                setBrokerList(res.brokers || {});
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <Sidebar>
            <div className={classes.Myquant}>
                <div>
                    <div className={classes.heading}>
                        <h4>Open Orders</h4>
                    </div>
                    {transactionList?.map((ele) => {
                        return <div className='available_balance_wrap'>
                            <div className='available_balance_box' style={{ width: "100%", display: "block" }}>
                                <div style={{ display: 'flex', justifyContent: "space-evenly" }}>
                                    <div className='available_balance_box_image'>
                                        <img src={my_order_men} alt="icon" />
                                    </div>
                                    <div className='available_balance_details' style={{ margin: "10px" }}>
                                        <h5><b>Zeus</b></h5>
                                    </div>
                                    <div className='available_balance_details' style={{ margin: "10px" }}>
                                        <h5>Mode</h5>
                                        <h5><b>{ele.mode}</b></h5>
                                    </div>
                                    <div className='available_balance_details' style={{ margin: "10px", color:"white" }}>
                                        <img src={moneyFactory} alt='Broker' />MF broker
                                    </div>
                                    <div className='available_balance_details' style={{ margin: "10px" }}>
                                        <h5>{ele.symbol}</h5>
                                        <h5>Quantity: <b>{ele.quantity}</b></h5>
                                    </div>
                                    <div className='available_balance_details' style={{ margin: "10px" }}>
                                        <h5><b>&#8377;{ele.transaction.entry_price}</b></h5>
                                    </div>
                                </div>
                                <Divider sx={{color:'#333333'}}/>
                                <div style={{ display: 'flex' }}>
                                    <div className='available_balance_details' style={{margin:'15px'}}>
                                        <button style={{ height:'50px', width:'180px', backgroundColor:'#1E383C', color:'white', borderRadius:'6px' }} onClick={(e) => { failorder(ele, 'user skiped') }}>Skip</button>
                                    </div>
                                    <div className='available_balance_details'style={{ margin: "15px" }}>
                                        <button style={{ height:'50px', width:'180px', backgroundColor:'#00d37f', color:'white', borderRadius:'6px' }} onClick={(e) => { order(ele) }}>{ele.transaction.signal ? 'BUY' : 'SELL'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </Sidebar>
    )
}

export default OrderApprove