import React, { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import leftIconSmit from '../../assets/leftIconSmit.svg';
import rightIconSmit from '../../assets/rightIconSmit.svg';
import classes from './Siat.module.css';
import vectorImg1 from '../../assets/vectorImg1.svg';
import vectorImg2 from '../../assets/vectorImg2.svg';
import vectorImg3 from '../../assets/vectorImg3.svg';

// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

const responsive = {
  // when window width is >= 464 px
  464: {
    slidesPerView: 2.5
  },
  1024: {
    slidesPerView: 3.2
  },
  3000: {
    slidesPerView: 4
  }
};

const investmentData = [
  {
    img: vectorImg1,
    alt: '""',
    title: '""',
    content: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
  },
  {
    img: vectorImg2,
    alt: '""',
    title: '""',
    content: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
  },
  {
    img: vectorImg3,
    alt: '""',
    title: '""',
    content: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
  }
];

function Siat() {
  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Autoplay]);
  return (
    <div className={classes.siat_container}>
      <div className={classes.investFactorDiv}>
        <div className={`position-relative ${classes.rightFluid}`}>
          {swiper ? (
            <SwiperActionBar
              ItemsLength={investmentData.length}
              swiper={swiper}
            />
          ) : null}
          <Swiper
           
            slidesPerView={1}
            loop={true}
            autoplay={true}
            breakpoints={responsive}
            watchOverflow
            spaceBetween={30}
            onSwiper={(sw) => setSwiper(sw)}
          >
            {investmentData.map((x, k) => {
              return (
                <SwiperSlide className={classes.vectorCard}>
                  <Card
                    style={{ backgroundColor: 'transparent', border: 'none' }}
                  >
                    <Card.Body>
                      <div>
                        <img src={x.img} alt="" style={{maxWidth:"100%"}} />
                      </div>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

const SwiperActionBar = ({ ItemsLength, swiper }) => {
  const [disableNext, setDisableNext] = useState(
    swiper.activeIndex + Number(swiper.params.slidesPerView) >= ItemsLength
  );
  const [disablePrev, setDisablePrev] = useState(swiper.activeIndex <= 0);
  const nextSlide = () => {
    swiper.slideNext();
    setDisableNext(
      swiper.activeIndex + Number(swiper.params.slidesPerView) >= ItemsLength
    );
    setDisablePrev(swiper.activeIndex <= 0);
  };

  const prevSlide = () => {
    swiper.slidePrev();
    setDisableNext(
      swiper.activeIndex + Number(swiper.params.slidesPerView) - 1 >=
        ItemsLength
    );
    setDisablePrev(swiper.activeIndex - 1 <= 0);
  };

  return (
    <div className={classes.swiperActionBar}>
      {/* <button onClick={() => prevSlide()} disabled={disablePrev}> */}
      {/* &lt; */}
      {/* <img className={classes.rightIconSmit} src={rightIconSmit} alt="prev" /> */}
      {/* </button> */}
      {/* <br /> */}
      {/* <br /> */}
      {/* <button onClick={() => nextSlide()} disabled={disablePrev}> */}
      {/* &gt; */}
      {/* <img className={classes.leftIconSmit} src={leftIconSmit} alt="next" /> */}
      {/* </button> */}
    </div>
  );
};

export default Siat;
