import React, { useEffect, useState } from 'react';
import './subscribedquants.css';
import classes from '../../MarketPlace/ActiveQ/ActiveQuantsCard/ActiveQuantsCard.module.css'
import { Link } from 'react-router-dom';

const SubscribedQuants = () => {
  const [myQuants,setMyQuants]=useState([]);
  useEffect(()=>{
    const userId=JSON.parse(localStorage.getItem("MF_user")).id;
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/quants/getsubscribequants/${userId}`)
    .then((res)=>{
        if (!res.ok) {
            // get error message from body or default to response status
            const error = 'Server error';
            return Promise.reject(error);
          }
        return res.json();
        })            
    .then((res)=>{
        setMyQuants(res);
    }).catch((err)=>{
        console.log(err);
    })
  },[])
  return (
    <div style={{padding: '16px'}}>
        {myQuants && myQuants.subQts && myQuants.subQts.map((qua)=>{return <Link to={{
              pathname: `/description/${qua.quant._id}`
            }}><div style={{float:'left'}} className={`${classes.aq_card} active_card_info`}>
      <img
        src={qua.quant.imgUrl}
        alt=""
        className="ac_quants_wrap"
      />
      <div className={`${classes.aq_cardtitle} ac_quants_name`}>
        {qua.quant.name}
      </div>
      {/* <div>
        { qua.quant.stocks.map((i)=>{
          return <span style={{fontSize:'8px'}}>{i.symbol}<br/></span>
        })}
      </div> */}
    </div></Link>})}
    </div>
  )
}

export default SubscribedQuants