import React from 'react';
import Navbar from '../Navbar/Navbar';
import classes from './subscribe.module.css';
import MBgrad from '../MBgrad/MBgrad';
import IMRE from '../IMRE/IMRE';
import Siat from '../SIAT/Siat';
import Eibp from '../EIBP/Eibp';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Accordion, Container, Form, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import Ellips from '../../assets/Ellipse62.png';

function Subscribe() {
  return (
    <div className={classes.subscribe_container}>
      <Container>
        <div className={classes.subscribe_inner_container}>
          <Row>
            <Col md={6} lg={6} xs={12} sm={12}>
              <div className={classes.textSection}>
                <h2>Subscribe to weekly finance,<br/> business and market updates</h2>
                <p>Investment insights, news & views delivered to your inbox</p>
              </div>
            </Col>
            <Col md={6} lg={6} xs={12} sm={12}>
            <div className={classes.inputWrapper}><div><input type="email" placeholder="Enter Email Address" className={classes.subscribeInput}/><button className={classes.subscribeBtn}>Subscribe Now</button></div></div>
              {/* <div className={classes.imgWrapper}>
                <img src={Ellips} alt="elips" className={classes.btnImg} />
                <button className={`button ${classes.homeBtn}`}>
                  Join With Us
                </button>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Subscribe;
