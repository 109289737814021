import React, { useState, useEffect } from 'react';
import classes from './Feedback.module.css';
import swal from 'sweetalert';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#00D37F'
  },
  '&:hover .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#00D37F'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#333333'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00D37F'
    }
  },
  '& label.Mui-root': {
    color: 'white'
  },
  '& .MuiInputBase-root': {
    color: 'white'
  }
});

const Feedback = () => {
  const [input, setInput] = useState('Enter your suggestion here...');
  const [isSubmit, setisSubmit] = useState(false);

  function handleSubmit() {
    setisSubmit(true);
  }

  useEffect(() => {
    if (input.trim !== '' && input !== 'Enter your suggestion here...')
      fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/profile/capture/feedback`,
        {
          method: 'POST',
          body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('MF_user')).id,
            comment: input
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        }
      )
        .then((response) => response.json())
        .then((json)=>{
          swal({
            title: 'Feedback added!',
            text: 'feedback has been added.',
            icon: 'success',
            timer: 3000
          }).then(() => {
          });
        
        })
        ;
  }, [isSubmit]);

  return (
    <div className={classes.inputs}>
      <div>
        <CssTextField
          label="Feedback"
          id="custom-css-outlined-input"
          InputLabelProps={{
            style: { color: '#fff' }
          }}
          multiline={true}
          className={classes.first_tf}
          onChange={(e) => setInput(e.target.value)}
          name="name"
          rows={10}
          maxRows={18}
          value={input}
        />
        <p className={classes.errors}>
          {/* {quantErrors.name} */}
          {/* hihi */}
        </p>
      </div>
      <div className={classes.buttons}>
        <button className={classes.cancel}> Cancel </button>
        <button className={classes.save} onClick={handleSubmit}>
          {' '}
          Save{' '}
        </button>
      </div>
    </div>
  );
};

export default Feedback;
