import React, { useState, useEffect } from 'react';
import classes from './ABCard.module.css';
import AB from '../../../assets/AB.svg';
import './ABCarddetails.css'

function ABCard({ i }) {
    const [total_balance, settotal_balance] = useState(0);
    const [avMargin, setavMargin] = useState(0);
    const [grMargin, setgrMargin] = useState(0);
    useEffect(() => {
        let cancel = false;

        var id = JSON.parse(localStorage.getItem('MF_user')).id;
        if (i == 1) {
            fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/fivepaisabroker/account/margins/${id}`
            )
                .then((res) => {
                    if (cancel) return;
                    if (res.ok) {
                        return res.json();
                    }
                })
                .then((data) => {
                    settotal_balance(data.data.EquityMargin[0].ALB);
                    setavMargin(data.data.EquityMargin[0].AvailableMargin);
                    setgrMargin(data.data.EquityMargin[0].GrossMargin);
                });
        } else if (i == 2) {
            fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/api/angelonebroker/account/margins/${id}`
            )
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                })
                .then((data) => {
                    settotal_balance(data.data.EquityMargin[0].ALB);
                    setavMargin(data.data.EquityMargin[0].AvailableMargin);
                    setgrMargin(data.data.EquityMargin[0].GrossMargin);
                });
        }

        return () => {
            cancel = true;
        };
    }, []);

    // eslint-disable-next-line no-useless-concat
    // let price = '₹' + `${total_balance}`;
    let nullprice = '₹ 0.00';
    return (
        <div className={classes.ab_card}>
            <div className={classes.ab_header}>{i == 0 ? '' : (i == 1 ? '5paisa' : 'AngelOne')}</div>
            <div className={classes.inner_card}>
                <div className={classes.image}>
                    <img src={AB} alt="available balance" />
                </div>
                <div className={classes.text}>
                    <h5>Available Balance</h5>
                    <h6 style={{ fontSize: '1rem' }}>₹ {total_balance}</h6>
                </div>
            </div>
            <div className={classes.acc_mr}>
                <div className={classes.h6down_section}>
                    <h6 style={{ fontSize: '1.1rem' }}>₹ {avMargin}</h6>
                    <h5>Account</h5>
                </div>
                <div className={classes.h5down_section}>
                    <h6 style={{ fontSize: '1.1rem' }}>{nullprice}</h6>
                    <h5>Margin</h5>
                </div>
            </div>
        </div>
    );
}

export default ABCard;
