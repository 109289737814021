import React, { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import quant from '../../assets/home/quant.svg';
import moneybag from '../../assets/home/moneybag.svg';
import guarantee from '../../assets/home/guarantee.svg';
import affordability from '../../assets/home/affordability.svg';
import leftIconSmit from '../../assets/home/leftIconSmit.svg';
import rightIconSmit from '../../assets/home/rightIconSmit.svg';
import classes from './Siat.module.css';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

const responsive = {
  // when window width is >= 464 px
  464: {
    slidesPerView: 2.5
  },
  1024: {
    slidesPerView: 3.2
  },
  3000: {
    slidesPerView: 4
  }
};

const investmentData = [
  {
    img: quant,
    alt: 'Quant based Investing',
    title: 'QUANT-BASED INVESTING',
    content:
      'Personalised portfolio recommendations based on the best- performing stocks (first time in India)'
  },
  {
    img: moneybag,
    alt: 'Financial empowerment',
    title: 'FINANCIAL EMPOWERMENT',
    content:
      'AI-powered investment strategies to maximise your profits and help you find the right opportunities'
  },
  {
    img: guarantee,
    alt: 'Trust and Convenience',
    title: 'TRUST & CONVENIENCE',
    content:
      'Automated systems to help you make the best financial decisions without spending hours trying to understand the market'
  },
  {
    img: affordability,
    alt: 'Affordability',
    title: 'AFFORDABILITY',
    content:
      'No expensive investment advisors or commissions. Just affordable subscription plans to take care of your financial needs.'
  }
];

function Siat() {
  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Autoplay]);
  return (
    <div className={classes.siat_container}>
      <div className={classes.siat_container_vector}>
        <Container>
          <Row>
            <Col md={12} xs={12} sm={12} lg={12}>
              <ScrollAnimation duration={2} animateIn="fadeIn">
                <h2 style={{ animation: 'fadeIn 5s', transition: 'all 2s' }}>
                  THE SMARTEST INVESTMENT
                  <br />{' '}
                  <span className={classes.subHeading}>ASSISTANT IN TOWN</span>
                </h2>
              </ScrollAnimation>
              <p>Say hello to the new way of investing with MoneyFactory:</p>
            </Col>
            {/* <Col className={classes.investImg} md={6} xs={12} sm={12}>
          <img src={smartInvestAssis} alt="assistant"/>
        </Col> */}
          </Row>
        </Container>

        <div className={classes.investFactorDiv}>
          <div className={`position-relative ${classes.rightFluid}`}>
            {swiper ? (
              <SwiperActionBar
                ItemsLength={investmentData.length}
                swiper={swiper}
              />
            ) : null}
            <Swiper
              slidesPerView={1}
              loop={true}
              autoplay={true}
              breakpoints={responsive}
              watchOverflow
              spaceBetween={30}
              onSwiper={(sw) => setSwiper(sw)}
            >
              {investmentData.map((x, k) => {
                return (
                  <SwiperSlide>
                    {/* <Col key={k + 1} md={6} xs={12} sm={12} lg={3}> */}
                    {/* <div className={classes.investFactorCard}>
                <img src={x.img} alt="invest"/>
                <div className={classes.investTitle}>{x.title}</div>
                <div className={classes.investContent}>{x.content}</div>
              </div> */}
                    <Card className={classes.investFactorCard}>
                      <Card.Body>
                        <Card.Text className={`pt-0`}>
                          <img
                            src={x.img}
                            alt={x.alt}
                            className={classes.investImg}
                          />
                          <h4 className={classes.investTitle}>{x.title}</h4>
                          <div className={classes.investContent}>
                            {x.content}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    {/* </Col> */}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className={classes.siat_container_bg}></div>
      </div>
    </div>
  );
}

const SwiperActionBar = ({ ItemsLength, swiper }) => {
  const [disableNext, setDisableNext] = useState(
    swiper.activeIndex + Number(swiper.params.slidesPerView) >= ItemsLength
  );
  const [disablePrev, setDisablePrev] = useState(swiper.activeIndex <= 0);
  const nextSlide = () => {
    swiper.slideNext();
    setDisableNext(
      swiper.activeIndex + Number(swiper.params.slidesPerView) >= ItemsLength
    );
    setDisablePrev(swiper.activeIndex <= 0);
  };

  const prevSlide = () => {
    swiper.slidePrev();
    setDisableNext(
      swiper.activeIndex + Number(swiper.params.slidesPerView) - 1 >=
        ItemsLength
    );
    setDisablePrev(swiper.activeIndex - 1 <= 0);
  };

  return (
    <div className={classes.swiperActionBar}>
      <button onClick={() => prevSlide()} disabled={disablePrev}>
        {/* &lt; */}
        <img src={rightIconSmit} alt="prev"/>
      </button>
      <br />
      <br />
      <button onClick={() => nextSlide()} disabled={disablePrev}>
        {/* &gt; */}
        <img src={leftIconSmit} alt="next"/>
      </button>
    </div>
  );
};

export default Siat;
