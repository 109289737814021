import React from 'react';
import Cards from '../../components/Cards/Cards';
import Navbar from '../../components/Navbar/Navbar';
import classes from './Proviso.module.css';
import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/PageTransition/Animate';
import { slide } from '../../components/PageTransition/Animate';
import { Link } from 'react-router-dom';
import GoToTop from '../../GoToTop';

function Proviso() {
  return (
    <div className={classes.proviso}>
      <div>
        <Navbar />

        <div className={classes.pflex}>
          <motion.h1
            variants={pageAnimation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            To be consistently
            <br /> profitable investor, you'll need
          </motion.h1>
          <motion.div
            variants={slide}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            <Cards />
          </motion.div>
          <motion.p
            variants={pageAnimation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            This takes years to develop the right mindset, even after
            developing, <br />
            the major drawback - Doing it efficiently and consistently.
          </motion.p>
          <motion.h3
            variants={pageAnimation}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            {' '}
            But now, there is a way to beat the market!
          </motion.h3>
          <Link to="/invest">
            <button>Show me how</button>
          </Link>
        </div>
      </div>
      <GoToTop />
    </div>
  );
}

export default Proviso;
