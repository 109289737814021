// code very similar to signin page

import React, { useState, useEffect, useCallback, useRef } from 'react';
import SignImage from '../../assets/SignUp.png';
import { useHistory } from 'react-router-dom';
import classes from '../SignUp/Signup.module.css';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import './resetpassword.css'

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

const PTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#00D37F'
    },
    '&:hover .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#00D37F'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#333333'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00D37F'
        }
    },
    '& label.Mui-root': {
        color: 'white'
    },
    '& .MuiInputBase-root': {
        color: 'white'
    }
});

function ResetPassword() {
    const history = useHistory();
    const initialValues = { phone: '', email: '' };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [formStatus, setFormStatus] = useState('initiate');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [curphone, setCurphone] = useState('');

    const handlepassword = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmpassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const changePassword = () => {
        setFormErrors({});
        if (password !== confirmPassword) {
            let newerror = {}
            newerror['confirmPassword'] = "Confirm Password does not match with the given password"
            setFormErrors(newerror);
            return;
        } else if (password.length < 6) {
            let newerror = {};
            newerror['password'] = 'Password must be more than 6 characters';
            setFormErrors(newerror);
            return;
        }
        else {
            var curphone = JSON.parse(localStorage.getItem('MF_user')).phone_number;

            const options = {
                phone: curphone,
                password: password
            }

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            };

            fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/reset`, requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        // get error message from body or default to response status
                        const error = 'password not updated'
                        return Promise.reject(error);
                    }
                    return res.json();
                })
                .then((res) => {
                    setFormErrors({});
                    swal({
                        title: 'password changed successfully',
                        text: 'you will be redirected to Sign in page in 3 seconds',
                        icon: 'success',
                        timer: 3000
                    }).then(() => {
                        setTimeout(() => {
                            history.push('/profile');
                        }, 750);
                    });
                })
                .catch((err) => {
                    console.log(err);
                })
        }



    }

    const handleSubmit = (e) => {
        e.preventDefault();

        changePassword();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={classes.signin}>
            <div>
                <img src={SignImage} alt="" />
            </div>
            <div>
                <h3>Reset Password</h3>
                <form method="post" onSubmit={handleSubmit}>
                    <div className='reset_cm_input'>
                        <CssTextField
                            id="custom-css-outlined-input"
                            label="New Password"
                            type="password"
                            autoComplete="current-password"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            name="password"
                            onChange={handlepassword}
                            className={`${classes.first_tf} ${classes.password} w-100`}
                            value={password}
                        />
                        <p className={classes.errors}>{formErrors.password}</p>
                    </div>
                    <div className='reset_cm_input'>
                        <CssTextField
                            label="Confirm Password"
                            id="custom-css-outlined-input"
                            type="password"
                            InputLabelProps={{
                                style: { color: '#fff' }
                            }}
                            className={`${classes.first_tf} ${classes.password} w-100`}
                            onChange={handleConfirmpassword}
                            name="confirmPassword"
                            value={confirmPassword}
                        />
                        <p className={classes.errors}>{formErrors.confirmPassword}</p>
                    </div>
                    <Button
                        type="submit"
                        className={`${classes.button} ${classes.width} mt-5 mb-3`}
                    >
                        {isSubmit ? (
                            <Spinner animation="border" variant="light" />
                        ) : (
                            'CONTINUE'
                        )}
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;
