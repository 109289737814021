import React from 'react';
import classes from './Suggestions.module.css';
import fb from '../../../assets/facebook.svg';
import right from '../../../assets/right_arrow.svg';
import ig from '../../../assets/instagram.svg';
import tw from '../../../assets/twitter.svg';

const Suggestions = () => {
  return (
    <div className={classes.suggest}>
      <div className={classes.sugg_head}>Suggestions For You</div>
      <div className={classes.facebook}>
        <div className={classes.fb1}>
          <div className={classes.logo}>
            <img src={fb} alt="" />
          </div>
          <div className={classes.fb_text}>Facebook</div>
        </div>
        <div className={classes.fb_link}>
          Link
          <img src={right} alt="" />
        </div>
      </div>
      <div className={classes.facebook}>
        <div className={classes.fb1}>
          <div className={classes.logo}>
            <img src={ig} alt="" />
          </div>
          <div className={classes.fb_text}>Instagram</div>
        </div>
        <div className={classes.fb_link}>
          Link
          <img src={right} alt="" />
        </div>
      </div>
      <div className={classes.facebook}>
        <div className={classes.fb1}>
          <div className={classes.logo}>
            <img src={tw} alt="" />
          </div>
          <div className={classes.fb_text}>Twitter</div>
        </div>
        <div className={classes.fb_link}>
          Link
          <img src={right} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
